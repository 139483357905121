import React from 'react'
import { Box, Typography } from "@mui/material";

function CallStats({ title, total, icon }) {
  return (
<Box width="100%"  m="0 10px" >
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Box>
      {icon}
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ color: 'black' }}
      >
        {total} 
      </Typography>
    </Box>
  </Box>
  <Box display="flex" justifyContent="space-between" mt="2px" alignItems="center">
    <Typography fontWeight="medium" variant="h6" sx={{ color: 'black' }}>
      {title}
    </Typography>
  </Box>
</Box>

  )
}

export default CallStats
