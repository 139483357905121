import React from "react";
import { styled } from '@mui/system';

import {
  Container,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  useTheme,
  Box,
} from "@mui/material";
import { tokens } from "../../../theme";

const RootContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FormContainer = styled('div')({
  width: '99%',
  margin: 'auto',
});

const FormTable = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
}));

// Usage example in a component
const useStyles = () => {
  return (
    <RootContainer>
      <FormContainer>
        <FormTable>
          {/* Your form table content goes here */}
        </FormTable>
        <StyledTextField>
          {/* Your text field content goes here */}
        </StyledTextField>
      </FormContainer>
    </RootContainer>
  );
};

const Configuration = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Container className={classes.root}>
      <form
        className={classes.formContainer}
        method="POST"
        action="?menu=callcenter_config"
      >
        <Grid container spacing={2}>
          {/* Module Title */}
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "row", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent="space-between"
              p={1}
            >
              <Typography
                variant="h3"
                color={colors.skyblueAccent[500]}
                fontWeight="bold"
              >
                Configuration
              </Typography>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.formTable}></Grid>

          {/* Asterisk Connection */}
          <Grid item xs={12} md={4} className={classes.formTable}>
            <Typography style={{ marginBottom: "8px" }} variant="h6">
              Asterisk Connection
            </Typography>
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Asterisk Server"
              id="asterisk_asthost"
              name="asterisk_asthost"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="127.0.0.1"
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Asterisk Login"
              id="asterisk_astuser"
              name="asterisk_astuser"
              inputProps={{ style: { height: "5px" } }}
              defaultValue=""
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Asterisk Password"
              type="password"
              id="asterisk_astpass_1"
              name="asterisk_astpass_1"
              inputProps={{ style: { height: "5px" } }}
              defaultValue=""
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Asterisk Password (confirm)"
              type="password"
              id="asterisk_astpass_2"
              name="asterisk_astpass_2"
              inputProps={{ style: { height: "5px" } }}
              defaultValue=""
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="AMI Session Duration (0 for persistent session)"
              id="asterisk_duracion_sesion"
              name="asterisk_duracion_sesion"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="0"
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Force more calls per agent"
              id="dialer_forzar_sobrecolocar"
              name="dialer_forzar_sobrecolocar"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="0"
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.formTable}>
            <Typography style={{ marginBottom: "8px" }} variant="h6">
              Dialer Parameters
            </Typography>
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Short Call Threshold"
              id="dialer_llamada_corta"
              name="dialer_llamada_corta"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="10"
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Answer delay"
              id="dialer_llamada_corta"
              name="dialer_llamada_corta"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="8"
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Service percentage"
              id="dialer_llamada_corta"
              name="dialer_llamada_corta"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="97"
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Per-call dial timeout"
              id="dialer_llamada_corta"
              name="dialer_llamada_corta"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="0"
            />
            <TextField
              className={classes.textField}
              style={{ marginBottom: "8px" }}
              label="Agent inactivity timeout"
              id="dialer_llamada_corta"
              name="dialer_llamada_corta"
              inputProps={{ style: { height: "5px" } }}
              defaultValue="15"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="chkolddialer_debug"
                  onClick={() => {}}
                  // Add other Checkbox props as needed
                />
              }
              label="Enable dialer debug"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="chkolddialer_allevents"
                  onClick={() => {}}
                  // Add other Checkbox props as needed
                />
              }
              label="Dump all received Asterisk events"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="chkolddialer_overcommit"
                  onClick={() => {}}
                  // Add other Checkbox props as needed
                />
              }
              label="Enable overcommit of outgoing calls"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="chkolddialer_predictivo"
                  defaultChecked
                  onClick={() => {}}
                  // Add other Checkbox props as needed
                />
              }
              label="Enable predictive dialer behavior"
            />
          </Grid>

          {/* Dialer Status */}
          <Grid item xs={12} md={4} className={classes.formTable}>
            <Typography variant="h6">Dialer Status</Typography>
            <Typography variant="body1">
              Current Status: <b>Running</b>
            </Typography>
            <Button type="submit" variant="contained" color="secondary">
              Stop
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Configuration;
