import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { IconButton, Badge, Box } from '@mui/material';
import { NetworkCheck as NetworkCheckIcon } from '@mui/icons-material';
import AudioPlayer from './AudioPlayer';
import CallQuality from './CallQuality';
import ConferenceDrawer from './ConferenceDrawer';
import ConferenceDrawerLog from './ConferenceDrawerLog';
import ConferenceDrawerFiles from './ConferenceDrawerFiles';
import ConferenceDrawerParticipant from './ConferenceDrawerParticipant';
import ConferenceDrawerParticipantList from './ConferenceDrawerParticipantList';
import ConferenceDrawerSpeakerSelection from './ConferenceDrawerSpeakerSelection';
import ConferenceDrawerMute from './ConferenceDrawerMute';
import ConferenceHeader from './ConferenceHeader';
import ConferenceCarousel from './ConferenceCarousel';
import ConferenceParticipant from './ConferenceParticipant';
import ConferenceMatrixParticipant from './ConferenceMatrixParticipant';
import ConferenceParticipantSelf from './ConferenceParticipantSelf';
import ConferenceChat from './ConferenceChat';
import ConferenceChatEditor from './ConferenceChatEditor';
import ConferenceMenu from './ConferenceMenu';
import DragAndDrop from './DragAndDrop';
import InviteParticipantsModal from './InviteParticipantsModal';
import MuteAudioParticipantsModal from './MuteAudioParticipantsModal';
import Statistics from './Statistics';
import SwitchDevicesModal from './SwitchDevicesModal';
import SwitchDevicesMenu from './SwitchDevicesMenu';
import UserIcon from './UserIcon';
import debug from 'debug';
import superagent from 'superagent';
import clsx from 'clsx';
import sylkrtc from 'sylkrtc';
import utils from '../utils';

const DEBUG = debug('blinkrtc:ConferenceBox');

class ConferenceBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // State initialization logic
        };

        // Bind methods
        this.showOverlay = this.showOverlay.bind(this);
        this.handleFullscreen = this.handleFullscreen.bind(this);
        this.muteAudio = this.muteAudio.bind(this);
        this.hangup = this.hangup.bind(this);
        this.onParticipantJoined = this.onParticipantJoined.bind(this);
        this.onParticipantLeft = this.onParticipantLeft.bind(this);
        // Add more method bindings
    }

    componentDidMount() {
        // Add event listeners, fetch necessary data
        this.props.call.on('participantJoined', this.onParticipantJoined);
        this.props.call.on('participantLeft', this.onParticipantLeft);
        // Other component lifecycle logic
    }

    componentWillUnmount() {
        // Clean up event listeners, timers, etc.
        this.props.call.off('participantJoined', this.onParticipantJoined);
        this.props.call.off('participantLeft', this.onParticipantLeft);
    }

    onParticipantJoined(p) {
        DEBUG(`Participant joined: ${p.identity}`);
        this.refs.audioPlayerParticipantJoined.play();
        p.on('stateChanged', this.onParticipantStateChanged);
        p.attach();
        this.setState((prevState) => ({
            participants: [...prevState.participants, p]
        }));
    }

    onParticipantLeft(p) {
        DEBUG(`Participant left: ${p.identity}`);
        this.refs.audioPlayerParticipantLeft.play();
        const participants = this.state.participants.filter(participant => participant !== p);
        this.setState({ participants });
        p.detach(true);
    }

    // More methods handling events, toggling states

    render() {
        const { call } = this.props;
        const { participants, showInviteModal, showChat, showDrawer, unreadMessages, sharedFiles } = this.state;

        if (call === null) {
            return <div></div>;
        }

        const topLeftButtons = (
            <Badge
                key="unreadBadge"
                badgeContent={unreadMessages}
                color="primary"
                sx={{ root: { margin: '4px' }, badge: { backgroundColor: '#337ab7' } }}
                overlap="circular"
            >
                <IconButton onClick={this.toggleChatInCall}>
                    <i className="fa fa-comments fa-2x" />
                </IconButton>
            </Badge>
        );

        const bottomButtons = [
            <IconButton key="statisticsBtn" onClick={this.toggleStatistics}>
                <NetworkCheckIcon />
            </IconButton>,
            <IconButton key="hangupButton" onClick={this.hangup} sx={{ backgroundColor: '#d9534f', color: '#fff' }}>
                <i className="fa fa-phone rotate-135" />
            </IconButton>
        ];

        return (
            <DragAndDrop handleDrop={this.handleDrop}>
                <ConferenceHeader
                    show={this.state.callOverlayVisible}
                    remoteIdentity={this.props.remoteIdentity}
                    participants={participants}
                    buttons={{ topLeft: topLeftButtons, bottom: bottomButtons }}
                />
                <ConferenceDrawer show={showDrawer} close={this.toggleDrawer}>
                    {/* Drawer contents */}
                </ConferenceDrawer>
                <InviteParticipantsModal
                    show={showInviteModal}
                    close={this.toggleInviteModal}
                />
                {/* More components */}
            </DragAndDrop>
        );
    }
}

ConferenceBox.propTypes = {
    notificationCenter: PropTypes.func.isRequired,
    setDevice: PropTypes.func.isRequired,
    shareScreen: PropTypes.func.isRequired,
    propagateKeyPress: PropTypes.func.isRequired,
    toggleShortcuts: PropTypes.func.isRequired,
    call: PropTypes.object,
    hangup: PropTypes.func,
    remoteIdentity: PropTypes.string,
    generatedVideoTrack: PropTypes.bool,
    participantIsGuest: PropTypes.bool,
    lowBandwidth: PropTypes.bool,
    toggleChatInCall: PropTypes.func,
    unreadMessages: PropTypes.object
};

export default ConferenceBox;
