import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Fade, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { utils as sylkrtcutils } from 'sylkrtc'; // Assuming this is how the sylkrtc library is imported

const useStyles = makeStyles((theme) => ({
    menuVideoContainer: {
        width: '240px',
        height: '132px',
        position: 'relative'
    },
    scaleFit: {
        transform: 'scale(-1, 1) !important',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    videoOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgba(50,50,50,.6)',
        zIndex: 1
    },
    videoLabel: {
        width: '220px',
        fontSize: 14,
        zIndex: 2,
        position: 'absolute',
        color: '#fff',
        textAlign: 'center',
        padding: '8px',
        textOverflow: 'ellipsis'
    },
    pending: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        zIndex: 2
    },
    failedText: {
        fontSize: 14
    },
    selectedBorder: {
        border: '3px solid #4cae4c'
    }
}));

const VideoMenuItem = ({ stream, selected, label }) => {
    const classes = useStyles();
    const videoRef = useRef();

    useEffect(() => {
        if (stream !== 'failed') {
            sylkrtcutils.attachMediaStream(stream, videoRef.current, {
                muted: true,
                disableContextMenu: true,
                mirror: true
            });
        }
    }, [stream]);

    const isFailed = () => stream === 'failed';

    return (
        <div className={clsx(classes.menuVideoContainer, { [classes.selectedBorder]: selected })}>
            {isFailed() && <div className={clsx(classes.pending, classes.failedText)}>Camera unavailable</div>}
            {stream && !isFailed() && <div className={classes.videoLabel}>{label}</div>}
            {stream ? (
                <>
                    <div className={classes.videoOverlay}></div>
                    <video className={classes.scaleFit} ref={videoRef} autoPlay muted />
                </>
            ) : (
                <div className={classes.pending}>
                    <Fade
                        in={!stream}
                        style={{ transitionDelay: '100ms', color: '#666' }}
                        unmountOnExit
                    >
                        <CircularProgress />
                    </Fade>
                </div>
            )}
        </div>
    );
};

VideoMenuItem.propTypes = {
    stream: PropTypes.any,
    selected: PropTypes.bool,
    label: PropTypes.string
};

export default VideoMenuItem;
