import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const requestForToken = (uid) => {
  const auth = getAuth(app);

  const messaging = getMessaging(app);

  return getToken(messaging, { vapidKey: process.env.REACT_APP_vapidKey })
    .then(async (currentToken) => {
      if (currentToken) {
        const tokenRef = doc(db, "users", uid);
        // Retrieve the existing user data from the database
        const userSnapshot = await getDoc(tokenRef);
        const userData = userSnapshot.data();

        // Update the currentToken field
        const updatedUserData = {
          ...userData, 
          currentToken, 
        };

        // Save the updated data back to the database
        await setDoc(tokenRef, updatedUserData);

        // Update the user profile with the new FCM token
        try {
          await updateProfile(auth.currentUser, { fcmToken: currentToken });
        } catch (error) {
        }
      } else {
        // Show permission request UI
      }
    })
    .catch((err) => {
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      // console.log("payload", payload);
      resolve(payload);
    });
  });
