import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        custom: {
          100: "#c2c2c2",
          200: "#e0e0e0",
          300: "#00458b",
          400: "#b6c8d9",
          500: "#0d1416",
        },
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#e0e0e0",
          40: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#e7f5fb",
          200: "#cfebf7",
          300: "#b7e2f3",
          400: "#9fd8ef",
          50: "#87ceeb",
          500: "#00273E",
          501: "#00273E",
          600: "#6ca5bc",
          700: "#517c8d",
          80: "#6dcff6",
          90: "#1b292f",
          800: "#10191c",
          900: "#10191c",
          901: "#00273E",
          911: "#00273E",
        },
        // primary: {
        //   100: "#e7f5fb",
        //   200: "#cfebf7",
        //   300: "#b7e2f3",
        //   400: "#9fd8ef",
        //   50: "#87ceeb",
        //   500: "#10191c",
        //   501: "#10191c",
        //   600: "#6ca5bc",
        //   700: "#517c8d",
        //   80: "#6dcff6",
        //   90: "#1b292f",
        //   800: "#10191c",
        //   900: "#10191c",
        //   901: "#10191c",
        // },
        blueAccent: {
          100: "#cfd7e9",
          200: "#9eafd3",
          300: "#6e86be",
          400: "#3d5ea8",
          500: "#0d3692",
          600: "#0a2b75",
          700: "#082058",
          800: "#05163a",
          900: "#030b1d"
        },
        skyblueAccent: {
          100: "#e2f5fd",
          200: "#c5ecfb",
          300: "#a7e2fa",
          400: "#8ad9f8",
          500: "#6dcff6",
          600: "#57a6c5",
          700: "#417c94",
          800: "#2c5362",
          900: "#162931"
        },
          
        purpleAccent: {
          100: "#d8d7e7",
          200: "#b1afcf",
          300: "#8988b7",
          400: "#62609f",
          500: "#3b3887",
          600: "#2f2d6c",
          700: "#232251",
          800: "#181636",
          900: "#0c0b1b",
        },
      }
    : {
      custom: {
        100: "#FFFFF0",
        200: "#e0e0e0",
        300: "#00458b",
        400: "#b6c8d9",
        500: "#0d1416",
      },
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#a1a4ab",
          500: "#02699C",
          // 500: "#4682B4",
          501: "#00273E",
          // 501: "#10191c",
          // 500: "#E0FFFF",
          // 501: "#E0FFFF",
          600: "#1c1660 ",
          700: "#8ad9f8",
          800: "#a1a4ab",
          90: "#6dcff6",
          900: "#E8E8E8",
          901: "white",
          911: "#ddddf7",
        },
        blueAccent: {
          100: "#030b1d",
          200: "#05163a",
          300: "#082058",
          400: "#0a2b75",
          500: "#0d3692",
          600: "#3d5ea8",
          700: "#6e86be",
          800: "#9eafd3",
          900: "#cfd7e9",
        },
        purpleAccent: {
          100: "#0c0b1b",
          200: "#181636",
          300: "#232251",
          400: "#62609f",
          40: "#2f2d6c",
          500: "#3b3887",
          600: "#62609f",
          700: "#8988b7",
          800: "#b1afcf",
          900: "#d8d7e7",
        },
        skyblueAccent: {
          100: "#162931",
          200: "#2c5362",
          300: "#417c94",
          400: "#57a6c5",
          500: "#6dcff6",
          600: "#8ad9f8",
          700: "#a7e2fa",
          800: "#c5ecfb",
          900: "#e2f5fd",
        },
      }),
});

// black: {
//     100: "#cccccc",
//     200: "#999999",
//     300: "#666666",
//     400: "#333333",
//     500: "#000000",
//     600: "#000000",
//     700: "#000000",
//     800: "#000000",
//     900: "#000000"
// },
// black: {
//     100: "#cfd1d2",
//     200: "#9fa3a4",
//     300: "#707577",
//     400: "#404749",
//     500: "#10191c",
//     600: "#0d1416",
//     700: "#0a0f11",
//     800: "#060a0b",
//     900: "#030506"
// },
// black: {
//     100: "#ccd4d8",
//     200: "#99a9b2",
//     300: "#667d8b",
//     400: "#335265",
//     500: "#00273e",
//     600: "#001f32",
//     700: "#001725",
//     800: "#001019",
//     900: "#00080c"
// },
// #00273E
// #02699C
// #50B6F2
// #FFFFFF
// #F4F4F4
// #D6D6D6

// #3b3887 purple
// #1c1660
// #6dcff6 light blue
// #0d3692
// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.blueAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],  
              light: colors.grey[100],
            },
            background: {
              // default: colors.primary[700],
              default: "#001f32",
              // default: "#162126",
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.blueAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#f5f5f5",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
