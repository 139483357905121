import React from "react";

const Form = ({ text }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "17vh",
        padding: "5px",
        margin: "5px",
        overflowY: "auto",
      }}
    >
     </div>
  );
};

export default Form;
