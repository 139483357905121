import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Modal,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '90%',
  maxWidth: 600,
  maxHeight: '90vh',
  overflow: 'auto',
  padding: theme.spacing(4),
  '&:focus': {
    outline: 'none',
  },
}));

const WhoWeAre = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <StyledSection id="WhoWeAre">
      <Container maxWidth="md">
        <Box textAlign="center">
          <Typography variant="h2" component="h2" gutterBottom>
            Who We Are
          </Typography>
          <Typography variant="body1" paragraph>
            Zerxis Limited strives to be the model virtual IT Department, for small and mid-size businesses. 
            We are committed to enabling the success of our clients by leveraging our own expertise in ways 
            that help them realize profitable results from their technology initiatives.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleOpen}
          >
            Learn More
          </Button>
        </Box>
      </Container>

      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="who-we-are-modal-title"
      >
        <ModalContent>
          <Typography id="who-we-are-modal-title" variant="h4" component="h2" gutterBottom>
            Who We Are
          </Typography>
          <Typography variant="body1" paragraph>
            Let us introduce ourselves
          </Typography>
          <Typography variant="h6" gutterBottom>
            A few words about us
          </Typography>
          <Typography variant="body1" paragraph>
            Zerxis Limited strives to be the model virtual IT Department, for small and mid-size businesses. 
            We are committed to enabling the success of our clients by leveraging our own expertise in ways 
            that help them realize profitable results from their technology initiatives.
          </Typography>
          <Typography variant="body1" paragraph>
            We believe successful businesses are built on long-term relationships, and so are committed to 
            investing the time and energy necessary to become a full-fledged member of each client's team. 
            Thus, we commit ourselves to learning the intricacies of each client's individual business, the 
            dynamics of its staff, and the demands of its industry. We commit ourselves to developing solutions 
            that are appropriate to each client and its schedules, budgets, and long-term goals.
          </Typography>
          <Typography variant="body1" paragraph>
            We understand that by saying "We want to be your virtual IT department," we must put our clients' 
            needs first. We encourage all members of our staff to approach each client in that vein.
          </Typography>
          <Typography variant="body1" paragraph>
            We recognize that Zerxis Limited is only as good as its people. We are committed to maintaining a 
            work environment that is challenging, fun and rewarding. As a growing company, we need the dedication, 
            talent and enthusiasm of our staff to succeed, and expect to share our success as the company evolves.
          </Typography>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </ModalContent>
      </StyledModal>
    </StyledSection>
  );
};

export default WhoWeAre;
