import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/system';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // You would typically send this data to a server
  };

  return (
    <StyledSection id="contact">
      <Container>
        <Typography variant="h2" component="h2" align="center" gutterBottom>
          Contact
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          By combining technical knowledge and business savvy with our full range of IT services, 
          we're able to assess complex situations, devise effective solutions, and implement them 
          for you quickly and cost effectively.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <StyledForm onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Your Message"
                name="message"
                multiline
                rows={6}
                value={formData.message}
                onChange={handleChange}
                required
              />
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                size="large"
              >
                Send Message
              </Button>
            </StyledForm>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledCard>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <PhoneIcon color="primary" />
                      <Box ml={2}>
                        <Typography variant="h6">Call us on</Typography>
                        <Typography variant="body2">(+254) 0707 077 707</Typography>
                        <Typography variant="body2">(+254) 205 293 024</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
              <Grid item xs={12}>
                <StyledCard>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <EmailIcon color="primary" />
                      <Box ml={2}>
                        <Typography variant="h6">Send your message</Typography>
                        <Typography variant="body2">info@zerxis.com</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
              <Grid item xs={12}>
                <StyledCard>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <LocationOnIcon color="primary" />
                      <Box ml={2}>
                        <Typography variant="h6">P.O. Box</Typography>
                        <Typography variant="body2">777 - 00200 Nairobi, Kenya</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default Contact;
