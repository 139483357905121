import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Modal,
  Paper,
  Link as MuiLink,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import PhoneIcon from '@mui/icons-material/Phone';
import SupportIcon from '@mui/icons-material/Support';
import KeyboardIcon from '@mui/icons-material/Keyboard';

import { useNavigate, Link as RouterLink } from 'react-router-dom';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '90%',
  maxWidth: 600,
  maxHeight: '90vh',
  overflow: 'auto',
  padding: theme.spacing(4),
  '&:focus': {
    outline: 'none',
  },
}));

const services = [
  {
    id: 'CallCentreSolutions',
    icon: <PhoneIcon fontSize="large" />,
    title: 'CALL CENTRE SOLUTIONS',
    description: 'Zerxis Limited call center services provide a cost-effective solution for communicating with clients and meeting their needs.',
    modalContent: 'Detailed information about Call Centre Solutions on our Call Center Page'
  },
  {
    id: 'DesigningAndSettingUpCallCenter',
    icon: <SupportIcon fontSize="large" />,
    title: 'DESIGNING AND SETTING UP CALL CENTRE',
    description: 'Zerxis Limited will evaluate, plan and implement the strategies needed to complete a successful call center set-up.',
    modalContent: 'Detailed information about Designing and Setting Up Call Centre...'
  },
  {
    id: 'DigitizationSolutions',
    icon: <KeyboardIcon fontSize="large" />,
    title: 'MESSAGING SOLUTIONS',
    description: 'Zerxis Limited offers a messaging consultation that will enable your business to build seamless conversations over preferred channels.',
    modalContent: 'Detailed information about Messaging Solutions...'
  }
];

const WhatWeDo = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (id) => setOpenModal(id);
  const handleCloseModal = () => setOpenModal(null);

  return (
    <StyledSection id="WhatWeDo">
      <Container>
        <Box textAlign="center" mb={6}>
          <Typography variant="h2" component="h2" gutterBottom>
            What We Do
          </Typography>
          <Typography variant="body1">
            Zerxis Limited offers a range of services to ensure your IT projects can be delivered end-to-end, 
            allowing your business to focus on what it does best. We offer consultation, development, training & support services.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {services.map((service) => (
            <Grid item xs={12} sm={4} key={service.id}>
              <StyledCard>
                <IconButton color="primary" aria-label={service.title}>
                  {service.icon}
                </IconButton>
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {service.title}
                  </Typography>
                  <Typography variant="body2">
                    {service.description}
                  </Typography>
                </CardContent>
                <Box mt="auto" pt={2}>
                  <Typography 
                    variant="button" 
                    color="primary" 
                    component="a" 
                    onClick={() => handleOpenModal(service.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    More
                  </Typography>
                </Box>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        {services.map((service) => (
          <StyledModal
            key={service.id}
            open={openModal === service.id}
            onClose={handleCloseModal}
            aria-labelledby={`modal-title-${service.id}`}
          >
            <ModalContent>
              <Typography id={`modal-title-${service.id}`} variant="h4" component="h2" gutterBottom>
                {service.title}
              </Typography>
              <Typography variant="body1" paragraph>
                {service.modalContent}
              </Typography>

          {/* Using React Router Link */}
          <MuiLink component={RouterLink} to="/call-center-solution" onClick={handleCloseModal} sx={{ mt: 2, display: 'block' }}>
            Call Center Africa
          </MuiLink>
              <IconButton 
                onClick={handleCloseModal}
                color="primary"
                style={{ position: 'absolute', top: 8, right: 8 }}
              >
                {/* You can import and use a close icon here */}
                ✕
              </IconButton>
            </ModalContent>
          </StyledModal>
        ))}
      </Container>
    </StyledSection>
  );
};

export default WhatWeDo;
