import React from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  useTheme,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";

import PhoneDisabledRoundedIcon from "@mui/icons-material/PhoneDisabledRounded";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import DialpadIcon from "@mui/icons-material/Dialpad";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import PauseIcon from "@mui/icons-material/Pause";
import CallEndIcon from "@mui/icons-material/CallEnd";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { tokens } from "../../../theme";
import CallStats from "../../../components/CallStats";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase_setup/firebase";
import Script from "./components/Script";
import Caller from "./components/Caller";
import CallHistory from "./components/CallHistory";
import Form from "./components/Forms";
import Footer from "../../global/Footer";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
// import { useUserData } from "../../../context/UserDataContext";

function AgentConsole() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const [user] = useAuthState(auth);
  const { userInfo } = useContext(AuthContext)
  // const { userData, isLoading, loadUserData } = useUserData();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ xs: "row", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
        p={1}
      >
        <Typography
          variant="h3"
          color={colors.skyblueAccent[500]}
          fontWeight="bold"
        >
          Agent Console
        </Typography>
        <Typography variant="h5" color={colors.grey[400]} fontWeight="bold">
          Auto-Dialer On
          {/* <Blink  backgroundColor= "red" color='green' text='Auto-Dialer On' fontSize='20'>
           ON
        </Blink> */}
        </Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
        p={1}
      >
        <Typography variant="h5" color={colors.grey[400]} fontWeight="bold">
          Name: {userInfo.username}{" "}
        </Typography>
        <Typography variant="h6" color={colors.grey[400]} fontWeight="bold">
          Interface: SIP/1234
        </Typography>
        <Typography variant="h6" color={colors.grey[400]} fontWeight="bold">
          Status: Active
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h6"
            color={colors.grey[400]}
            fontWeight="bold"
            alignItems="center"
          >
            Position: TOP 20
          </Typography>
          <TrendingUpIcon sx={{ color: "green" }} />
        </Box>
      </Box>
      <Divider />

      {/* Box with Agent Profile */}
      <Box m="0 10px">
        <Box width="100%" m="20px 0">
          <Box
            display="grid"
            mt={1}
            gridTemplateColumns={{
              xs: "repeat(4, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gap={{ xs: "10px", md: "20px" }}
            width={{
              xs: "100%",
              md: "100%",
            }}
          >
            <Box
              gridColumn="span 2 "
              backgroundColor="#90EE90"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
              ml="10px"
            >
              <CallStats
                title="Auto Dial"
                total="2"
                icon={
                  <DialpadIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 2"
              backgroundColor="#fff44f"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Manual Dial"
                total="2"
                icon={
                  <DialpadIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="#90EE90"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Answered"
                total="2"
                icon={
                  <CallRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="#f08080"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Abandoned"
                total="2"
                icon={
                  <PhoneDisabledRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="grey"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Call Back"
                total="2"
                icon={
                  <CallMadeIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 2"
              backgroundColor="#4169e1"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Total Calls"
                total="0"
                icon={
                  <CallEndRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            {/* <Box
              gridColumn="span 1"
              backgroundColor="#4169e1"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Top"
                total="5"
                icon={
                  <TrendingUpIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box> */}
          </Box>
        </Box>

        <Box
          mt={1}
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box display="flex" flexWrap="wrap">
            <Box mb={{ xs: 1, md: 0 }} mr={{ xs: 0, md: 1 }}>
              <Button
                sx={{
                  margin: "5px",
                  fontSize: "0.8rem",
                  textTransform: "none",
                }}
                variant="outlined"
                startIcon={<CallEndIcon />}
                color="error"
              >
                End Call
              </Button>
            </Box>
            <Box mb={{ xs: 1, md: 0 }} mr={{ xs: 0, md: 1 }}>
              <Button
                disabled
                sx={{
                  margin: "5px",
                  fontSize: "0.8rem",
                  textTransform: "none",
                }}
                variant="outlined"
                startIcon={<ChangeCircleIcon />}
                color="success"
              >
                Transfer Call
              </Button>
            </Box>
            <Box mb={{ xs: 1, md: 0 }} mr={{ xs: 0, md: 1 }}>
              <Button
                disabled
                sx={{
                  margin: "5px",
                  fontSize: "0.8rem",
                  textTransform: "none",
                }}
                variant="outlined"
                startIcon={<DateRangeIcon />}
                color="primary"
              >
                Schedule Callback
              </Button>
            </Box>
            <Box mb={{ xs: 1, md: 0 }} mr={{ xs: 0, md: 1 }}>
              <Button
                sx={{
                  margin: "5px",
                  fontSize: "0.8rem",
                  textTransform: "none",
                }}
                variant="outlined"
                startIcon={<SaveAltIcon />}
                color="warning"
              >
                Save Data
              </Button>
            </Box>
            <Box mb={{ xs: 1, md: 0 }}>
              <Button
                disabled
                sx={{
                  margin: "5px",
                  fontSize: "0.8rem",
                  textTransform: "none",
                }}
                variant="outlined"
                startIcon={<PauseIcon />}
                color="secondary"
              >
                Take Break
              </Button>
            </Box>
          </Box>
        </Box>

        <div>
          <Paper>
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              sx={{ backgroundColor: colors.primary[700] }}
            >
              <Tab label="Calls" />
            </Tabs>
            <Box role="tabpanel" hidden={value !== 0}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  height: { xs: "auto", md: "75vh" },
                  backgroundColor: colors.primary[901],
                }}
              >
                <Box
                  sx={{
                    flex: 0.4,
                    // width: "300px",
                    overflow: "hidden",
                    position: "relative",
                    flexDirection: "column",
                  }}
                >
                  {/* Left pane content */}
                  <Box sx={{ p: 2 }}>
                    <fieldset>
                      <legend>
                        <b>Information</b>
                      </legend>
                      <div>
                        <Caller text="Item 2" />
                      </div>
                    </fieldset>
                  </Box>
                  <Box sx={{ p: 2, overflowY: "auto" }}>
                    <fieldset>
                      <legend>
                        <b>Script</b>
                      </legend>
                      <div>
                        <Script text="Item 2" />
                      </div>
                    </fieldset>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ flex: 1, pt: 2 }}>
                    <fieldset style={{ width: "100%" }}>
                      <legend style={{ paddingLeft: "0 10px" }}>
                        <b>Call History</b>
                      </legend>
                      <div>
                        <CallHistory text="Item 2" includeTable={true} />
                      </div>
                    </fieldset>
                  </Box>
                  <Box sx={{ flex: 1, pt: 2 }}>
                    <fieldset>
                      <legend>
                        <b>Forms</b>
                      </legend>
                      <div>
                        <Form text="Item 2" />
                      </div>
                    </fieldset>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Footer />
        </div>
      </Box>
      {/* <ResizableGrid/> */}
    </Box>
  );
}

export default AgentConsole;
