const React = require('react');


const Logo = () => {
    return (
        <div>
            <div className="vii-logo"></div>
            <h1 className="cover-heading">VII</h1>
        </div>
    );
}


export default Logo;
