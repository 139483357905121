import React, { useState } from 'react';
import { 
  Grid, 
  Card, 
  CardMedia, 
  CardContent, 
  Typography, 
  Button, 
  Modal, 
  Box, 
  List, 
  ListItem, 
  ListItemText 
} from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  '&:hover .MuiCardContent-root': {
    opacity: 1,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: 'white',
  opacity: 0,
  transition: 'opacity 0.3s',
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  maxWidth: 600,
  width: '90%',
}));

const portfolioItems = [
  { id: 1, image: '/assets/img/portfolio/1.jpg', title: 'Process & Technology.' },
  { id: 2, image: '/assets/img/portfolio/2.jpg', title: 'Consulting Expreience' },
  { id: 3, image: '/assets/img/portfolio/3.jpg', title: '24/7 Phone support' },
];

const PortfolioSection = () => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box component="section" id="portfolio" sx={{ py: 8 }}>
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        Our Portfolio
      </Typography>
      <Grid container spacing={0}>
        {portfolioItems.map((item) => (
          <Grid item xs={12} sm={4} key={item.id}>
            <StyledCard>
              <CardMedia
                component="img"
                height="300"
                image={item.image}
                alt={item.title}
              />
              <StyledCardContent>
                <Typography variant="h6">{item.title}</Typography>
                <Button onClick={() => handleOpen(item)} color="primary">
                  View Project
                </Button>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="project-modal-title"
      >
        <ModalContent>
          <Typography id="project-modal-title" variant="h6" component="h2" gutterBottom>
            Project Overview
          </Typography>
          {selectedItem && (
            <>
              <img src={selectedItem.image} alt={selectedItem.title} style={{ width: '100%', marginBottom: 16 }} />
              <Typography variant="body1" paragraph>
                At Zerxis Limited, our Call Centre services leverage cutting-edge technology to address a wide range of organisational challenges. Our solutions are designed to enhance operational efficiency, streamline communication workflows, and improve customer experience through reliable and scalable infrastructure.
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Technology" secondary="VoIP" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Category" secondary="SaaS Call Center Software" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Date" secondary="10 March 2024" />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Project URL" 
                    secondary={<a href="https://callcenter.africa" target="_blank" rel="noopener noreferrer">callcenter.africa</a>}
                  />
                </ListItem>
              </List>
            </>
          )}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </ModalContent>
      </StyledModal>
    </Box>
  );
};

export default PortfolioSection;
