import {
  Box,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";


function Inbound() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const mockDataCaller = [
    {
      id: 1,
      idname: "ABC123",
      idnumber: 101,
      position: 2,
      wait: "10 mins",
    },
    {
      id: 2,
      idname: "XYZ456",
      idnumber: 102,
      position: "1",
      wait: "5 mins",
    },
    {
      id: 3,
      idname: "PQR789",
      idnumber: 103,
      position: "3",
      wait: "15 mins",
    },
  ];
  const mockData = [
    {
      id: 1,
      name: "John Doe",
      interfaces: "SIP/1111",
      status: "Active",
      lastCall: "2023-10-09T08:00:00",
      action: "Edit",
    },
    {
      id: 2,
      name: "Jane Smith",
      interfaces: "SIP/2222",
      status: "Inactive",
      lastCall: "2023-10-08T15:30:00",
      action: "Delete",
    },
    {
      id: 3,
      name: "Bob Johnson",
      interfaces: "SIP/3333",
      status: "Active",
      lastCall: "2023-10-09T10:45:00",
      action: "View",
    },
    // Add more data as needed
  ];

  const columns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "idname",
      headerName: "ID Name",
      flex: 0.4,
    },
    {
      field: "idnumber",
      headerName: "ID Number",
      flex: 0.4,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 0.5,
    },
    {
      field: "wait",
      headerName: "Wait",
      flex: 0.5,
    },
  ];
  const columnsAgent = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
    },
    {
      field: "interfaces",
      headerName: "Interface",
      flex: 0.4,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "lastCall",
      headerName: "Last Call At",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <strong>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  marginLeft: 6,
                  marginBottom: 0.5,
                  backgroundColor: "orange",
                }}
                onClick={() => {}}
              >
                Spy
              </Button>
              <Button
                variant="contained"
                // color="primary"
                backgroundColor="red"
                size="small"
                style={{ marginLeft: 6, backgroundColor: "red" }}
                onClick={() => {}}
              >
                Remove From Queue
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 6, backgroundColor: "skyblue" }}
                onClick={() => {}}
              >
                Barge
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: 5, backgroundColor: "green" }}
                onClick={() => {}}
              >
                Whisper
              </Button>
            </Box>
          </strong>
        );
      },
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: colors.primary[900],
          borderRadius: 3,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={4}
        >
          <Typography
            variant="h3"
            color={colors.skyblueAccent[500]}
            fontWeight="bold"
          >
            Inbound Team
          </Typography>
          <Typography variant="h5" color={colors.blueAccent[500]} fontWeight="bold">Agents : 7</Typography>
        </Box>
        <Divider />
        <Box
          display="grid"
          gridTemplateColumns={{
          xs: "repeat(4, 1fr)",
          md: "repeat(12, 1fr)",}}
          gridAutoRows="100px"
          gap="10px"
          mt={2}
        >
          <Box
            gridColumn="span 2"
            backgroundColor="Blue"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                1283
              </Typography>
              <Box>
                <Typography color="white">Total Calls</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="green"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                980
              </Typography>
              <Box>
                <Typography color="white">Answered</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="red"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                283
              </Typography>
              <Box backgroundColor="red">
                <Typography color="white">Abandoned</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="#90EE90"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "black" }}
              >
                2:43
              </Typography>
              <Box>
                <Typography>Average Time</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="#90EE90"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "black" }}
              >
                1:03
              </Typography>
              <Box>
                <Typography>Average wait </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="#90EE90"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
            mr="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bolder"
                sx={{ color: "black" }}
              >
                67%
              </Typography>
              <Box>
                <Typography>Service Level</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box m={3}>
          <Button variant="outlined" startIcon={<AddBoxRoundedIcon />}>
            Add Member
          </Button>
        </Box>
        <Box mt="20px">
          <Typography
            variant="h4"
            color={colors.primary[700]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            Calls
          </Typography>
          <Box
            m="10px 0 0 0"
            height="65vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.primary[500],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.purpleAccent[400],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[900],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.primary[900],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.blueAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={mockData}
              columns={columnsAgent}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <Typography
            variant="h4"
            color={colors.primary[700]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            Callers
          </Typography>
          <Box
            m="20px 0 0 0"
            // height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.blueAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.purpleAccent[400],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[900],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.primary[900],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.blueAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              autoHeight
              {...mockDataCaller}
              rows={mockDataCaller}
              columns={columns}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Inbound;
