import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Oval } from "react-loader-spinner";

import { ColorModeContext, useMode } from "./theme";
import { auth } from "./firebase_setup/firebase";
import MainLayout from "./scenes/global/MainLayout";
import PublicLanding from "./scenes/landing/Public";
import TermsOfServicePage from "./pages/TermsOfServicePage.js";
import CallCenterSolutionPage from "./pages/CallCenterSolutionPage";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";
import { UserDataProvider } from "./context/UserDataContext";
import LoginPage from "./scenes/landing/auth/LoginPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DataDeletionRequestPage from "./pages/DataDeletionRequestPage";
import DeviceMap from "./scenes/devices/DeviceMap";
import CallComponent from "./components/CallComponent";

import Blink from "./webrtc/app";

function App() {
  const [theme, colorMode] = useMode();
  const { isLoading, userToken } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(true); // Initially true to show loader while auth status is being checked

  useEffect(() => {
    if (!isLoading) {
      // Hide loader when loading is finished regardless of the authentication status
      setShowLoader(false);
    }
  }, [isLoading]); // Effect runs when `isLoading` changes

  const signOut = () => {
    auth.signOut();
  };

  if (isLoading || showLoader) {
    // Show loader while authentication status is being checked or if `showLoader` is still true
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: "1000",
        }}
      >
        <Oval color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return (
    <Router>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <Routes>
                {/* Public Routes */}
                <Route path="*" element={userToken ? <MainLayout /> : <PublicLanding/>} />
                <Route path="/products/" element={ <CallCenterSolutionPage />} />
                <Route path="/call-center-solution" element={<CallCenterSolutionPage />} />
                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/data-deletion-request" element={<DataDeletionRequestPage />} />
                <Route path="/devicemap" element={<DeviceMap />} />
                <Route path="/calls" element={<CallComponent />} />
                <Route path="/webrtc" element={<Blink />} />
                <Route path="/login" element={userToken ? <Navigate to="/app" /> : <LoginPage />} />
                
                {/* Protected Routes */}
                <Route path="/app/*" element={userToken ? <MainLayout /> : <Navigate to="/login" />} />
              </Routes>
              
            </ThemeProvider>
          </ColorModeContext.Provider>
    </Router>
  );
}

export default App;
