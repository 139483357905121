import React from "react";

import { Box } from "@mui/material";
import Navbar from './Navbar';
import Authentication from "../auth/Authentication";

import PublicHeader from './PublicHeader';
import HomeSection from './HomeSection';
import PortfolioSection from './PortfolioSection';
import WhoWeAre from './WhoWeAre';
import WhatWeDo from './WhatWeDo';
import CallToAction from './CallToAction';
import CallCenterServices from './CallCenterServices';
import OurClients from './OurClients';
import Contact from './Contact';
import Footer from './Footer';

const PublicLanding = () => {
  return (
    <>
      <Navbar />
      <HomeSection />
      <PortfolioSection />
      <WhoWeAre />
      <WhatWeDo />
      <CallCenterServices />
      <CallToAction />
      <OurClients />
      <Contact />
      <Footer />
    </>
  );
};

export default PublicLanding;
