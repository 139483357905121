import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import CallStats from "../../components/CallStats";
import CallReceivedRoundedIcon from "@mui/icons-material/CallReceivedRounded";
import PhoneMissedRoundedIcon from "@mui/icons-material/PhoneMissedRounded";
import PhoneDisabledRoundedIcon from "@mui/icons-material/PhoneDisabledRounded";
import RingVolumeRoundedIcon from "@mui/icons-material/RingVolumeRounded";
import PhonePausedRoundedIcon from "@mui/icons-material/PhonePausedRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";


export default function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const mockData = [
    {
      id: 1,
      source: "Caller A",
      destination: "Receiver X",
      "Date and Time": "2023-10-06 10:30 AM",
      status: "Answered",
      action: "View",
    },
    {
      id: 2,
      source: "Caller B",
      destination: "Receiver Y",
      "Date and Time": "2023-10-06 11:45 AM",
      status: "No Answer",
      action: "Edit",
    },
    {
      id: 3,
      source: "Caller C",
      destination: "Receiver Z",
      "Date and Time": "2023-10-06 02:15 PM",
      status: "Answered",
      action: "Delete",
    },
    {
      id: 4,
      source: "Caller D",
      destination: "Receiver W",
      "Date and Time": "2023-10-06 03:30 PM",
      status: "No Answer",
      action: "View",
    },
    {
      id: 5,
      source: "Caller E",
      destination: "Receiver V",
      "Date and Time": "2023-10-06 04:45 PM",
      status: "Answered",
      action: "Edit",
    },
    // Add more mock data as needed
  ];
  const mockDataCon = [
    {
      id: 1,
      queue: "Queue A",
      name: "John Doe",
      interfaces: "Interface 1",
      status: "Available",
      lastCall: "2023-10-06 10:30 AM",
    },
    {
      id: 2,
      queue: "Queue B",
      name: "Jane Smith",
      interfaces: "Interface 2",
      status: "Busy",
      lastCall: "2023-10-06 11:45 AM",
    },
    {
      id: 3,
      queue: "Queue C",
      name: "Bob Johnson",
      interfaces: "Interface 3",
      status: "Away",
      lastCall: "2023-10-06 02:15 PM",
    },
    {
      id: 4,
      queue: "Queue D",
      name: "Alice Williams",
      interfaces: "Interface 4",
      status: "Available",
      lastCall: "2023-10-06 03:30 PM",
    },
    {
      id: 5,
      queue: "Queue E",
      name: "Charlie Brown",
      interfaces: "Interface 5",
      status: "Busy",
      lastCall: "2023-10-06 04:45 PM",
    },
    // Add more mock data as needed
  ];

  const columnsAgent = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "queue",
      headerName: "Queue",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "interfaces",
      headerName: "Interface",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "lastCall",
      headerName: "Last Call At",
      flex: 1,
    },
  ];
  const columns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "source",
      headerName: "Source",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "destination",
      headerName: "Destination",
      flex: 0.5,
    },
    {
      field: "Date and Time",
      headerName: "Duration",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
    },
  ];

  return (
    <Box  height={"100%"}>
          <Box
            display="grid"
            mt={1}
            gridTemplateColumns={{
              xs: "repeat(4, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gap={{ xs: "10px", md: "20px" }}
            width={{
              xs: "100%", 
              md: "100%",
            }}
          >
            <Box
              gridColumn="span 2"
              backgroundColor="#90EE90"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
              ml="10px"
            >
              <CallStats
                title="Incoming"
                total="2"
                icon={
                  <RingVolumeRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="#fff44f"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Answered"
                total="2"
                icon={
                  <CallReceivedRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="#b5651d"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Failed"
                total="2"
                icon={
                  <PhoneDisabledRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="#f08080"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Busy"
                total="2"
                icon={
                  <PhonePausedRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="grey"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="No Answer"
                total="2"
                icon={
                  <PhoneMissedRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 2"
              backgroundColor="#4169e1"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
            >
              <CallStats
                title="Total Calls"
                total="0"
                icon={
                  <CallRoundedIcon
                    sx={{ color: colors.primary[600], fontSize: "22px" }}
                  />
                }
              />
            </Box>
          </Box>
          <Box mt="20px">
            <Typography
              variant="h4"
              color={colors.primary[700]}
              fontWeight="bold"
              sx={{ m: "0 0 5px 0" }}
            >
              Call History
            </Typography>
            <Box
              m="10px 0 0 0"
              height="65vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.blueAccent[100],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[900],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[900],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.blueAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={mockData}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Box>
          <Box mt="20px">
            <Typography
              variant="h4"
              color={colors.primary[700]}
              fontWeight="bold"
              sx={{ m: "0 0 5px 0" }}
            >
              Agents 13
            </Typography>
            <Box
              m="10px 0 0 0"
              height="65vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.blueAccent[100],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[900],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[900],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.blueAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={mockDataCon}
                columns={columnsAgent}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Box>
    </Box>
  );
}
