import React, { useState } from "react";
import { Box } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReportHeader from "../../../components/ReportHeader";
function BreaksReport() {
  const defaultStartDate = new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000); // 4 days ago
  const defaultEndDate = new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000); // 4 days from now
  const [values, setValues] = useState([defaultStartDate, defaultEndDate]);

  const handleChange = (newValues) => {
    setValues(newValues);
  };

  const isDateValid = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };
  function createData(agent_no, agent, start_time, end_time, duration, duration_wait, queue, type, phone, transfer, status, recording) {
    return { agent_no, agent, start_time, end_time, duration, duration_wait, queue, type, phone, transfer, status, recording };
  }
  
  const rows = [
    createData(3994, 'John Doe', '10:00 AM', '11:30 AM', 1.5, 0.5, 'Support', 'Inbound', '123-456-7890', 'No', 'Connected', 'Yes'),
    createData(3995, 'Jane Smith', '1:00 PM', '2:30 PM', 2.5, 1.0, 'Sales', 'Outbound', '987-654-3210', 'Yes', 'On Hold', 'No'),
    createData(3996, 'Bob Johnson', '3:00 PM', '5:00 PM', 2.0, 0.8, 'Service', 'Inbound', '555-123-4567', 'No', 'Disconnected', 'Yes'),
  ];
  
  return (
    <Box>
      <ReportHeader title="Breaks" subtitle="Report on Breaks" />
      <DatePicker value={values} onChange={handleChange} range />
  
      <div>
        {Array.isArray(values) && values.length === 2 && isDateValid(values[0]) && isDateValid(values[1]) ? (
          <div>
            Selected Date Range: {values[0].toLocaleDateString()} to {values[1].toLocaleDateString()}
          </div>
        ) : (
          <div>
            Please select a valid date range. Debug: {JSON.stringify(values)}
          </div>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 420 }} aria-label="caption table">
          <caption>Breaks</caption>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Duration Wait</TableCell>
              <TableCell>Queue</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Transfer</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Recording</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.agent_no}
                </TableCell>
                <TableCell>{row.agent}</TableCell>
                <TableCell>{row.start_time}</TableCell>
                <TableCell>{row.end_time}</TableCell>
                <TableCell>{row.duration}</TableCell>
                <TableCell>{row.duration_wait}</TableCell>
                <TableCell>{row.queue}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.transfer}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.recording}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default BreaksReport