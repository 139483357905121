import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import linkifyUrls from 'linkify-urls';
import { DateTime } from 'luxon';
import { Chip, Divider, MenuItem } from '@mui/material';
import { Lock as LockIcon, Done as DoneIcon, DoneAll as DoneAllIcon, ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';
import CustomContextMenu from '../CustomContextMenu';
import UserIcon from '../UserIcon';

const Message = ({
  message,
  scroll,
  cont,
  displayed,
  focus,
  contactCache,
  removeMessage,
  editMessage,
  imdnStates,
  enableMenu,
  fromSelf
}) => {
  const [state, setState] = useState('');
  const [parsedContent, setParsedContent] = useState();
  const messageRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { ref, inView } = useInView({ threshold: 0 });

  const sender = message.sender.displayName || message.sender.uri;
  const time = DateTime.fromJSDate(message.timestamp).toFormat('HH:mm');

  const preHtmlEntities = (str) => String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  const postHtmlEntities = (str) => String(str).replace(/(?!&amp;|&lt;|&gt;|&quot;)&/g, '&amp;');
  const customUrlRegexp = () => (/((?:https?(?::\/\/))(?:www\.)?(?:[a-zA-Z\d-_.]+(?:(?:\.|@)[a-zA-Z\d]{2,})|localhost)(?:(?:[-a-zA-Z\d:%_+.~#!?&//=@();]*)(?:[,](?![\s]))*)*)/g);

  const isDisplayed = useCallback(() => {
    if (displayed) {
      displayed();
    }
  }, [displayed]);

  useEffect(() => {
    if (parsedContent !== undefined) {
      scroll();
    }
  }, [parsedContent, scroll]);

  useEffect(() => {
    if (message.contentType === 'text/html') {
      setParsedContent(parse(message.content.trim(), {
        replace: (domNode) => {
          if (domNode.attribs && domNode.attribs.href) {
            domNode.attribs.target = '_blank';
          }
          if (domNode.type === 'text' && (!domNode.parent || (domNode.parent.type === 'tag' && domNode.parent.name !== 'a'))) {
            const url = linkifyUrls(preHtmlEntities(domNode.data), {
              customUrlRegexp,
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            });
            return <span>{parse(postHtmlEntities(url))}</span>;
          }
        }
      }));
    } else if (message.contentType.startsWith('image/')) {
      const image = `data:${message.contentType};base64,${message.content}`;
      setParsedContent(<img className="img-responsive" src={image} alt="message content" />);
    } else if (message.contentType === 'text/plain') {
      const linkifiedContent = linkifyUrls(preHtmlEntities(message.content), {
        customUrlRegexp,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      });
      setParsedContent(<pre>{parse(postHtmlEntities(linkifiedContent))}</pre>);
    } else if (message.contentType === 'text/pgp-public-key') {
      setParsedContent(
        <Chip
          sx={{ height: 18, fontSize: 11 }}
          variant="outlined"
          size="small"
          icon={<LockIcon />}
          label="Public key"
        />
      );
    }

    const finalStates = new Set(['displayed', 'received']);
    const stateChanged = (oldState, newState) => setState(newState);

    if (message instanceof require('events').EventEmitter
      && (message.state === 'pending' || (imdnStates && !finalStates.has(message.state)))) {
      message.on('stateChanged', stateChanged);
    }
    setState(message.state);

    return () => {
      if (message instanceof require('events').EventEmitter) {
        message.removeListener('stateChanged', stateChanged);
      }
    };
  }, [message, imdnStates]);

  const scrollToMessage = () => {
    messageRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (messageRef.current && focus) {
      scrollToMessage();
    }
  }, [focus]);

  useEffect(() => {
    if (inView) {
      isDisplayed();
    }
  }, [inView, isDisplayed]);

  const statusIcon = () => {
    switch (state) {
      case 'accepted':
        return <DoneIcon sx={{ fontSize: 15, verticalAlign: 'middle', color: '#888' }} />;
      case 'delivered':
        return <DoneIcon sx={{ fontSize: 15, verticalAlign: 'middle', color: 'green' }} />;
      case 'displayed':
        return <DoneAllIcon sx={{ fontSize: 15, verticalAlign: 'middle', color: 'green' }} />;
      case 'failed':
        return <ErrorOutlineIcon sx={{ fontSize: 17, verticalAlign: 'middle', color: '#a94442' }} titleAccess="Not Delivered" />;
      case 'error':
        return <ErrorOutlineIcon sx={{ fontSize: 17, verticalAlign: 'middle', color: '#a94442' }} titleAccess="Display Error" />;
      default:
        return null;
    }
  };

  const getDisplayName = (uri) => contactCache?.get(uri) || uri;

  const handleContextMenu = (e) => {
    if (!enableMenu) return;

    e.preventDefault();
    const { clientX, clientY } = e;
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        bottom: clientY,
        left: clientX
      })
    };
    setAnchorEl(virtualElement);
  };

  const copy = () => {
    const selection = window.getSelection().toString() || message.content;
    navigator.clipboard.writeText(selection);
  };

  const _removeMessage = () => {
    if (removeMessage) removeMessage();
  };

  const _editMessage = () => {
    if (editMessage) editMessage();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div ref={ref}>
      <Card onContextMenu={handleContextMenu}>
        {enableMenu && (
          <CustomContextMenu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            keepMounted={false}
          >
            <MenuItem onClick={() => { copy(); handleClose(); }}>Copy</MenuItem>
            <Divider />
            {fromSelf && <MenuItem onClick={() => { _editMessage(); handleClose(); }}>Edit Message</MenuItem>}
            <MenuItem onClick={() => { _removeMessage(); handleClose(); }}>Remove Message</MenuItem>
          </CustomContextMenu>
        )}

        <div ref={messageRef} />

        <Row className="no-gutters align-items-center">
          <Col xs="auto">
            <UserIcon identity={getDisplayName(message.sender.uri)} />
          </Col>

          <Col>
            <Card.Body className="vertical-center">
              <Card.Title>
                {getDisplayName(message.sender.uri)}&nbsp;
                <span>{time}</span>
                <span className="pull-right" style={{ paddingRight: '15px' }}>
                  {message.isSecure && <LockIcon sx={{ fontSize: 15, verticalAlign: 'middle', color: '#ccc' }} />}
                  {statusIcon()}
                </span>
              </Card.Title>
              <Card.Text>{parsedContent}</Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  scroll: PropTypes.func.isRequired,
  removeMessage: PropTypes.func,
  cont: PropTypes.bool,
  displayed: PropTypes.func,
  focus: PropTypes.bool,
  contactCache: PropTypes.object,
  imdnStates: PropTypes.bool,
  enableMenu: PropTypes.bool,
  editMessage: PropTypes.func,
  fromSelf: PropTypes.bool
};

export default Message;
