import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debug from 'debug';
import hark from 'hark';
import { utils as sylkrtcutils } from 'sylkrtc';
import { Badge, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NetworkCheck as NetworkCheckIcon } from '@mui/icons-material';

import CallOverlay from './CallOverlay';
import CallQuality from './CallQuality';
import ConferenceDrawer from './ConferenceDrawer';
import DragAndDrop from './DragAndDrop';
import DTMFModal from './DTMFModal';
import Statistics from './Statistics';
import UserIcon from './UserIcon';
import EscalateConferenceModal from './EscalateConferenceModal';
import SwitchDevicesMenu from './SwitchDevicesMenu';

import * as fileTransferUtils from '../fileTransferUtils';
import * as utils from '../utils';

const DEBUG = debug('blinkrtc:AudioCallBox');

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        width: '20px',
        height: '20px',
        fontWeight: 'bold',
        fontSize: '1rem',
        backgroundColor: '#337ab7',
        '&.MuiBadge-anchorOriginTopLeftCircular': {
            top: '18%',
            left: '18%'
        },
        '&.MuiBadge-anchorOriginTopRightCircular': {
            top: '18%',
            right: '18%'
        }
    }
}));

const SharingButton = styled(IconButton)(({ theme }) => ({
    width: '45px',
    height: '45px',
    backgroundColor: '#fff',
    fontSize: '20px',
    border: '1px solid #fff',
    color: '#333',
    margin: '4px',
    '&:hover': {
        backgroundColor: '#fff'
    }
}));

const AudioCallBox = (props) => {
    const {
        setDevice,
        call,
        escalateToConference,
        hangupCall,
        mediaPlaying,
        remoteIdentity,
        forceTimerStart,
        notificationCenter,
        toggleChatInCall,
        inlineChat,
        unreadMessages,
        propagateKeyPress
    } = props;

    const [audioMuted, setAudioMuted] = useState(false); // Here setAudioMuted is defined


    const [state, setState] = useState({
        active: false,
        audioMuted: false,
        showDtmfModal: false,
        showEscalateConferenceModal: false,
        showAudioSwitchMenu: false,
        showStatistics: false,
        showChat: false,
        showInlineChat: false,
        audioGraphData: new Array(60).fill({}),
        lastData: {},
        switchAnchor: null
    });

    const remoteAudio = useRef(null);
    const speechEvents = useRef(null);
    const notificationCenterRef = useRef(null);
    // Handle incoming message (placeholder function)
    const incomingMessage = (message) => {
        console.log("Incoming message: ", message);
        // Add your logic to handle the message
    };


    // Toggle mute/unmute audio
    const muteAudio = () => {
        const localStream = call.getLocalStreams()[0]; // Assuming 'call' is passed as a prop
        if (localStream.getAudioTracks().length > 0) {
            const track = localStream.getAudioTracks()[0];
            track.enabled = !track.enabled;  // Toggle mute/unmute
            setAudioMuted(!track.enabled);   // Update state to reflect mute status
        }
    };

    // Example useEffect to handle incoming messages
    useEffect(() => {
        call.account.on('incomingMessage', incomingMessage);
        return () => {
            call.account.removeListener('incomingMessage', incomingMessage);
        };
    }, [call]);

    useEffect(() => {
        if (notificationCenter) {
            notificationCenterRef.current = notificationCenter();
        }

        if (call != null) {
            switch (call.state) {
                case 'established':
                    attachStream(call);
                    break;
                case 'incoming':
                    mediaPlaying();
                // fall through
                default:
                    call.on('stateChanged', callStateChanged);
                    break;
            }
            call.statistics.on('stats', statistics);
            call.account.on('incomingMessage', incomingMessage);
        } else {
            mediaPlaying();
        }
        document.addEventListener('keydown', onKeyDown);

        return () => {
            if (speechEvents.current !== null) {
                speechEvents.current.stop();
                speechEvents.current = null;
            }
            document.removeEventListener('keydown', onKeyDown);
            if (call) {
                call.account.removeListener('incomingMessage', incomingMessage);
                call.statistics.removeListener('stats', statistics);
            }
        };
    }, [call]);

    const onKeyDown = (event) => {
        if (!state.showEscalateConferenceModal && !state.showDtmfModal && !propagateKeyPress) {
            switch (event.which) {
                case 77:    // m/M
                    muteAudio(event);
                    break;
                default:
                    break;
            }
        }
    };

    const callStateChanged = (oldState, newState, data) => {
        if (newState === 'established') {
            attachStream(call);
        }
    };

    const statistics = (stats) => {
        // ... (rest of the statistics function remains the same)
    };

    const attachStream = (call) => {
        const remoteStream = call.getRemoteStreams()[0];
        sylkrtcutils.attachMediaStream(remoteStream, remoteAudio.current);
        const options = {
            interval: 225,
            play: false
        };
        speechEvents.current = hark(remoteStream, options);
        speechEvents.current.on('speaking', () => {
            setState(prevState => ({ ...prevState, active: true }));
        });
        speechEvents.current.on('stopped_speaking', () => {
            setState(prevState => ({ ...prevState, active: false }));
        });
    };

    // ... (rest of the methods remain largely the same, converted to arrow functions)

    const handleDrop = (files) => {
        DEBUG('Dropped file %o', files);
        fileTransferUtils.upload(
            {
                notificationCenter: notificationCenter,
                account: call.account
            },
            files,
            call.remoteIdentity.uri
        );
    };

    const handleFiles = (e) => {
        DEBUG('Selected files %o', e.target.files);
        fileTransferUtils.upload(
            {
                notificationCenter: notificationCenter,
                account: call.account
            },
            e.target.files,
            call.remoteIdentity.uri
        );
        e.target.value = '';
    };

    // ... (rest of the render logic)

    return (
        <React.Fragment>
            <DragAndDrop title="Drop files to share them" handleDrop={handleDrop}>
                {/* ... (rest of the JSX) */}
            </DragAndDrop>
        </React.Fragment>
    );
};

AudioCallBox.propTypes = {
    setDevice: PropTypes.func.isRequired,
    call: PropTypes.object,
    escalateToConference: PropTypes.func,
    hangupCall: PropTypes.func,
    mediaPlaying: PropTypes.func,
    remoteIdentity: PropTypes.string,
    forceTimerStart: PropTypes.bool,
    notificationCenter: PropTypes.func,
    toggleChatInCall: PropTypes.func,
    inlineChat: PropTypes.object,
    unreadMessages: PropTypes.object,
    propagateKeyPress: PropTypes.bool
};

export default AudioCallBox;
