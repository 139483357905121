import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { ListItemIcon } from '@mui/material';
import clsx from 'clsx';
import VolumeBar from '../VolumeBar';

const useStyles = makeStyles((theme) => ({
    audioLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '20px',
        flex: 3
    },
    audioLabelSelected: {
        paddingLeft: 0
    },
    icon: {
        minWidth: '20px'
    }
}));

const AudioMenuItem = ({ stream, selected, label }) => {
    const classes = useStyles();
    const volume = useRef();

    const isFailed = () => stream && stream === 'failed';

    return (
        <>
            {selected && (
                <ListItemIcon className={classes.icon}>
                    <i className="fa fa-check-circle"></i>
                </ListItemIcon>
            )}
            <div className={clsx(classes.audioLabel, { [classes.audioLabelSelected]: selected })}>
                {label}
            </div>
            {stream && !isFailed() && (
                <div style={{ flex: 1 }}>
                    <VolumeBar localMedia={stream} />
                </div>
            )}
        </>
    );
};

AudioMenuItem.propTypes = {
    stream: PropTypes.any,
    selected: PropTypes.bool,
    label: PropTypes.string
};

export default AudioMenuItem;
