import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debug from 'debug';
import { IconButton, Toolbar, CircularProgress, Typography } from '@mui/material';
import { SkipPreviousRounded, SkipNextRounded, Close as CloseIcon } from '@mui/icons-material';
import AudioPlayer from 'react-h5-audio-player';
import { usePrevious, useHasChanged } from '../../hooks';
import messageStorage from '../../messageStorage';
import fileTransferUtils from '../../fileTransferUtils';

const DEBUG = debug('blinkrtc:ToolbarAudioPlayer');

const ToolbarAudioPlayer = (props) => {
    const [messages, setMessages] = useState([]);
    const [audioMessages, setAudioMessages] = useState(null);
    const [currentTrack, setTrackIndex] = useState(null);

    const prevMessagesChanged = useHasChanged(props.messages);
    const oldAudioMessages = usePrevious(audioMessages);
    const player = useRef();

    const getDisplayName = useCallback((uri) => {
        if (props.contactCache.has(uri)) {
            return { uri: uri, displayName: props.contactCache.get(uri) };
        }
        return { uri: uri };
    }, [props.contactCache]);

    useEffect(() => {
        messageStorage.revertFiles(props.selectedAudioUri);
    }, [props.selectedAudioUri]);

    useEffect(() => {
        let ignore = false;
        DEBUG('Attempting to load all messages');

        const loadMore = () => {
            messageStorage.loadMoreFiles(props.selectedAudioUri).then((loadedMessages) => {
                DEBUG('Load more files');
                allMessages = [...loadedMessages, ...allMessages];
                canLoadMore();
            });
        };

        const canLoadMore = () => {
            DEBUG('Check if more files can be loaded');
            messageStorage.hasMoreFiles(props.selectedAudioUri).then((value) => {
                if (!value) {
                    if (!ignore) {
                        setMessages(allMessages);
                    }
                } else {
                    loadMore();
                }
            });
        };

        if (!prevMessagesChanged) {
            return;
        }

        let allMessages = [...props.messages];
        canLoadMore();

        return () => {
            ignore = true;
        };
    }, [props.messages, props.selectedAudioUri]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let ignore = false;

        let newAudioMessages = [];
        messages.filter((message) => {
            return message.contentType === 'application/sylk-file-transfer' && message.json.filetype;
        }).reverse().map((message) => {
            let file = message.json;
            if (file.filename.startsWith('sylk-audio-recording')) {
                newAudioMessages.push(
                    fileTransferUtils.getAndReadFile(props.account, message)
                        .then(([data, filename]) => {
                            return {
                                data: data, filename: filename, id: message.id, contact: message.sender.uri
                            };
                        }).catch((error) => {
                            return Promise.reject();
                        })
                );
            }
        });

        Promise.allSettled(newAudioMessages).then((results) => {
            let newData = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
            if (!ignore) {
                if (JSON.stringify(newData) !== JSON.stringify(oldAudioMessages)) {
                    setAudioMessages(newData);
                }
            }
        });

        return () => {
            ignore = true;
        };
    }, [messages, props.account]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let ignore = false;
        if (!audioMessages) return;

        if (!ignore) {
            let index = audioMessages.findIndex((data) => data.id === props.selectedAudioId);
            if (index >= 0) {
                setTrackIndex(index);
            }
        }
        return () => {
            ignore = true;
        };
    }, [audioMessages, props.selectedAudioId, props.selectedAudioUri]);

    const handleClickNext = () => {
        setTrackIndex((currentTrack) =>
            currentTrack < audioMessages.length ? currentTrack - 1 : 0
        );
    };

    const handleClickPrev = () => {
        setTrackIndex((currentTrack) =>
            currentTrack < audioMessages.length ? currentTrack + 1 : 0
        );
    };

    const handleEnd = () => {
        if (currentTrack === 0) {
            props.close();
            return;
        }
        setTrackIndex((currentTrack) => currentTrack - 1);
    };

    const previousButton = (
        <IconButton
            component="span"
            disabled={audioMessages && currentTrack === audioMessages.length - 1}
            sx={{
                borderColor: '#2e6da4',
                color: '#337ab7',
                width: '32px',
                height: '32px',
                margin: '0 3px',
                '&:hover': {
                    backgroundColor: '#286090',
                    borderColor: '#204d74',
                    boxShadow: 'none',
                    color: '#fff'
                }
            }}
            onClick={handleClickPrev}
        >
            <SkipPreviousRounded sx={{ fontSize: '30px' }} />
        </IconButton>
    );

    const nextButton = (
        <IconButton
            component="span"
            disabled={currentTrack === 0}
            sx={{
                borderColor: '#2e6da4',
                color: '#337ab7',
                width: '32px',
                height: '32px',
                margin: '0 3px',
                '&:hover': {
                    backgroundColor: '#286090',
                    borderColor: '#204d74',
                    boxShadow: 'none',
                    color: '#fff'
                }
            }}
            onClick={handleClickNext}
        >
            <SkipNextRounded sx={{ fontSize: '30px' }} />
        </IconButton>
    );

    const audioLoaded = audioMessages && audioMessages[currentTrack];

    const playerText = (
        <div style={{ flex: 1, paddingLeft: '10px' }}>
            {audioLoaded && (getDisplayName(audioMessages[currentTrack].contact).displayName || audioMessages[currentTrack].contact)}<br />
            <span style={{ color: '#888', fontSize: '12px' }}>Voice Message</span>
        </div>
    );

    return (
        <Toolbar sx={{ minHeight: 50, height: 50, backgroundColor: '#fff', margin: '-15px' }}>
            {!audioLoaded ? (
                <>
                    <CircularProgress sx={{ color: '#888', margin: '5px', marginRight: '10px', width: '35px', height: '35px', display: 'block' }} />
                    <Typography sx={{ flexGrow: 1, fontSize: '16px', fontFamily: 'inherit' }} variant="h6" noWrap>Loading</Typography>
                </>
            ) : (
                <AudioPlayer
                    style={{
                        width: '100%',
                        boxShadow: 'none',
                        fontSize: 'inherit',
                        background: 'none',
                        marginTop: '-2px'
                    }}
                    customProgressBarSection={['PROGRESS_BAR']}
                    customControlsSection={[
                        previousButton,
                        'MAIN_CONTROLS',
                        nextButton,
                        playerText,
                        <IconButton
                            component="span"
                            sx={{
                                borderColor: '#2e6da4',
                                color: '#337ab7',
                                width: '32px',
                                height: '32px',
                                margin: '0 3px',
                                '&:hover': {
                                    backgroundColor: '#286090',
                                    borderColor: '#204d74',
                                    boxShadow: 'none',
                                    color: '#fff'
                                }
                            }}
                            onClick={props.close}
                        >
                            <CloseIcon sx={{ fontSize: '24px' }} />
                        </IconButton>
                    ]}
                    ref={player}
                    layout="stacked-reverse"
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    preload="metadata"
                    src={audioLoaded && audioMessages[currentTrack].data}
                    autoPlayAfterSrcChange={false}
                    onEnded={handleEnd}
                />
            )}
        </Toolbar>
    );
};

ToolbarAudioPlayer.propTypes = {
    messages: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    contactCache: PropTypes.object.isRequired,
    selectedAudioUri: PropTypes.string,
    selectedAudioId: PropTypes.string
};

export default ToolbarAudioPlayer;
