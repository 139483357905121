import React, { createContext, useEffect, useState } from "react";
import { auth } from "../firebase_setup/firebase";
import { GoogleAuthProvider, signInWithPopup,
   signOut, onAuthStateChanged, FacebookAuthProvider,
  getAuth, OAuthProvider } from 'firebase/auth';

import { useNavigate } from 'react-router-dom'; // Import useNavigate

import axios from "axios";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null); // User information
  const [token, setToken] = useState(null); // Knox token from backend
  const [loading, setLoading] = useState(false); // Loading state
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loginError, setLoginError] = useState('');
  const [error, setError] = useState(null); // Error state



  // Function to handle Google Sign-In
  const googleSignIn = async () => {
    // Initialize the GoogleAuthProvider
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      // Get the signed-in user info
      const firebaseUser = result.user;

      // Get the ID token
      const idToken = await firebaseUser.getIdToken();

      // Send the ID token to your backend
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/auth/firebase/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: idToken }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const knoxToken = data.token;
      setUserToken(knoxToken);
      const user_data_response = await axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/user/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("userToken")}`.replace(/['"]+/g, '')
                }
            })
            .then(response => {
              if (response.data) {
                  const userData = response.data;
                  saveToLocalStorage("userInfo", userData);
              }
            });

      // Optionally store the token in localStorage
      localStorage.setItem('knoxToken', knoxToken);
    } catch (error) {
      console.error('Google Sign-In error:', error);
      setLoginError('Login failed. Please try again later.');
    }
  };

  // Function to handle Google Sign-In
  const facebookSignIn = async () => {
    const auth = getAuth();
    // Initialize the FacebookAuthProvider
    const provider = new FacebookAuthProvider();
    
    try {
      const result = await signInWithPopup(auth, provider);
      // Get the signed-in user info
      const firebaseUser = result.user;

      // Get the ID token
      const idToken = await firebaseUser.getIdToken();

      // Send the ID token to your backend
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/auth/firebase/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: idToken }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const knoxToken = data.token;
      setUserToken(knoxToken);
      const user_data_response = await axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/user/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("userToken")}`.replace(/['"]+/g, '')
                }
            })
            .then(response => {
              if (response.data) {
                  const userData = response.data;
                  saveToLocalStorage("userInfo", userData);
              }
            });

      // Optionally store the token in localStorage
      localStorage.setItem('knoxToken', knoxToken);
    } catch (error) {
      console.error('Facebook Sign-In error:', error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);
      setLoginError('Login failed. Please try again later.');
    }
  };

  // Function to handle Apple Sign-In
  const appleSignIn = async () => {
    const auth = getAuth();
    // Initialize the AppleAuthProvider
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    
    try {
      const result = await signInWithPopup(auth, provider);
      // Get the signed-in user info
      const firebaseUser = result.user;

      // Get the ID token
      const idToken = await firebaseUser.getIdToken();

      // Send the ID token to your backend
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/auth/firebase/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: idToken }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const knoxToken = data.token;
      setUserToken(knoxToken);
      // Ensure user data is fetched correctly
      const user_data_response = await axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/user/`, {
                headers: {
                    Authorization: `Token ${knoxToken}` // Use knoxToken instead of userToken
                }
            })
            .then(response => {
              if (response.data) {
                  const userData = response.data;
                  saveToLocalStorage("userInfo", userData);
              }
            });

      // Optionally store the token in localStorage
      localStorage.setItem('knoxToken', knoxToken);
    } catch (error) {
      console.error('Apple Sign-In error:', error);
      setLoginError('Login failed. Please try again later.');
    }
  };


  const axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
      headers: {
          'Content-Type': 'application/json',
      },
  });


  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };

  const register = async (username, email, password) => {
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_API_URL}/register/`, {
        username,
        email,
        password,
      });

      const userData = response.data;
      setUserInfo(userData.user);
      setUserToken(userData.token);
      console.log(userData);
      console.log(userInfo);
      console.log(userToken);

      saveToLocalStorage("userInfo", userData);
      saveToLocalStorage("userToken", userData.token);
    } catch (error) {
      console.error(`Registration error: ${error}`);
    }

    setIsLoading(false);
  };

  const login = async (username, password) => {
    setIsLoading(true);

    try {
      const response = await axiosInstance
        .post(`${process.env.REACT_APP_BACKEND_API_URL}/login/`, {username,password})
            .then(response => {
              if (response.data) {
                  const userToken = response.data.token;
                  const userTokenExpiry = response.data.expiry;
                  saveToLocalStorage("userToken", userToken);
                  saveToLocalStorage("userTokenExpiry", userTokenExpiry);
                  setUserToken(userToken);
                  console.log("Login Successfull");
              }
            });
      const user_data_response = await axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/user/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("userToken")}`.replace(/['"]+/g, '')
                }
            })
            .then(response => {
              if (response.data) {
                  const userData = response.data;
                  saveToLocalStorage("userInfo", userData);
              }
            });

    } catch (error) {
      console.error(`Login error: ${error}`);
      // Handle the error here. For example:
      setLoginError('Login failed. Please try again later.');
      console.error('There was an error!', error);
    }

    setIsLoading(false);
  };


  // Function to handle logout
  const logout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await signOut(auth);
      setUser(null);
      setToken(null);
      removeFromLocalStorage("userInfo");
      removeFromLocalStorage("userToken");
      localStorage.removeItem('knoxToken');
      // Refresh the page after successful login
      window.location.reload(); // Add this line

    } catch (error) {
      console.error('Logout error:', error);
      setError('Logout failed. Please try again.');
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  const isLoggedIn = () => {
    try {
      setIsLoading(true);

      const storedUserInfo = localStorage.getItem("userInfo");
      const storedUserToken = localStorage.getItem("userToken");

      const parsedUserInfo = JSON.parse(storedUserInfo);

      if (parsedUserInfo) {
        setUserToken(storedUserToken);
        setUserInfo(parsedUserInfo);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(`isLoggedIn error: ${error}`);
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      const handleAuthStateChanged = async () => {
        if (firebaseUser) {
          // User is signed in, get the ID token
          const idToken = await firebaseUser.getIdToken();

          // Optionally, send the ID token to your backend for silent authentication
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/auth/firebase/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token: idToken }),
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const knoxToken = data.token;
            const userToken = data.token;
            // Store the Knox token and user data
            setToken(knoxToken);
            setUser(data.user);
            console.log(data.user);

            // Optionally store the token in localStorage
            localStorage.setItem('knoxToken', knoxToken);
            localStorage.setItem('userToken', knoxToken);

            const user_data_response = await axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/user/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("userToken")}`.replace(/['"]+/g, '')
                }
            })
            .then(response => {
              if (response.data) {
                  const userData = response.data;
                  saveToLocalStorage("userInfo", userData);

              }
            });

          } catch (error) {
            console.error('Silent authentication error:', error);
            setError('Authentication failed. Please log in again.');
            setUser(null);
            setToken(null);
            localStorage.removeItem('knoxToken');
            localStorage.removeItem('userToken');
            localStorage.removeItem('userInfo');

          }
        } else {
          // User is signed out
          setUser(null);
          setToken(null);
          localStorage.removeItem('knoxToken');
          localStorage.removeItem('userToken');
          localStorage.removeItem('userInfo');

        }
      };

      handleAuthStateChanged();
    });

    return () => unsubscribe();
  }, []);


  return (
    <AuthContext.Provider
      value={{
        googleSignIn,
        facebookSignIn,
        appleSignIn,
        login,
        logout,
        register,
        isLoading,
        userToken,
        userInfo,
        loginError,
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
