'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Navbar, Dropdown, ButtonToolbar } from 'react-bootstrap';
import { withStyles } from '@mui/styles';
import { Badge, Tooltip } from '@mui/material';

import config from '../config';
import AboutModal from './AboutModal';
import CallMeMaybeModal from './CallMeMaybeModal';

const styles = {
    root: {
        float: 'left',
        marginLeft: 5
    },
    badge: {
        width: '20px',
        height: '20px',
        fontWeight: 'bold',
        fontSize: '1rem',
        backgroundColor: '#337ab7',
        '&.MuiBadge-anchorOriginTopLeftCircular': {
            top: '18%',
            left: '18%'
        },
        '&.MuiBadge-anchorOriginTopRightCircular': {
            top: '18%',
            right: '18%'
        }
    }
};

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAboutModal: false,
            showCallMeMaybeModal: false,
            mute: false
        };

        if (window.location.origin.startsWith('file://')) {
            this.callUrl = `${config.publicUrl}/call/${props.account.id}`;
        } else {
            this.callUrl = `${window.location.origin}/call/${props.account.id}`;
        }

        // ES6 classes no longer autobind
        [
            'handleMenu',
            'toggleAboutModal',
            'toggleCallMeMaybeModal',
            'toggleMute'
        ].forEach((name) => {
            this[name] = this[name].bind(this);
        });
    }

    handleMenu(event) {
        switch (event) {
            case 'about':
                this.toggleAboutModal();
                break;
            case 'callMeMaybe':
                this.toggleCallMeMaybeModal();
                break;
            case 'logOut':
                this.props.logout();
                break;
            case 'preview':
                this.props.preview();
                break;
            case 'shortcuts':
                this.props.toggleShortcuts();
                break;
            case 'settings':
                window.open('https://mdns.sipthor.net/sip_settings.phtml', '_blank');
                break;
            case 'export':
                this.props.exportPrivateKey();
                break;
            default:
                break;
        }
        document.activeElement.blur();
    }

    toggleMute() {
        this.setState(prevState => ({ mute: !prevState.mute }));
        this.props.toggleMute();
    }

    toggleAboutModal() {
        this.setState({ showAboutModal: !this.state.showAboutModal });
    }

    toggleCallMeMaybeModal() {
        this.setState({ showCallMeMaybeModal: !this.state.showCallMeMaybeModal });
    }

    render() {
        const notRegistered = this.props.account.registrationState !== 'registered';
        const muteClasses = clsx({
            'fa': true,
            'fa-2x': true,
            'fa-bell-o': !this.state.mute,
            'fa-bell-slash-o': this.state.mute,
            'text-warning': this.state.mute
        });

        const registrationClasses = clsx({
            'text-warning': notRegistered
        });

        const HtmlTooltip = withStyles({
            tooltip: {
                backgroundColor: '#fcf8e3',
                color: '#8a6d3b',
                maxWidth: 275,
                fontSize: '1.4rem',
                border: '1px solid #faebc',
                textAlign: 'left'
            }
        })(Tooltip);

        let title = '';
        if (notRegistered) {
            title = (
                <React.Fragment>
                    You're unable to receive SIP calls.<br />
                    You <strong>can</strong> still make outbound calls.
                </React.Fragment>
            );
        }

        const activeRoute = this.props.router.getPath();
        const defaultNavButtonClasses = clsx(
            'btn',
            'btn-link',
            'btn-fw'
        );

        const callNavButtonClasses = clsx(
            defaultNavButtonClasses,
            {
                'active': activeRoute.startsWith('/ready')
            }
        );

        const chatNavButtonClasses = clsx(
            defaultNavButtonClasses,
            {
                'active': activeRoute.startsWith('/chat')
            }
        );

        const unreadMessages = this.props.unreadMessages || 0;

        return (
            <Navbar bg="dark" variant="dark" fixed="top" expand="lg" fluid="true">
                <Navbar.Brand href="#">
                    Sylk
                </Navbar.Brand>
                <HtmlTooltip title={title}>
                    <p className="navbar-text hidden-xs">
                        {notRegistered ? 'Not signed' : 'Signed'} in as: <strong className={registrationClasses}>{this.props.account.id}</strong>
                        {notRegistered ? <span>&nbsp;<i className="fa fa-exclamation-circle text-warning" /></span> : ''}
                    </p>
                </HtmlTooltip>
                <ButtonToolbar className="navbar-btn-toolbar pull-right">
                    {this.props.enableMessaging &&
                        <button title="Home screen" className={callNavButtonClasses} onClick={() => this.props.router.navigate('/ready')}>
                            <i className="fa fa-home fa-2x" />
                        </button>
                    }
                    {this.props.enableMessaging &&
                        <Badge key="unreadBadge" badgeContent={unreadMessages} color="primary" classes={{ root: this.props.classes.root, badge: this.props.classes.badge }} overlap="circular">
                            <button title="Chat screen" className={chatNavButtonClasses} onClick={() => this.props.router.navigate('/chat')}>
                                <i className="fa fa-comments fa-2x" />
                            </button>
                        </Badge>
                    }
                    <button title="Mute Incoming Ringtones" className="btn btn-link btn-fw" onClick={this.toggleMute}>
                        <i className={muteClasses}></i>
                    </button>
                    <Dropdown id="blinkNavBar" drop="down" variant="link">
                        <Dropdown.Toggle as="i" className="fa fa-ellipsis-v fa-2x" />
                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <strong><i className="fa fa-user"></i> {this.props.account.id}</strong>
                            </Dropdown.Header>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="about" onClick={() => this.handleMenu('about')}>
                                <i className="fa fa-info-circle"></i> About Sylk
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="callMeMaybe" onClick={() => this.handleMenu('callMeMaybe')}>
                                <i className="fa fa-share"></i> Call me, maybe?
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="preview" onClick={() => this.handleMenu('preview')}>
                                <i className="fa fa-video-camera"></i> Video preview
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="settings" onClick={() => this.handleMenu('settings')}>
                                <i className="fa fa-wrench"></i> Server account settings
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="shortcuts" onClick={() => this.handleMenu('shortcuts')}>
                                <i className="fa fa-keyboard-o"></i> View shortcuts
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="export" onClick={() => this.handleMenu('export')}>
                                <i className="fa fa-key"></i> Export private key
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="logOut" onClick={() => this.handleMenu('logOut')}>
                                <i className="fa fa-sign-out"></i> Sign Out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonToolbar>
                <AboutModal
                    show={this.state.showAboutModal}
                    close={this.toggleAboutModal}
                />
                <CallMeMaybeModal
                    show={this.state.showCallMeMaybeModal}
                    close={this.toggleCallMeMaybeModal}
                    callUrl={this.callUrl}
                    notificationCenter={this.props.notificationCenter}
                />
            </Navbar>
        );
    }
}

NavigationBar.propTypes = {
    notificationCenter: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    preview: PropTypes.func.isRequired,
    toggleMute: PropTypes.func.isRequired,
    toggleShortcuts: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    enableMessaging: PropTypes.bool.isRequired,
    exportPrivateKey: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    unreadMessages: PropTypes.number
};

export default withStyles(styles)(NavigationBar);
