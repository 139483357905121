import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";
import Logo from "./../../assets/images/logofav.png";

const StyledSidebarHeader = styled.div`
  height: 54px;
  min-height: 54px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  background-color: skyblue;
`;

function SidebarHeader({ onToggleSidebar, children, rtl, ...rest }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const logoStyle = rtl
    ? { marginLeft: "10px", marginRight: "30px" }
    : { marginRight: "30px", marginLeft: "0px" };

  return (
    <StyledSidebarHeader {...rest}>
      <Link to="/app/helpline/" style={{ textDecoration: "none" }}>
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          icon={
            <StyledLogo style={logoStyle}>
              <img
                src={Logo}
                alt="Logo"
                style={{ height: "30px", width: "30px" }}
              />
            </StyledLogo>
          }
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <StyledLogo style={logoStyle}>
              <img
                src={Logo}
                alt="Logo"
                style={{ height: "30px", width: "30px" }}
              />
            </StyledLogo>
            <Typography
              fontSize="17px"
              fontWeight={700}
              color={colors.skyblueAccent[500]}
            >
              VII
            </Typography>
          </Box>
        </Button>
      </Link>
    </StyledSidebarHeader>
  );
}

export default SidebarHeader;
