import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import cloneDeep from 'lodash/cloneDeep';
import { styled } from '@mui/material/styles';
import { CircularProgress, Toolbar, Divider, Typography, IconButton, useMediaQuery } from '@mui/material';
import { Phone as PhoneIcon, VideoCamera as VideoCameraIcon } from '@mui/icons-material';
import clsx from 'clsx';

import ConferenceDrawer from './ConferenceDrawer';
import ContactList from './Chat/ContactList';
import UserIcon from './UserIcon';
import MessageList from './Chat/MessageList';
import InfoPanel from './Chat/InfoPanel';
import ConferenceChatEditor from './ConferenceChatEditor';
import VoiceMessageRecorderModal from './Chat/VoiceMessageRecorderModal';
import ToolbarAudioPlayer from './Chat/ToolbarAudioPlayer';

import * as utils from '../utils';
import * as fileTransferUtils from '../fileTransferUtils';

const DEBUG = debug('blinkrtc:Chat');

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: '50px',
    height: 50,
    marginBottom: 15
}));

const Title = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    display: 'block',
    fontSize: '16px',
    fontFamily: 'inherit'
}));

const CenterTitle = styled(Typography)(({ theme }) => ({
    display: 'block',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'inherit'
}));

const ToolbarName = styled('span')(({ theme }) => ({
    paddingLeft: 5,
    fontWeight: 'normal',
    color: '#888'
}));

const Spacer = styled('div')(({ theme, height }) => ({
    minHeight: height,
    height: height
}));

const AudioToolbar = styled('div')(({ theme }) => ({
    width: 'calc(100% - 415px)',
    marginLeft: '415px',
    height: '50px',
    position: 'fixed',
    textAlign: 'left',
    color: '#333',
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 0
    }
}));

const Chat = (props) => {
    const [messages, setMessages] = useState({});
    const [filter, setFilter] = useState('');
    const [unread, setUnread] = useState('');
    const [show, setShow] = useState(false);
    const [focus, setFocus] = useState('');
    const [selectedUri, setSelectedUri] = useState('');
    const [selectedAudioUri, setSelectedAudioUri] = useState('');
    const [selectedAudioId, setSelectedAudioId] = useState('');
    const [showVoiceMessageRecordModal, setVoiceMessageRecordModal] = useState(false);
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const [editMessage, setEditMessage] = useState('');

    const selectedUriRef = useRef(selectedUri);
    const messagesRef = useRef(messages);
    const contactCache = useRef(props.contactCache);
    const anchorEl = useRef(null);
    const input = useRef();

    let propagateFocus = false;

    const setSelectedUriWithRef = useCallback((uri) => {
        selectedUriRef.current = uri;
        setSelectedUri(uri);
        setShowInfoPanel(false);
    }, []);

    const setMessagesWithRef = useCallback((data) => {
        messagesRef.current = data;
        setMessages(data);
    }, []);

    const componentJustMounted = useRef(true);
    let timer = null;

    useEffect(() => {
        setSelectedUriWithRef(props.focusOn);
    }, [props.focusOn, setSelectedUriWithRef]);

    useEffect(() => {
        if (show && props.focusOn) {
            setMessagesWithRef((prevMessages) => {
                const newMessages = { ...prevMessages };
                if (!newMessages[props.focusOn]) {
                    newMessages[props.focusOn] = [];
                }
                return newMessages;
            });
        }
    }, [show, props.focusOn, setMessagesWithRef]);

    useEffect(() => {
        if (props.account === null) {
            return;
        }

        DEBUG('Loading messages');

        const incomingMessage = (message) => {
            DEBUG('Incoming Message from: %s', message.sender.uri);
            let oldMessages = Object.assign({}, messagesRef.current);
            let hasId = false;
            if (!oldMessages[message.sender.uri]) {
                oldMessages[message.sender.uri] = [];
            } else {
                for (const [key, messages] of Object.entries(oldMessages)) {
                    if (messages.filter(m => m.id == message.id).length > 0) {
                        hasId = true;
                        break;
                    }
                }
            }
            if (hasId) {
                return;
            }

            oldMessages[message.sender.uri].push(message);
            oldMessages[message.sender.uri].sort((a, b) => a.timestamp - b.timestamp);
            if (selectedUriRef.current === message.sender.uri) {
                DEBUG('We have this contact selected');
            }
            setMessages(oldMessages);
        };

        const messageStateChanged = (message) => {
            DEBUG('Message state changed: %o', message);
            let oldMessages = Object.assign({}, messagesRef.current);
            setMessages(oldMessages);
        };

        const outgoingMessage = (message) => {
            if (message.contentType !== 'text/pgp-private-key') {
                const oldMessages = Object.assign({}, messagesRef.current);
                if (!oldMessages[message.receiver]) {
                    oldMessages[message.receiver] = [];
                }
                oldMessages[message.receiver].push(message);
                setMessages(oldMessages);
            }
        };

        const removeMessage = (message) => {
            const oldMessages = cloneDeep(messagesRef.current);
            let key = message.receiver;
            if (message.state === 'received') {
                key = message.sender.uri;
            }
            if (oldMessages[key]) {
                oldMessages[key] = oldMessages[key].filter(loadedMessage => loadedMessage.id !== message.id);
                setMessages(oldMessages);
            }
        };

        const newMessages = cloneDeep(props.oldMessages);
        for (let message of props.account.messages) {
            const senderUri = message.sender.uri;
            const receiver = message.receiver;
            let key = receiver;
            if (message.state === 'received') {
                key = senderUri;
            }
            if (!newMessages[key]) {
                newMessages[key] = [];
            }
            newMessages[key].push(message);
        };

        for (let contact of Object.keys(newMessages)) {
            newMessages[contact].sort((a, b) => a.timestamp - b.timestamp);
        }

        setMessages(newMessages);
        setShow(true);
        props.account.on('incomingMessage', incomingMessage);
        props.account.on('messageStateChanged', messageStateChanged);
        props.account.on('outgoingMessage', outgoingMessage);
        props.account.on('removeMessage', removeMessage);

        componentJustMounted.current = false;

        return () => {
            DEBUG('Running leave hook');
            setShow(false);
            props.account.removeListener('incomingMessage', incomingMessage);
            props.account.removeListener('messageStateChanged', messageStateChanged);
            props.account.removeListener('outgoingMessage', outgoingMessage);
            props.account.removeListener('removeMessage', removeMessage);
        };
    }, [props.account, props.oldMessages, setMessagesWithRef]);

    const loadMessages = (uri, id) => {
        // Remove entries with 0 messages from contact list
        if (contactMessages.length === 0) {
            const oldMessages = cloneDeep(messagesRef.current);
            delete oldMessages[selectedUri];
            setMessages(oldMessages);
        }

        if (uri !== selectedAudioUri && !selectedAudioUri) {
            setSelectedAudioUri(uri);
        }

        if (uri !== selectedUri) {
            setSelectedUri(uri);
            props.lastContactSelected(uri);
            if (id) {
                DEBUG('Focus message: %s', id);
                setFocus(id);
                setTimeout(() => { setFocus('') }, 750)
            }
            setEditMessage('');
        } else {
            DEBUG('Focus message: %s', id);
            setFocus(id);
            setTimeout(() => { setFocus('') }, 750)
        }
    };

    const loadMoreMessages = () => {
        return props.loadMoreMessages(selectedUri);
    };

    const toggleChatEditorFocus = () => {
        props.propagateKeyPress(propagateFocus);
        propagateFocus = !propagateFocus;
    };

    const toggleRecordVoiceMessage = (target) => {
        anchorEl.current = target || null;
        setVoiceMessageRecordModal(!showVoiceMessageRecordModal);
    };

    const filterMessages = () => {
        setFilter(input.current.value);
    }

    const togglePanel = () => {
        setShowInfoPanel(!showInfoPanel)
    }

    const contactMessages = messages[selectedUri] ? [...messages[selectedUri]] : [];
    const contactAudioMessages = messages[selectedAudioUri] ? [...messages[selectedAudioUri]] : [];

    const handleFiles = (e) => {
        DEBUG('Selected files %o', e.target.files);
        fileTransferUtils.upload(props, e.target.files, selectedUri);
        e.target.value = '';
    }

    const handleMessage = (content, type) => {
        if (editMessage) {
            if (editMessage.content !== content) {
                props.account.sendMessage(selectedUri, content, editMessage.contentType, { timestamp: editMessage.timestamp }, () => {
                    props.removeMessage(editMessage);
                });
            }
            setEditMessage();
            return;
        }
        let message = props.account.sendMessage(selectedUri, content, type);
        setMessages({ ...messages, [selectedUri]: [...contactMessages, message] });
    };

    const handleDownload = (...args) => {
        let { filename } = args[0];
        let notification = props.notificationCenter().postPreparingFileDownload(filename);

        fileTransferUtils.download(props.account, ...args).then(() => {
            props.notificationCenter().removeNotification(notification);
        }).catch(({ error, filename }) => {
            props.notificationCenter().removeNotification(notification);
            props.notificationCenter().postFileDownloadFailed(filename, error)
        })
    };

    const handleMessageEdit = (message) => {
        setEditMessage(message);
    }

    const defaultDomain = props.account.id.substring(props.account.id.indexOf('@') + 1);

    const startChat = () => {
        if (input.current.value !== '') {
            const target = utils.normalizeUri(input.current.value, defaultDomain);
            setSelectedUri(target);
            DEBUG('Starting new chat to: %s', target);
            let oldMessages = cloneDeep(messages);
            if (!oldMessages[target]) {
                oldMessages[target] = [];
            }
            input.current.value = '';
            setFilter('');
            setMessages(oldMessages);
        }
    };

    const selectAudio = (id) => {
        setSelectedAudioUri(selectedUri);
        setSelectedAudioId(id);
    }

    const selectContactAudio = (id, uri) => {
        setSelectedAudioUri(uri);
        setSelectedAudioId(id);
    }

    const resetSelectedAudio = () => {
        setSelectedAudioId('');
        setSelectedAudioUri('');
    }

    const getDisplayName = (uri) => {
        if (props.contactCache.has(uri)) {
            return { uri: uri, displayName: props.contactCache.get(uri) };
        }
        return { uri: uri };
    };

    const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const chevronIcon = clsx({
        'fa': true,
        'fa-chevron-left': true
    });

    const messageDisplayed = (uri, id, timestamp, state) => {
        props.account.sendDispositionNotification(
            uri,
            id,
            timestamp,
            state
        );
        if (timer !== null) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            let sendMark = true;
            for (let message of messages[uri]) {
                if (message.state === 'received'
                    && message.dispositionState !== 'displayed'
                    && message.dispositionNotification.indexOf('display') !== -1
                    && message.id !== id
                ) {
                    sendMark = false;
                    break;
                }
            }
            if (sendMark) {
                if (unread === uri) {
                    setUnread()
                } else {
                    setUnread(uri)
                }
                props.account.markConversationRead(uri);
                timer = null;
            }
        }, 500);
    };

    const messagePane = (
        <React.Fragment key="pane">
            <MessageList
                loadMoreMessages={loadMoreMessages}
                messages={contactMessages}
                focus={focus}
                key={selectedUri}
                hasMore={() => props.messageStorage.hasMore(selectedUri)}
                contactCache={contactCache.current}
                displayed={messageDisplayed}
                removeMessage={(message) => props.removeMessage(message)}
                editMessage={(message) => handleMessageEdit(message)}
                isLoadingMessages={props.isLoadingMessages}
                account={props.account}
                uploadFiles={(...args) => fileTransferUtils.upload(props, ...args, selectedUri)}
                downloadFiles={handleDownload}
                embed={props.embed}
                storageLoadEmpty={props.storageLoadEmpty}
            />
            <ConferenceChatEditor
                onSubmit={handleMessage}
                onTyping={() => { }}
                scroll={() => { }}
                focus={toggleChatEditorFocus}
                setFocus={true}
                upload={handleFiles}
                enableVoiceMessage={true}
                toggleRecordVoiceMessage={toggleRecordVoiceMessage}
                editMessage={editMessage}
                cancelEdit={() => { setEditMessage(''); }}
                multiline
            />
        </React.Fragment>
    );

    const infoPane = (
        <React.Fragment key="infopane">
            <InfoPanel
                key={selectedUri}
                startMessages={contactMessages}
                contactCache={contactCache.current}
                removeMessage={(message) => props.removeMessage(message)}
                account={props.account}
                uploadFiles={(...args) => fileTransferUtils.upload(props, ...args, selectedUri)}
                downloadFiles={handleDownload}
                selectedUri={selectedUri}
                selectAudio={selectAudio}
            />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {!props.embed && (
                <div className="chat">
                    {selectedAudioId !== '' && (
                        <AudioToolbar style={{ top: selectedUri ? '115px' : '66px' }}>
                            <ToolbarAudioPlayer
                                account={props.account}
                                messages={contactAudioMessages}
                                contactCache={contactCache.current}
                                selectedAudioUri={selectedAudioUri}
                                selectedAudioId={selectedAudioId}
                                close={resetSelectedAudio}
                            />
                        </AudioToolbar>
                    )}
                    <ConferenceDrawer
                        show={show && !showInfoPanel && (!matches || selectedUri !== '')}
                        size="full"
                        anchor="right"
                        close={() => setShow(false)}
                        position="full"
                        noBackgroundColor
                        showClose={false}
                        slideProps={{ direction: 'right', unmountOnExit: false }}
                    >
                        {/* ... (rest of the JSX) */}
                    </ConferenceDrawer>
                    {/* ... (rest of the drawers) */}
                </div>
            )}
            {props.embed && props.isLoadingMessages && (
                <StyledToolbar style={{ marginLeft: '-15px', marginTop: '-15px', marginRight: '-15px' }}>
                    <CircularProgress style={{ color: '#888', margin: '5px', marginRight: '10px', width: '35px', height: '35px', display: 'block' }} />
                    <Title variant="h6" noWrap>
                        Updating
                    </Title>
                    <Divider absolute />
                </StyledToolbar>
            )}
            {props.embed && messagePane}
            {showVoiceMessageRecordModal && (
                <VoiceMessageRecorderModal
                    show={showVoiceMessageRecordModal}
                    close={toggleRecordVoiceMessage}
                    contact={selectedUri}
                    anchorElement={anchorEl.current}
                    sendAudioMessage={(...args) => fileTransferUtils.upload(props, ...args, selectedUri)}
                />
            )}
        </React.Fragment>
    );
};

Chat.propTypes = {
    // ... (propTypes remain the same)
};

export default Chat;
