import React from 'react';
import Navbar from '../scenes/landing/Public/Navbar';
import Footer from '../scenes/landing/Public/Footer';
import { Container, Grid, Typography, Button, Card, CardContent, Box } from '@mui/material';
import { Phone, Email, BarChart, CallSplit, Storage, RecordVoiceOver, Cloud, Build, Security, Language } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for routing


const features = [
  { icon: <Phone />, title: "Omni-Channel Communication", description: "Handle customer queries across voice, email, chat, and social media from a single interface." },
  { icon: <BarChart />, title: "Real-Time Analytics", description: "Monitor performance with detailed metrics and customizable reports to gain insights into customer interactions." },
  { icon: <CallSplit />, title: "Advanced Routing", description: "Intelligent call routing ensures the right agent handles each call, improving first contact resolution rates." },
  { icon: <Storage />, title: "CRM Integration", description: "Seamless integration with popular CRM platforms like Salesforce and HubSpot to provide agents with all the necessary customer data." },
  { icon: <RecordVoiceOver />, title: "Call Recording and Monitoring", description: "Record and monitor calls for quality assurance, agent training, and compliance purposes." },
  { icon: <Build />, title: "Workforce Management", description: "Schedule shifts, track attendance, and manage agent performance with powerful workforce management tools." },
  { icon: <Cloud />, title: "Cloud-Based", description: "Accessible from anywhere, ensuring that your team can work remotely without interruptions." },
  { icon: <Build />, title: "AI-Powered Chatbots", description: "Automate routine customer queries with intelligent chatbots, reducing the workload for your agents." },
  { icon: <Security />, title: "Enterprise-Grade Security", description: "Ensure your communications are safe with end-to-end encryption and advanced security features." },
  { icon: <Language />, title: "Multi-Language Support", description: "Serve a global customer base with multi-language support for both agents and customers." },
];

const CallCenterSolutionPage = () => {
    const navigate = useNavigate();  // Hook for navigation

    const handleContactNavigation = () => {
        navigate('/#contact');  // Navigate to contact form page
    };

  return (
    <>
      <Navbar />
      <Container
      component="main"
      sx={{ marginTop: '64px' }} // Adjust the margin based on your navbar height
      >
        <Box
          display="flex"
          py={5}
          textAlign="center"
          flexDirection="column"
          justifyContent="center"
          minHeight="80vh" // Center the login form vertically
        >
      {/* Header Section */}
        <Typography variant="h3" gutterBottom>
          Full Scale Call Center Solution
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Empower your business with a comprehensive call center platform designed to streamline communication and enhance customer service delivery.
        </Typography>
      </Box>

      {/* Key Features Section */}
      <Box py={5}>
        <Typography variant="h4" gutterBottom>
          Key Features
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Box color="primary.main" mr={2}>
                      {feature.icon}
                    </Box>
                    <Typography variant="h6">{feature.title}</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Benefits Section */}
      <Box py={5}>
        <Typography variant="h4" gutterBottom>
          Benefits for Your Business
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Improved Customer Satisfaction</Typography>
            <Typography variant="body1" color="textSecondary">
              Reduced wait times and seamless communication elevate customer experience.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Increased Agent Productivity</Typography>
            <Typography variant="body1" color="textSecondary">
              Tools like AI-assist and CRM integrations enable agents to work efficiently.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Cost Savings</Typography>
            <Typography variant="body1" color="textSecondary">
              Scale up or down based on your call volume, ensuring you only pay for what you use.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Data-Driven Decision Making</Typography>
            <Typography variant="body1" color="textSecondary">
              With real-time analytics and reporting, you can track performance and optimize operations.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* How It Works Section */}
      <Box py={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          How It Works
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Our platform can be up and running in a matter of minutes. Here’s how it works:
        </Typography>
        <ul style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>
          <li><strong>Onboarding:</strong> We guide you through the initial setup, helping you configure your call flows, user accounts, and integrations.</li>
          <li><strong>Custom Configuration:</strong> Tailor the dashboard to meet your specific needs, whether for support, sales, or both.</li>
          <li><strong>Agent Training:</strong> We provide comprehensive training to ensure your agents are fully equipped to use the platform.</li>
          <li><strong>Go Live:</strong> Start receiving calls, track performance, and enjoy the benefits of a seamless communication system.</li>
        </ul>
      </Box>

      {/* Call to Action Section */}
      <Box py={5} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleContactNavigation}  // Link to contact form
        >
          Schedule a Demo
        </Button>
      </Box>


        </Container>
      <Footer />
		</>
  );
};

export default CallCenterSolutionPage;
