'use strict';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material';
import { Close as CloseIcon, CheckCircleOutline as OkIcon } from '@mui/icons-material';
import AuthCode from 'react-auth-code-input';

const useStyles = makeStyles((theme) => ({
    bigger: {
        '&> h2': {
            fontSize: '20px'
        },
        '&> div > p ': {
            fontSize: '14px'
        },
        '&> li.MuiListSubheader-root': {
            fontSize: '14px',
            textAlign: 'left'
        }
    },
    fixFont: {
        fontFamily: 'inherit',
        fontSize: '14px'
    },
    fixFontBigger: {
        fontFamily: 'inherit',
        fontSize: '16px',
        fontWeight: 300
    },
    container: {
        padding: 16
    },
    input: {
        width: '2ch',
        padding: 8,
        borderRadius: 8,
        fontSize: 32,
        textAlign: 'center',
        marginRight: 12,
        border: '1px solid #ddd',
        background: 'none',
        textTransform: 'uppercase',
        boxSizing: 'content-box',
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            marginRight: 6,
            border: 'none',
            padding: 5,
            borderRadius: 0,
            borderBottom: '2px solid black',
            '&:focus': {
                outline: 'none'
            }
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        '&> span > svg': {
            fontSize: 24
        }
    },
    okIcon: {
        fontSize: 75,
        color: '#3c763d'
    }
}));

const ImportModal = (props) => {
    const classes = useStyles();
    const [reset, setReset] = useState(0);
    const [success, setSuccess] = useState(false);

    const verifyPass = (value) => {
        if (value.length === 6) {
            props.account.decryptKeyImport(props.message, value, (result) => {
                if (result.didDecrypt === true) {
                    setSuccess(true);
                    setTimeout(() => {
                        props.close();
                        setSuccess(false);
                    }, 4000);
                    props.importKey(result);
                } else {
                    setReset(1);
                    setImmediate(() => {
                        setReset(0);
                    });
                }
            });
        }
    };

    return (
        <Dialog
            open={props.show}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.close();
                }
            }}
            maxWidth="sm"
            fullWidth
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            disableEscapeKeyDown
        >
            <DialogTitle id="dialog-title" className={classes.bigger}>
                Import Private Key
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.close}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {!success ? (
                    <>
                        <DialogContentText id="dialog-description" className={classes.fixFont}>
                            Sylk uses end-to-end encryption for messaging and needs a private key.
                        </DialogContentText>
                        {reset === 0 && (
                            <AuthCode
                                allowedCharacters="^[0-9]"
                                characters={6}
                                containerClassName={classes.container}
                                inputClassName={classes.input}
                                onChange={verifyPass}
                            />
                        )}
                        <DialogContentText id="dialog-explain" className={classes.fixFont}>
                            A private key has been sent from one of your other devices. Enter the code shown on the sending device to import it.
                        </DialogContentText>
                    </>
                ) : (
                    <>
                        <OkIcon className={classes.okIcon} />
                        <DialogContentText id="dialog-description" className={classes.fixFontBigger}>
                            Key was decrypted successfully
                        </DialogContentText>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

ImportModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    importKey: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired
};

export default ImportModal;
