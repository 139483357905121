import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ipaddr from 'ipaddr.js';
import { makeStyles } from '@mui/styles';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import EnrollmentModal from './EnrollmentModal';
import storage from '../storage';
import config from '../config';

const useStyles = makeStyles({
    root: {
        color: '#ffffff',
        height: 48,
        width: 48,
        '&$checked': {
            color: '#ffffff'
        },
        '& .MuiSvgIcon-root': {
            fontSize: 24
        }
    },
    checked: {},
    font: {
        fontSize: '14px !important',
        color: '#ffffff'
    },
    center: {
        justifyContent: 'center'
    }
});

const RegisterForm = ({ handleRegistration, registrationInProgress, autoLogin }) => {
    const classes = useStyles();
    const [accountId, setAccountId] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

    useEffect(() => {
        storage.get('account').then((account) => {
            if (account) {
                setAccountId(account.accountId);
                setPassword(account.password);
                setRemember(true);
                if (autoLogin && account.password) {
                    handleRegistration(account.accountId, account.password);
                }
            }
        });
    }, [autoLogin, handleRegistration]);

    const handleAccountIdChange = (event) => {
        setAccountId(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleRememberChange = (event) => {
        setRemember(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let account = accountId;
        if (!account.includes('@')) {
            account = `${account}@${config.defaultDomain}`;
        }
        handleRegistration(account, password, remember);
    };

    const handleEnrollment = (account) => {
        setShowEnrollmentModal(false);
        if (account) {
            setAccountId(account.accountId);
            setPassword(account.password);
            setRegistering(true);
            handleRegistration(account.accountId, account.password);
        }
    };

    const handleInvalid = (event) => {
        event.preventDefault();
        handleSubmit(event);
    };

    const createAccount = (event) => {
        event.preventDefault();
        setShowEnrollmentModal(true);
    };

    const domain = accountId.substring(accountId.indexOf('@') + 1);
    const validDomain = !ipaddr.IPv4.isValidFourPartDecimal(domain) && !ipaddr.IPv6.isValid(domain);
    const validInput = validDomain && password !== '';

    const formClasses = clsx('form-signin', { 'form-signin-electron': autoLogin });

    return (
        <div>
            <p className="lead">Sign in to continue</p>
            <form className={formClasses} onSubmit={handleSubmit}>
                <label htmlFor="inputUser" className="sr-only">Sip Account</label>
                <div className="input-group">
                    <span className="input-group-addon first"><i className="fa fa-globe fa-fw"></i></span>
                    <input 
                        type="email" 
                        id="inputUser" 
                        className="form-control" 
                        placeholder="Enter your account" 
                        value={accountId} 
                        onChange={handleAccountIdChange} 
                        required 
                        autoFocus 
                        onInvalid={handleInvalid} 
                    />
                </div>
                <label htmlFor="inputPassword" className="sr-only">Password</label>
                <div className="input-group">
                    <span className="input-group-addon second"><i className="fa fa-lock fa-fw"></i></span>
                    <input 
                        type="password" 
                        id="inputPassword" 
                        className="form-control" 
                        placeholder="Password" 
                        value={password} 
                        onChange={handlePasswordChange} 
                        required 
                    />
                </div>

                {!autoLogin && (
                    <FormGroup row className={classes.center}>
                        <FormControlLabel
                            classes={{ label: classes.font }}
                            control={
                                <Checkbox
                                    checked={remember}
                                    onChange={handleRememberChange}
                                    classes={{ root: classes.root, checked: classes.checked }}
                                />
                            }
                            label="Remember me"
                        />
                    </FormGroup>
                )}

                <div className="btn-group btn-group-justified">
                    <div className="btn-group">
                        <button 
                            type="submit" 
                            className={clsx('btn btn-lg btn-block', {
                                'btn-default': !validInput,
                                'btn-primary': validInput && !registering,
                                'btn-info': registering
                            })} 
                            disabled={registrationInProgress || !validInput}
                        >
                            <i className="fa fa-sign-in"></i>&nbsp;Sign In
                        </button>
                    </div>
                    <div className="btn-group">
                        <button 
                            className="btn btn-lg btn-primary" 
                            onClick={createAccount} 
                            disabled={registrationInProgress}
                        >
                            <i className="fa fa-plus"></i>&nbsp;Sign Up
                        </button>
                    </div>
                </div>
            </form>
            <EnrollmentModal show={showEnrollmentModal} handleEnrollment={handleEnrollment} />
        </div>
    );
};

RegisterForm.propTypes = {
    handleRegistration: PropTypes.func.isRequired,
    registrationInProgress: PropTypes.bool.isRequired,
    autoLogin: PropTypes.bool
};

export default RegisterForm;
