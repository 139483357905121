import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const HighlightedText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: '#2891d6',
  letterSpacing: '1px',
  fontWeight: 700,
  lineHeight: 2,
}));

const serviceList = {
  inbound: [
    "Customer Service",
    "Technical and Helpdesk Support",
    "Reservation and Bookings",
    "Appointments",
    "Order Processing",
    "Multilingual Call Center Support",
    "Email Support",
    "24/7 Answering Service",
    "Live Chat Support Services"
  ],
  outbound: [
    "Appointment",
    "Telemarketing",
    "Telesales",
    "B2B Sales",
    "Lead Generation",
    "Phone Surveys"
  ]
};

const CallCenterServices = () => {
  return (
    <StyledSection id="CallCenterServices">
      <Container>
        <Box textAlign="center" mb={6}>
          <Typography variant="h2" component="h2" gutterBottom>
            Call Center Services
          </Typography>
          <Typography variant="body1" paragraph>
            Providing Business with Top-Notch 24-Hour Contact Center Solutions
          </Typography>
          <Typography variant="body1" paragraph>
            Zerxis Limited is dedicated to accomplishing one goal in mind:
          </Typography>
          <HighlightedText variant="body1" paragraph>
            To grow your business from this day forward.
          </HighlightedText>
          <Typography variant="body1">
            That is why the company offers a wide range of inbound and outbound call center services.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardMedia
                component="img"
                image="/assets/img/inbound-new.png"
                alt="Inbound Call Center Services"
                style={{ width: 200, height: 200, marginBottom: 16 }}
              />
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom>
                  Inbound Call Center Services
                </Typography>
                {serviceList.inbound.map((service, index) => (
                  <Typography key={index} variant="body2" paragraph>
                    {service}
                  </Typography>
                ))}
                <Button color="primary">More</Button>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardMedia
                component="img"
                image="/assets/img/outbound-new.png"
                alt="Outbound Call Center Services"
                style={{ width: 200, height: 200, marginBottom: 16 }}
              />
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom>
                  Outbound Call Center Services
                </Typography>
                {serviceList.outbound.map((service, index) => (
                  <Typography key={index} variant="body2" paragraph>
                    {service}
                  </Typography>
                ))}
                <Button color="primary">More</Button>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default CallCenterServices;
