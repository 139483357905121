import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import { DateTime } from 'luxon';
import { useInView } from 'react-intersection-observer';
import { resolveMime } from 'friendly-mimes';
import { Box, CircularProgress, IconButton, ImageList, ImageListItem, List, ListItem, ListItemText, ListItemIcon, Paper, Typography, useMediaQuery, Tab, Tabs } from '@mui/material';
import { PlayArrowRounded, DescriptionOutlined as FileIcon } from '@mui/icons-material';
import UserIcon from '../UserIcon';
import DragAndDrop from '../DragAndDrop';
import ImagePreviewModal from './ImagePreviewModal';
import ListWithStickyHeader from '../ListWithStickyHeader';
import TabPanel from '../TabPanel';
import { usePrevious, useResize, useHasChanged } from '../../hooks';
import fileTransferUtils from '../../fileTransferUtils';
import messageStorage from '../../messageStorage';

const DEBUG = debug('blinkrtc:InfoPanel');

const formatTime = (fileDate) => DateTime.fromJSDate(fileDate).toLocaleString(DateTime.DATETIME_MED);
const formatListTime = (message) => DateTime.fromJSDate(message.timestamp).toFormat('MMMM yyyy');

const groupByMonth = (xs) => xs.reduce((rv, x) => {
    const key = formatListTime(x);
    (rv[key] = rv[key] || []).push(x);
    return rv;
}, {});

const groupImagesByMonth = (xs) => xs.reduce((rv, x) => {
    const key = formatListTime(x.message);
    (rv[key] = rv[key] || []).push(x);
    return rv;
}, {});

const fileSize = (size) => {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const fileInfo = (fileData) => {
    let filetype = 'Unknown';
    if (fileData.filetype) {
        try {
            filetype = resolveMime(fileData.filetype).name;
        } catch (error) {
            filetype = fileData.filetype;
        }
    }
    return `${fileSize(fileData.filesize)} - ${filetype} - ${formatTime(fileData.timestamp)}`;
};

const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
});

const InfoPanel = ({
    startMessages,
    contactCache,
    removeMessage,
    account,
    uploadFiles,
    downloadFiles,
    selectedUri,
    selectAudio
}) => {
    const [images, setImages] = useState(null);
    const [voiceMessages, setVoiceMessages] = useState(null);
    const [files, setFiles] = useState(null);
    const [display, setDisplay] = useState(false);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [more, setMore] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [noFilesFound, setNoFilesFound] = useState(false);
    const [image, setImage] = useState('');
    const [messages, setMessages] = useState(startMessages);
    const [message, setMessage] = useState({});
    const [panel, width] = useResize();
    const { ref, inView } = useInView({ threshold: 0 });

    const prevMessagesChanged = useHasChanged(messages);
    const oldImages = usePrevious(images || []);
    const oldVoiceMessages = usePrevious(voiceMessages || []);
    const oldFiles = usePrevious(files || []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const matches = useMediaQuery('(max-width:1059.95px)');

    const getImage = (message) => {
        fileTransferUtils.getAndReadFile(account, message).then(([imageData, filename]) => {
            setImage(imageData);
            message.filename = filename;
            setMessage(message);
            setShowModal(true);
        });
    };

    const getDisplayName = (uri) => {
        return contactCache.has(uri) ? { uri, displayName: contactCache.get(uri) } : { uri };
    };

    const getContact = (message) => {
        const contact = message.state === 'received' ? message.sender.uri : message.receiver;
        return getDisplayName(contact).displayName || contact;
    };

    const getFilename = (json) => json.filename.replace('.asc', '').replace(/_/g, ' ');

    const getFileList = (files) => {
        const sortedFiles = groupByMonth(files);

        return Object.keys(sortedFiles).map((key, index) => (
            <ListWithStickyHeader key={`files-${key}`} header={<Typography sx={{ padding: '4px', fontSize: 16, fontWeight: 300 }}>{key}</Typography>}>
                <Paper elevation={0}>
                    <Box p={0}>
                        <List>
                            {sortedFiles[key].map((message) => (
                                <ListItem key={`file-${message.id}`} button onClick={() => downloadFiles(message.json)}>
                                    <ListItemIcon>
                                        <FileIcon sx={{ fontSize: 24 }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={getFilename(message.json)}
                                        secondary={fileInfo(message.json)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Paper>
            </ListWithStickyHeader>
        ));
    };

    const getImageList = (images) => {
        const sortedFiles = groupImagesByMonth(images);
        const calculateHeight = width !== 0 ? (matches ? (width - 4) / 3 : (width - 6) / 4) : 200;

        return Object.keys(sortedFiles).map((key) => (
            <ListWithStickyHeader key={`images-${key}`} header={<Typography sx={{ padding: '4px', fontSize: 16, fontWeight: 300 }}>{key}</Typography>}>
                <Paper elevation={0}>
                    <ImageList rowHeight={calculateHeight} gap={2} cols={matches ? 3 : 4}>
                        {sortedFiles[key].map((entry) => (
                            <ImageListItem key={entry.filename} cols={1} onClick={() => getImage(entry.message)}>
                                <img src={entry.image} alt={entry.filename} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Paper>
            </ListWithStickyHeader>
        ));
    };

    const getAudioList = (files) => {
        const sortedFiles = groupByMonth(files);

        return Object.keys(sortedFiles).map((key) => (
            <ListWithStickyHeader key={`vm-${key}`} header={<Typography sx={{ padding: '4px', fontSize: 16, fontWeight: 300 }}>{key}</Typography>}>
                <Paper elevation={0}>
                    <Box p={0}>
                        <List>
                            {sortedFiles[key].map((message) => (
                                <ListItem key={`vm-${message.id}`}>
                                    <ListItemIcon>
                                        <IconButton onClick={() => selectAudio(message.id)}>
                                            <PlayArrowRounded sx={{ fontSize: '3rem' }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText primary={formatTime(message.json.timestamp)} secondary={getContact(message)} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Paper>
            </ListWithStickyHeader>
        ));
    };

    const loadMore = useCallback(() => {
        DEBUG('Attempting to load more messages');
        setLoading(true);
        setMore(false);
        messageStorage.loadMoreFiles(selectedUri).then((loadedMessages) => {
            if (loadedMessages) {
                setMessages((prevMessages) => [...loadedMessages, ...prevMessages]);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [selectedUri]);

    useEffect(() => {
        if (!prevMessagesChanged) return;

        DEBUG('Entries changed, updating entries');
        let ignore = false;
        const cacheResults = [];
        const newVoiceMessages = [];
        const newFiles = [];

        messages.filter((message) => message.contentType === 'application/sylk-file-transfer' && message.json.filetype).reverse().forEach((message) => {
            const file = message.json;
            if (file.filetype.startsWith('image/')) {
                cacheResults.push(fileTransferUtils.generateThumbnail(account, message).then(([image, filename]) => ({
                    image,
                    filename,
                    message
                })).catch(() => Promise.reject()));
            } else if (file.filename.startsWith('sylk-audio-recording')) {
                newVoiceMessages.push(message);
            } else {
                newFiles.push(message);
            }
        });
    if (newVoiceMessages.length === 0 && newFiles.length === 0 && cacheResults.length === 0) {
        messageStorage.hasMoreFiles(selectedUri).then((value) => {
            setMore(value);
            if (!value) setNoFilesFound(true);
        });
        return;
    }

    if (JSON.stringify(newFiles) !== JSON.stringify(oldFiles)) {
        setFiles(newFiles);
    }
    if (JSON.stringify(newVoiceMessages) !== JSON.stringify(oldVoiceMessages)) {
        setVoiceMessages(newVoiceMessages);
    }

    Promise.allSettled(cacheResults).then((results) => {
        const newData = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
        if (!ignore) {
            if (JSON.stringify(newData) !== JSON.stringify(oldImages)) {
                setImages(newData);
            }
        }
    });

    return () => {
        ignore = true;
    };
}, [messages, removeMessage, account, selectedUri]); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    messageStorage.revertFiles(selectedUri);
}, [selectedUri]);

const busy = useRef(false);

useEffect(() => {
	const canLoadMore = () => {
		DEBUG('Checking if more can be loaded');
		messageStorage.hasMoreFiles(selectedUri).then((value) => setMore(value));
	};

	if (busy.current) return;

	const load = images || files || voiceMessages;
	if (load) busy.current = true;

	if (load && display !== true) {
		canLoadMore();
		setTimeout(() => setDisplay(true), 150);
	}

	if (load && loading) {
		canLoadMore();
		setLoading(false);
		if (more && inView) {
			loadMore();
		}
	}
}, [images, files, voiceMessages, inView, display, loading, more, loadMore]);

useEffect(() => {
	if (inView) {
		loadMore();
	}
}, [inView, loadMore]);

return (
	<div
	ref={panel}
	style={{
		margin: 0,
			alignSelf: 'center',
			overflow: 'hidden',
			maxWidth: '806px',
			flex: 1,
			width: '100%'
	}}
	>
	<ImagePreviewModal
	show={showModal}
	close={() => setShowModal(false)}
	image={image}
	contactCache={contactCache}
	message={message}
	openInNewTab={(...args) => fileTransferUtils.openInNewTab(account, ...args)}
	download={downloadFiles}
	removeMessage={removeMessage}
	/>
	<DragAndDrop title="Drop files to share them" handleDrop={uploadFiles} sx={{ marginTop: '100px', height: '100%', flexDirection: 'column', overflowX: 'hidden' }} useFlex>
	<UserIcon identity={getDisplayName(selectedUri)} active={false} large={true} />
	<Typography sx={{ display: 'block', textAlign: 'center', marginTop: '10px', marginBottom: '4px', fontFamily: 'inherit' }} variant="h3" noWrap>
	{getDisplayName(selectedUri).displayName || selectedUri}
	</Typography>
	<Typography sx={{ display: 'block', textAlign: 'center', marginTop: '4px', marginBottom: '20px', fontFamily: 'inherit' }} variant="h4" noWrap>
	{getDisplayName(selectedUri).displayName && <span>({selectedUri})</span>}
	</Typography>
	{display && (
		<>
		<Paper elevation={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
		<Tabs value={value} onChange={handleChange} centered>
		{images && <Tab label="Media" {...a11yProps(0)} />}
		{files && <Tab label="Files" {...a11yProps(1)} />}
		{voiceMessages && <Tab label="Voice" {...a11yProps(2)} />}
		</Tabs>
		</Paper>
		{images && (
			<TabPanel sx={{ flex: 1, overflow: 'auto' }} value={value} index={0}>
			{getImageList(images)}
			</TabPanel>
		)}
		{files && (
			<TabPanel sx={{ flex: 1, overflow: 'auto' }} value={value} index={images ? 1 : 0}>
			{getFileList(files)}
			</TabPanel>
		)}
		{voiceMessages && (
			<TabPanel sx={{ flex: 1, overflow: 'auto' }} value={value} index={images && files ? 2 : (files || images) ? 1 : 0}>
			{getAudioList(voiceMessages)}
			</TabPanel>
		)}
		</>
	)}
	{!display && (
		<Box p={0} sx={{ flex: 1 }}>
		{noFilesFound && (
			<Typography sx={{ display: 'block', textAlign: 'center', marginTop: '4px', marginBottom: '20px', fontFamily: 'inherit' }} variant="h5">
			No shared media, files, and voice messages.
			</Typography>
		)}
		</Box>
	)}
	</DragAndDrop>
	{more && (
		<div ref={ref}>
		<CircularProgress sx={{ color: '#888', margin: 'auto', display: 'block' }} />
		</div>
	)}
	</div>
);
};

InfoPanel.propTypes = {
	startMessages: PropTypes.array,
	contactCache: PropTypes.object,
	removeMessage: PropTypes.func.isRequired,
	account: PropTypes.object.isRequired,
	uploadFiles: PropTypes.func,
	downloadFiles: PropTypes.func,
	selectedUri: PropTypes.string,
	selectAudio: PropTypes.func
};

export default InfoPanel;
