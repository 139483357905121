import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Link,
} from '@mui/material';
import { styled } from '@mui/system';
import { Facebook, X, Instagram, LinkedIn } from '@mui/icons-material';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(6, 0),
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Left Column with Logo and Contact Info */}
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="column">
              {/* Replace this with your logo if needed */}
              <img src="/assets/img/logo.png" alt="Zerxis Logo" style={{ marginBottom: 16 }} />

              <Typography variant="body2" color="text.secondary" paragraph>
                Kenya: +254 7070 777 07<br />
                Uganda: +256 705 742629<br />
                Malawi: +265 99 924 7514<br />
                Zambia: +260 97 7786943<br />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Email: info@zerxis.com<br />
                The Trio Complex<br />
                Off exit, 7 Thika Rd.<br />
                Nairobi, Kenya
              </Typography>
            </Box>
          </Grid>

          {/* Middle Column with Links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Why Zerxis?
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="#WhatWeDo" color="inherit" underline="hover">How it works</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="#WhatWedo" color="inherit" underline="hover">Capabilities</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="#WhatWeDo" color="inherit" underline="hover">Integrations</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="/call-center-solution" color="inherit" underline="hover">Call Center Solution</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="#OurClients" color="inherit" underline="hover">Blog</Link>
            </Typography>
          </Grid>

          {/* Right Column with Company Info */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="/#WhoWeAre" color="inherit" underline="hover">About Us</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="https://callcenter.africa/helpdesk/" color="inherit" underline="hover">Help desk</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="/terms-of-service" color="inherit" underline="hover">Terms of Service</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="/privacy-policy" color="inherit" underline="hover">Privacy Policy</Link>
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <Link href="/data-deletion-request" color="inherit" underline="hover">Request Data Deletion</Link>
            </Typography>
          </Grid>

          {/* Footer Social Media */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={4}>
              <IconButton
                component="a"
                href="https://www.facebook.com/zerxisltd"
                target="_blank"
                rel="noopener"
                color="primary"
              >
                <Facebook />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.twitter.com/zerxisltd"
                target="_blank"
                rel="noopener"
                color="primary"
              >
                <X />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.instagram.com/zerxisltd"
                target="_blank"
                rel="noopener"
                color="primary"
              >
                <Instagram />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.linkedin.com/zerxisltd"
                target="_blank"
                rel="noopener"
                color="primary"
              >
                <LinkedIn />
              </IconButton>
            </Box>
          </Grid>

          {/* Copyright Section */}
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" align="center">
              Copyright © {currentYear} Zerxis Limited. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
