import React from "react";
import { Button, TextField, Container, Typography, Box } from "@mui/material";
import Footer from "../../global/Footer";
import ReportHeader from "../../../components/ReportHeader";

const NewWalkIn = () => {
  return (
    <Box>
      <Box sx={{ marginBottom: 32 }}>
        <ReportHeader title="New Walk-In" subtitle="" />
        <form noValidate autoComplete="off">
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </Box>
      <Footer />
    </Box>
  );
};

export default NewWalkIn;
