import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, Select, MenuItem, Fade, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { utils as sylkrtcutils } from 'sylkrtc';
import VolumeBar from './VolumeBar';
import { Button, InputBase } from '../MaterialUIAsBootstrap';
import debug from 'debug';

const DEBUG = debug('blinkrtc:SwitchDevicesModal');

const useStyles = makeStyles((theme) => ({
    bigger: {
        '&> h2': {
            fontSize: '20px'
        }
    },
    formControl: {
        margin: theme.spacing(1),
        fontSize: '14px',
        textAlign: 'left'
    },
    inputLabel: {
        fontSize: '14px',
        transform: 'translate(0, 1.5px) scale(1) !important'
    },
    focused: {
        color: '#337ab7 !important'
    },
    scale: {
        transform: 'scale(-1, 1) !important',
        width: '100%',
        borderRadius: '5px',
        display: 'block'
    },
    select: {
        minHeight: 0
    },
    icon: {
        fontSize: '24px'
    },
    videoOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgb(200,200,200)',
        zIndex: 1,
        borderRadius: '5px'
    },
    pending: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        zIndex: 2
    },
    failedText: {
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.87)'
    }
}));

const SwitchDevicesModal = (props) => {
    const classes = useStyles();
    const [devices, setDevices] = useState([]);
    const [stream, setStream] = useState(null);
    const [audioStream, setAudioStream] = useState(props.call.getLocalStreams()[0]);
    const [videoInput, setVideoInput] = useState('');
    const [audioInput, setAudioInput] = useState('');
    const [audioOutput, setAudioOutput] = useState('');
    const [videoError, setVideoError] = useState(false);
    const previousVideoInput = useRef();
    const previousAudioInput = useRef();
    const videoRef = useRef();
    const init = useRef(false);

    const currentStream = props.call.getLocalStreams()[0];

    useEffect(() => {
        if (props.show) {
            init.current = false;
            getDevices();
        }

        if (init.current && !props.show) {
            DEBUG('Closing stream:', stream);
            sylkrtcutils.closeMediaStream(stream);
            init.current = false;
            setStream(null);
            setDevices([]);
            setVideoInput('');
            setAudioInput('');
        }
    }, [props.show, stream]);

    useEffect(() => {
        if (init.current && (videoInput || audioInput)) {
           getMedia();
        }
    }, [videoInput, audioInput]);

    useEffect(() => {
        if (devices.length !== 0) {
            let currentLabel;
            if (currentStream.getVideoTracks().length !== 0) {
                currentLabel = currentStream.getVideoTracks()[0].label;
                let device = devices.find(device => device.label === currentLabel);
                if (device) {
                    setVideoInput(device.deviceId);
                    previousVideoInput.current = device.deviceId;
                }
            }

            currentLabel = currentStream.getAudioTracks()[0].label;
            let audioInputDevice = devices.find(device => device.label === currentLabel);
            if (audioInputDevice) {
                setAudioInput(audioInputDevice.deviceId);
                previousAudioInput.current = audioInputDevice.deviceId;
            }
            init.current = true;
        }
    }, [devices, currentStream]);

    const getDevices = () => {
        DEBUG('Getting available devices');
        navigator.mediaDevices.enumerateDevices()
            .then((deviceList) => {
                DEBUG('We got the devices:', deviceList);
                setDevices(deviceList);
            })
            .catch((error) => {
                DEBUG('Device enumeration failed:', error);
            });
    };

    const getMedia = () => {
        DEBUG('Getting media');
        let constraints = {
            audio: true,
            video: {
                width: { ideal: 1280 },
                height: { ideal: 720 }
            }
        };

        if (!stream) {
            constraints.audio = false;
            constraints.video = { deviceId: { exact: videoInput } };
        }

        if (videoInput !== previousVideoInput.current && videoInput) {
            previousVideoInput.current = videoInput;
            DEBUG('Video selection has changed, getting new track');
            constraints.video = { deviceId: { exact: videoInput } };
            if (stream) {
                stream.getVideoTracks().forEach(track => track.stop());
            }
        }

        if (audioInput !== previousAudioInput.current) {
            previousAudioInput.current = audioInput;
            DEBUG('Audio selection has changed, getting new track');
            if (videoInput) {
                constraints.video = false;
            }
            constraints.audio = { deviceId: { exact: audioInput } };
            if (stream) {
                stream.getAudioTracks().forEach(track => track.stop());
            }
        }

        navigator.mediaDevices.getUserMedia(constraints)
            .then((mediaStream) => {
                if (stream) {
                    if (mediaStream.getVideoTracks().length !== 0) {
                        stream.addTrack(mediaStream.getVideoTracks()[0]);
                    }
                    if (mediaStream.getAudioTracks().length !== 0) {
                        stream.addTrack(mediaStream.getAudioTracks()[0]);
                        setAudioStream(mediaStream);
                    }
                } else {
                    sylkrtcutils.attachMediaStream(mediaStream, videoRef.current, { muted: true, disableContextMenu: true, mirror: true });
                    setStream(mediaStream);
                }
                setVideoError(false);
            })
            .catch((error) => {
                DEBUG('Device had a problem:', error);
                if (!stream || !stream.getVideoTracks()[0]) {
                    setVideoError(true);
                }
            });
    };

    const selectVideoInput = (event) => setVideoInput(event.target.value);
    const selectAudioInput = (event) => setAudioInput(event.target.value);
    const selectOutput = (event) => setAudioOutput(event.target.value);

    const applyConstraints = () => {
        if (videoInput && !videoError && stream && stream.getVideoTracks()[0]) {
            DEBUG('Switching video input');
            props.call.replaceTrack(
                currentStream.getVideoTracks()[0],
                stream.getVideoTracks()[0].clone(),
                false
            );
            let device = devices.find(device => device.deviceId === videoInput);
            if (device) props.setDevice(device);
        }
        if (audioInput && stream && stream.getAudioTracks()[0]) {
            DEBUG('Switching audio input');
            props.call.replaceTrack(
                currentStream.getAudioTracks()[0],
                stream.getAudioTracks()[0].clone(),
                false
            );
            let device = devices.find(device => device.deviceId === audioInput);
            if (device) props.setDevice(device);
            sylkrtcutils.closeMediaStream(audioStream);
        }
        props.close();
    };

    return (
        <Dialog
            open={props.show}
            onClose={props.close}
            maxWidth="sm"
            fullWidth
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" className={classes.bigger}>Switch Devices</DialogTitle>
            <DialogContent dividers>
                {devices.length > 0 ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div style={{ position: 'relative' }}>
                                {videoError && (
                                    <div className={classes.videoOverlay}>
                                        <div className={clsx(classes.pending, classes.failedText)}>Camera unavailable</div>
                                    </div>
                                )}
                                <video className={classes.scale} ref={videoRef} autoPlay muted />
                                <br />
                                {audioStream && <VolumeBar localMedia={audioStream} style={{ borderRadius: '5px' }} />}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel className={classes.inputLabel} classes={{ focused: classes.focused }} id="camera-label">Camera</InputLabel>
                                <Select
                                    labelId="camera-label"
                                    value={videoInput}
                                    onChange={selectVideoInput}
                                    input={<InputBase />}
                                    classes={{ selectMenu: classes.select, icon: classes.icon }}
                                >
                                    {devices.filter(device => device.kind === 'videoinput').map(device => (
                                        <MenuItem value={device.deviceId} key={device.deviceId}>{device.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel className={classes.inputLabel} classes={{ focused: classes.focused }} id="mic-label">Microphone</InputLabel>
                            <Select
                                labelId="mic-label"
                                value={audioInput}
                                onChange={selectAudioInput}
                                input={<InputBase />}
                                classes={{ selectMenu: classes.select, icon: classes.icon }}
                            >
                                {devices.filter(device => device.kind === 'audioinput').map(device => (
                                    <MenuItem value={device.deviceId} key={device.deviceId}>{device.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {props.showOutput && devices.filter(device => device.kind === 'audiooutput').length > 0 && (
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel className={classes.inputLabel} classes={{ focused: classes.focused }} id="output-label">Output</InputLabel>
                                <Select
                                    labelId="output-label"
                                    value={audioOutput}
                                    onChange={selectOutput}
                                    input={<InputBase />}
                                    classes={{ selectMenu: classes.select, icon: classes.icon }}
                                >
                                    {devices.filter(device => device.kind === 'audiooutput').map(device => (
                                        <MenuItem value={device.deviceId} key={device.deviceId}>{device.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Fade
                    in={devices.length === 0}
                    style={{
                        transitionDelay: devices.length === 0 ? '100ms' : '0ms',
                        color: '#666',
                        width: '100px',
                        height: '100px'
                    }}
                >
                    <CircularProgress />
                </Fade>
            )}
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={applyConstraints} title="apply">Ok</Button>
            <Button onClick={props.close} variant="text" title="cancel">Cancel</Button>
        </DialogActions>
    </Dialog>
);
};

SwitchDevicesModal.propTypes = {
show: PropTypes.bool.isRequired,
close: PropTypes.func.isRequired,
call: PropTypes.object.isRequired,
setDevice: PropTypes.func.isRequired,
showOutput: PropTypes.bool,
disableCameraSelection: PropTypes.bool
};

export default SwitchDevicesModal;
