import React, { useState } from "react";
import { getIdToken, onAuthStateChanged, getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase_setup/firebase";
import { useAuthState, useSignInWithApple } from "react-firebase-hooks/auth";
import logo from "../../../assets/images/logo.png";
import Authentication from "../auth/Authentication";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

const LoginForm = () => {
  const { login, loginError, googleSignIn, loading, error, facebookSignIn, appleSignIn } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // For example, you can call your 'login' function here
    login(username, password);
  };


  const buttonStyle = {
    color: "white",
    padding: "5px",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    marginBottom: "5px",
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: "320px",
        margin: "auto",
        // borderRadius: "50px",
        padding: "20px",
        background: "#e0e0e0",
        boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
      }}
    >
      <h1 style={{ fontSize: "24px", marginBottom: "10px", color: "#3498db" }}>
        Login
      </h1>
      <form onSubmit={handleFormSubmit} style={{ marginBottom: "5px" }}>
        <div style={{ marginBottom: "5px" }}>
          <label
            htmlFor="username"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            className="form-control"
            autoComplete="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label
            htmlFor="password"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            required
            style={{
              width: "100%",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
        </div>

        <button
          type="submit"
          style={{
            width: "100%",
            backgroundColor: "#3498db",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
          // Removed onClick from here as it's redundant with onSubmit in the form
        >
          Log in
        </button>
      {loginError && <p>{loginError}</p>}
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <p style={{ color: "#666", marginBottom: "10px" }}>- OR -</p>

        <button
          onClick={facebookSignIn}
          style={{ ...buttonStyle, backgroundColor: "#3044BC" }}
        >
          <FacebookIcon style={{ marginRight: "8px" }} /> Sign in with Facebook
        </button>
        <button style={{ ...buttonStyle, backgroundColor: "#D70040" }}>
          {loading ? 'Signing in...' : ''}
          <GoogleIcon 
          onClick={googleSignIn} 
          disabled={loading}
          style={{ marginRight: "8px" }} />{" "}
          Sign in with Google
        </button>
        <button style={{ ...buttonStyle, backgroundColor: "#000000" }}>
          {loading ? 'Signing in...' : ''}
          <AppleIcon 
          onClick={appleSignIn} // Changed from googleSignIn to appleSignIn
          style={{ marginRight: "8px" }} />{" "}
          Sign in with Apple
        </button>
        <button
          onClick={googleSignIn}
          style={{ ...buttonStyle, backgroundColor: "#1DA1F2" }}
        >
          <TwitterIcon style={{ marginRight: "8px" }} /> Sign in with Twitter
        </button>

        <button
          onClick={googleSignIn}
          style={{ ...buttonStyle, backgroundColor: "#0078D4" }}
        >
          <MicrosoftIcon style={{ marginRight: "8px" }} /> Sign in with
          Microsoft
        </button>
      </div>
      <p style={{ color: "#666", marginBottom: "10px" }}>
        Can't access your account? <a href="/">Reset your password.</a>
      </p>
    </div>
  );
};

export default LoginForm;
