import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CircularProgress, IconButton, Grid } from '@mui/material';
import { PlayArrowRounded as PlayIcon, StopRounded as StopIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import WaveSurfer from 'wavesurfer.js';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#337ab7',
        borderColor: '#2e6da4',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#286090',
            borderColor: '#204d74',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: '#122b40',
            backgroundColor: '#204d74',
            outlineOffset: '-2px',
            boxShadow: 'inset 0px 3px 5px 0px rgba(0,0,0,.125)'
        }
    }
}));

const useWavesurfer = (containerRef, options) => {
    const [wavesurfer, setWavesurfer] = useState(null);

    useEffect(() => {
        if (!containerRef.current) return;

        const ws = WaveSurfer.create({
            ...options,
            container: containerRef.current
        });

        setWavesurfer(ws);

        return () => {
            ws.destroy();
        };
    }, [options, containerRef]);

    return wavesurfer;
};

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = Math.round(seconds) % 60;
    const paddedSeconds = `0${secondsRemainder}`.slice(-2);
    return `${minutes}:${paddedSeconds}`;
};

const WaveSurferPlayer = (props) => {
    const classes = useStyles();
    const containerRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [ready, setReady] = useState(false);
    const wavesurfer = useWavesurfer(containerRef, props);

    const onPlayClick = useCallback(() => {
        if (wavesurfer) {
            wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
        }
    }, [wavesurfer]);

    useEffect(() => {
        if (!wavesurfer) return;

        setCurrentTime(0);
        setIsPlaying(false);
        let totalDuration = 0;

        const subscriptions = [
            wavesurfer.on('play', () => setIsPlaying(true)),
            wavesurfer.on('ready', () => setReady(true)),
            wavesurfer.on('pause', () => setIsPlaying(false)),
            wavesurfer.on('decode', (duration) => { totalDuration = duration; setCurrentTime(duration); }),
            wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(totalDuration - currentTime))
        ];

        return () => {
            subscriptions.forEach((unsub) => unsub());
        };
    }, [wavesurfer]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '70px' }}>
            <Grid container spacing={2} style={ready ? { animation: 'fadeIn ease .6s' } : { opacity: 0, display: 'none' }}>
                <Grid item>
                    <IconButton onClick={onPlayClick} className={classes.root}>
                        {isPlaying ? (
                            <StopIcon style={{ fontSize: '3rem' }} />
                        ) : (
                            <PlayIcon style={{ fontSize: '3rem' }} />
                        )}
                    </IconButton>
                </Grid>
                <Grid item>
                    <Grid container spacing={1} direction="column">
                        <Grid item>
                            <div ref={containerRef} style={{ whiteSpace: 'normal', width: '150px' }} />
                        </Grid>
                        <Grid item>
                            {formatTime(currentTime)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!ready && <CircularProgress style={{ color: '#999' }} />}
        </div>
    );
};

export default WaveSurferPlayer;
