'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const HistoryTileBox = (props) => {
    return (
        <div className="history-tile-box">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                {props.children}
            </Grid>
        </div>
    );
};

HistoryTileBox.propTypes = {
    children: PropTypes.node
};

export default HistoryTileBox;
