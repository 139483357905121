'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Badge } from '@mui/material';

const useStyles = makeStyles({
    badge: {
        width: '20px',
        height: '20px',
        fontWeight: 'bold',
        fontSize: '1rem',
        backgroundColor: '#337ab7'
    },
    badgeDrawer: {
        width: '20px',
        height: '20px',
        fontWeight: 'bold',
        fontSize: '1rem',
        backgroundColor: '#337ab7',
        position: 'unset',
        marginLeft: '-10px',
        transform: 'none'
    },
    rootThumb: {
        display: 'block',
        bottom: '25px',
        position: 'absolute',
        zIndex: 3
    }
});

const HandIcon = (props) => {
    const classes = useStyles();
    let badgeClass = props.drawer ? classes.badgeDrawer : classes.badge;
    let rootClass = props.thumb ? classes.rootThumb : undefined;

    if (props.raisedHand !== -1) {
        const button = props.disableHandToggle ? (
            <span style={{ padding: '0 13px' }}>
                <i className="fa fa-hand-o-up" />
            </span>
        ) : (
            <button
                key="handButton"
                type="button"
                title="Accept speaker request"
                className="btn btn-link btn-hand"
                onClick={props.handleHandSelected}
            >
                <i className="fa fa-hand-o-up" />
            </button>
        );

        return (
            <Badge
                badgeContent={props.raisedHand + 1}
                color="primary"
                classes={{ badge: badgeClass, root: rootClass }}
            >
                {button}
            </Badge>
        );
    }

    return null;
};

HandIcon.propTypes = {
    raisedHand: PropTypes.number.isRequired,
    handleHandSelected: PropTypes.func.isRequired,
    disableHandToggle: PropTypes.bool,
    drawer: PropTypes.bool,
    thumb: PropTypes.bool
};

export default HandIcon;
