import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useInView } from 'react-intersection-observer';
import { resolveMime } from 'friendly-mimes';

import { Box } from '@mui/material';  // Use Box for layout
import { useTheme } from '@mui/material/styles';  // Use theme hook
import { makeStyles } from '@mui/styles';  // Use makeStyles for custom styles
import LockIcon from '@mui/icons-material/Lock';  // Import MUI v5 icon
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';  // Import MUI v5 icon
import Avatar from '@mui/material/Avatar';  // Import Avatar component

import { Paper, Grid, Typography, IconButton, MenuItem, Divider } from '@mui/material';
import { Lock, Done, DoneAll, ErrorOutline, ArrowDropDown, ArrowRight, DescriptionOutlined as FileIcon } from '@mui/icons-material';

import CustomContextMenu from '../CustomContextMenu';
import UserIcon from '../UserIcon';
import WaveSurferPlayer from '../WaveSurferPlayer';
import fileTransferUtils from '../../fileTransferUtils';

function isElectron() {
  return typeof window.process !== 'undefined' && window.process.versions.electron !== '';
}

const styleSheet = makeStyles((theme) => ({
    chipSmall: {
        height: 18,
        fontSize: 11
    },
    iconSmall: {
        width: 12,
        height: 12
    },
    doneIcon: {
        fontSize: 15,
        verticalAlign: 'middle',
        color: 'green'
    },
    errorOutlineIcon: {
        fontSize: 17,
        verticalAlign: 'middle',
        color: '#a94442'
    },
    warningIcon: {
        fontSize: 17,
        verticalAlign: 'middle',
        color: '#a94442',
        marginLeft: '4px'
    },
    item: {
        fontSize: '14px',
        fontFamily: 'inherit',
        color: '#333',
        minHeight: 0
    },
    itemRed: {
        fontSize: '14px',
        fontFamily: 'inherit',
        color: 'rgb(169, 68, 66)',
        minHeight: 0
    },
    lockIcon: {
        fontSize: 15,
        verticalAlign: 'middle',
        color: '#ccc'
    },
    fixFont: {
        fontFamily: 'inherit'
    }
}));

const ReactBootstrap = require('react-bootstrap');
const Media = ReactBootstrap.Media;

const FileTransferMessage = ({
  message,
  scroll,
  cont,
  displayed,
  focus,
  contactCache,
  removeMessage,
  imdnStates,
  enableMenu,
  account,
  showModal,
  downloadFiles,
}) => {
  const classes = styleSheet();
  const [state, setState] = useState(message.state);
  const [parsedContent, setParsedContent] = useState();
  const [header, setHeader] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hidden, setHidden] = useState(false);
  const messageRef = useRef(null);
  const { ref, inView } = useInView({ threshold: 0 });

  const sender = message.sender.displayName || message.sender.uri;
  const time = DateTime.fromJSDate(message.timestamp).toFormat('HH:mm');

  const isDisplayed = useCallback(() => {
    if (displayed) {
      displayed();
    }
  }, [displayed]);

  useEffect(() => {
    if (parsedContent !== undefined) {
      scroll();
    }
  }, [parsedContent, scroll]);

  useEffect(() => {
    let file = message.json;
    if (file.filetype && !file.filetype.startsWith('image/')) {
      if (hidden) {
        setHeader(
          <Typography sx={{ fontSize: 12, alignSelf: 'center' }} variant="body2">
            {file.filename.replace('.asc', '').replace(/_/g, ' ')}
          </Typography>
        );
      } else {
        let filetype = file.filetype;
        try {
          filetype = resolveMime(file.filetype).name;
        } catch (error) {
          // no op
        }
        if (file.filename.startsWith('sylk-audio-recording')) {
          filetype = 'Voice Message';
        }
        setHeader(
          <Typography sx={{ fontSize: 12, alignSelf: 'center' }} variant="body2" color="textSecondary">
            {filetype}
          </Typography>
        );
      }
    }
  }, [hidden, message]);

  useEffect(() => {
    const fileSize = (size) => {
      let i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const generateFileBlock = (error) => {
      let filetype = 'Unknown';
      try {
        filetype = resolveMime(message.json.filetype).name;
      } catch (error) {
        filetype = message.json.filetype;
      }

      if (error) {
        setHeader(
          <Typography sx={{ fontSize: 12, alignSelf: 'center' }} variant="body2" color="textSecondary">
            {filetype}
          </Typography>
        );
      }

      setParsedContent(
        <Paper variant="outlined" sx={{ cursor: 'pointer' }} onClick={() => fileTransferUtils.download(account, message.json)}>
          <Grid container spacing={2}>
            <Grid item>
              <FileIcon sx={{ fontSize: 45 }} />
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Typography sx={{ fontSize: 16, fontWeight: 300 }} variant="subtitle1">
                  {message.json.filename.replace('.asc', '').replace(/_/g, ' ')}
                  {error && <ErrorOutline sx={{ fontSize: 17, color: '#a94442' }} />}
                </Typography>
                <Typography sx={{ fontSize: 12 }} variant="body2" color="textSecondary">
                  {fileSize(message.json.filesize)} {filetype}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      );
    };

    let ignore = false;
    if (message.jsonError) {
      setParsedContent(
        <Typography sx={{ fontSize: 14, color: 'rgb(169, 68, 66)' }}>Couldn't parse file transfer message</Typography>
      );
      return;
    }

    if (message.contentType === 'application/sylk-file-transfer') {
      if (message.json.filetype.startsWith('image/')) {
        fileTransferUtils.generateThumbnail(account, message).then(([image, filename, w, h]) => {
          if (!ignore) {
            setHeader(
              <Typography sx={{ fontSize: 12, alignSelf: 'center' }} variant="body2" color="textSecondary">
                {filename.replace(/_/g, ' ')}
              </Typography>
            );
            setParsedContent(
              <Paper sx={{ display: 'inline-block', borderRadius: 7, overflow: 'hidden', cursor: 'zoom-in' }}>
                <img
                  style={{ ...w && { width: w }, ...h && { height: h } }}
                  src={image}
                  onClick={showModal}
                  alt={filename}
                />
              </Paper>
            );
          }
        }).catch(error => {
          if (!ignore) {
            generateFileBlock(error);
          }
        });
      } else if (message.json.filename.startsWith('sylk-audio-recording')) {
        fileTransferUtils.getAndReadFile(account, message).then(([data]) => {
          if (!ignore) {
            setParsedContent(
              <WaveSurferPlayer
                height={25}
                waveColor="rgb(150,150,150)"
                progressColor="rgb(51, 122, 183)"
                barWidth={4}
                barAlign="bottom"
                normalize={true}
                url={data}
              />
            );
          }
        }).catch(error => {
          if (!ignore) {
            generateFileBlock(error);
          }
        });
      } else {
        generateFileBlock();
      }
    }

    return () => {
      ignore = true;
    };
  }, [message, account, showModal]);

  useEffect(() => {
    if (messageRef.current && focus) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [focus]);

  useEffect(() => {
    if (inView) {
      isDisplayed();
    }
  }, [inView, isDisplayed]);

  const handleContextMenu = (e) => {
    if (!enableMenu) return;

    e.preventDefault();
    const { clientX, clientY } = e;
    const virtualElement = {
      getBoundingClientRect: () => ({ top: clientY, left: clientX, bottom: clientY, right: clientX }),
    };
    setAnchorEl(virtualElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyLink = () => {
    const { url } = JSON.parse(message.content);
    navigator.clipboard.writeText(url);
  };

  const handleDownload = () => {
    downloadFiles(message.json);
  };

  const generateMenu = () => {
    const fileData = message.json;
    if (!fileData.filetype) return null;

    return (
      <CustomContextMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleDownload}>Download File</MenuItem>
        {!message.isSecure && <MenuItem onClick={copyLink}>Copy link to file</MenuItem>}
        <Divider />
        <MenuItem sx={{ color: 'red' }} onClick={removeMessage}>Delete file</MenuItem>
      </CustomContextMenu>
    );
  };

  const statusIcon = () => {
    switch (state) {
      case 'accepted':
        return <Done sx={{ color: '#888', fontSize: 15 }} />;
      case 'delivered':
        return <Done sx={{ fontSize: 15, color: 'green' }} />;
      case 'displayed':
        return <DoneAll sx={{ fontSize: 15, color: 'green' }} />;
      case 'failed':
      case 'error':
        return <ErrorOutline sx={{ fontSize: 17, color: '#a94442' }} />;
      default:
			return null;
	}
  };

	const getDisplayName = (uri) => {
		return contactCache?.get(uri) || uri;
	};

    const theme = clsx({
        'text-left': true,
        'pending': state === 'pending',
        'text-danger': state === 'failed' || state === 'error' || message.jsonError,
        'continued': cont && message.type !== 'status',
        'status': message.type === 'status'
    });

    if (cont || message.type === 'status') {
        return (
            <div ref={ref}>
                <Media className={theme} onContextMenu={handleContextMenu}>
                    {enableMenu && message.type !== 'status' &&
                        generateMenu()
                    }

                    <div ref={messageRef} />
                    {message.type !== 'status' &&
                        <Media.Left className="timestamp-continued"><span>{time}</span></Media.Left>
                    }
                    <Media.Body className="vertical-center">
                        {state !== 'error' &&
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                {header}
                                <IconButton
                                    size="small"
                                    onClick={() => setHidden(!hidden)}
                                >
                                    {hidden ? <ArrowRight /> : <ArrowDropDown />}
                                </IconButton>
                            </div>
                        }
                        {!hidden &&
                            parsedContent
                        }
                    </Media.Body>
                    <Media.Right style={{ minWidth: 55 }}>
                        <span className="pull-right" style={{ paddingRight: '15px', whiteSpace: 'nowrap' }}>
                            {message.isSecure && <LockIcon className={classes.lockIcon} />}
                            {statusIcon()}
                            {message.type === 'status' &&
                                <pre>{time}</pre>
                            }
                        </span>
                    </Media.Right>
                </Media>
            </div>
        );
    }

    return (
        <div ref={ref}>
            <Media className={theme} onContextMenu={handleContextMenu}>
                {enableMenu &&
                    generateMenu()
                }
                <div ref={messageRef} />
                <Media.Left>
                    <UserIcon identity={getDisplayName(message.sender.uri)} />
                </Media.Left>
                <Media.Body className="vertical-center">
                    <Media.Heading>
                        {getDisplayName(message.sender.uri).displayName || sender}&nbsp;
                        <span>{time}</span>
                        <span className="pull-right" style={{ paddingRight: '15px' }}>
                            {message.isSecure && <LockIcon className={classes.lockIcon} />}
                            {statusIcon()}
                            {message.jsonError &&
                                <ErrorOutlineIcon className={classes.errorOutlineIcon} titleAccess="Parse Error" />
                            }
                        </span>
                    </Media.Heading>

                    {!message.jsonError &&
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                            {header}
                            <IconButton
                                size="small"
                                onClick={() => setHidden(!hidden)}
                            >
                                {hidden ? <ArrowRight /> : <ArrowDropDown />}
                            </IconButton>
                        </div>
                    }
                    {!hidden &&
                        <div style={{ maxWidth: 'calc(100% - 55px)' }}>
                            {parsedContent}
                        </div>
                    }
                </Media.Body>
            </Media>
        </div>
    );
};

FileTransferMessage.propTypes = {
	message: PropTypes.object.isRequired,
	scroll: PropTypes.func.isRequired,
	removeMessage: PropTypes.func,
	displayed: PropTypes.func,
	cont: PropTypes.bool,
	focus: PropTypes.bool,
	contactCache: PropTypes.object,
	imdnStates: PropTypes.bool,
	enableMenu: PropTypes.bool,
	account: PropTypes.object.isRequired,
	showModal: PropTypes.func,
	downloadFiles: PropTypes.func,
};

export default FileTransferMessage;
