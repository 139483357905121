import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, ImageList, ImageListItem, ImageListItemBar, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from '@mui/material';

const useStyles = makeStyles({
    gridList: {
        height: 300,
        overflow: 'auto'
    },
    fullWidth: {
        maxWidth: 'none'
    },
    border: {
        border: '2px solid #3f51b5',
        padding: '2px !important'
    },
    imageListItem: {
        cursor: 'pointer'
    }
});

const ScreenSharingModal = ({ show, close, getLocalScreen }) => {
    const classes = useStyles();
    const [value, setValue] = useState('screens');
    const [focus, setFocus] = useState(null);
    const [sources, setSources] = useState([]);

    useEffect(() => {
        let interval = null;
        if (show && sources.length !== 0) {
            interval = setTimeout(() => {
                getSources();
            }, 5000);
        }
        return () => {
            if (interval !== null) {
                clearTimeout(interval);
            }
        };
    }, [show, sources.length]);

    const getSources = () => {
        const desktopCapturer = window.require('electron').desktopCapturer;
        desktopCapturer.getSources({ types: ['window', 'screen'], thumbnailSize: { width: 180, height: 180 } })
            .then((newSources) => {
                if (sources.length === 0) {
                    const firstScreen = newSources.find(source => source.display_id !== '' || source.id.startsWith('screen'));
                    setFocus(firstScreen?.id || null);
                }
                setSources(newSources);
            });
    };

    const handleClose = () => {
        setSources([]);
        setFocus(null);
        setValue('screens');
        close();
    };

    const shareScreen = () => {
        getLocalScreen(focus);
    };

    const screens = sources.filter(source => source.display_id !== '' || source.id.startsWith('screen'));
    const windows = sources.filter(source => source.display_id === '' && !source.id.startsWith('screen'));

    useEffect(() => {
        if (show && sources.length === 0) {
            getSources();
        }
    }, [show, sources.length]);

    return (
        <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Share your screen</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    Sylk wants to share the contents of your screen. Choose what you'd like to share.
                </Typography>
                <Tabs
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Your Entire Screen" value="screens" />
                    <Tab label="Application Window" value="windows" />
                </Tabs>
                <br />
                {sources.length === 0 && (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                        <Typography variant="body2">Getting Your Screens and Windows...</Typography>
                    </div>
                )}
                {value === 'screens' && sources.length !== 0 && (
                    <ImageList cellHeight={180} cols={2} className={classes.gridList}>
                        {screens.map(screen => (
                            <ImageListItem
                                key={screen.id}
                                onClick={() => setFocus(screen.id)}
                                className={`${classes.imageListItem} ${screen.id === focus && classes.border}`}
                            >
                                <img src={screen.thumbnail.toDataURL()} alt={screen.name} />
                                <ImageListItemBar title={screen.name} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                )}
                {value === 'windows' && sources.length !== 0 && (
                    <ImageList cellHeight={'auto'} cols={3} className={classes.gridList}>
                        {windows.map(screen => (
                            <ImageListItem
                                key={screen.id}
                                onClick={() => setFocus(screen.id)}
                                className={`${classes.imageListItem} ${screen.id === focus && classes.border}`}
                            >
                                <img src={screen.thumbnail.toDataURL()} alt={screen.name} />
                                <ImageListItemBar title={screen.name} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={shareScreen} color="primary" variant="contained" disabled={focus === null}>Share</Button>
            </DialogActions>
        </Dialog>
    );
};

ScreenSharingModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    getLocalScreen: PropTypes.func
};

export default ScreenSharingModal;
