import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ProjectContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch the contacts data from the Django API
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/management/api/project-contacts/`)
      .then(response => setContacts(response.data))
      .catch(error => console.error('Error fetching contacts:', error));
  }, []);

  // Filter contacts based on the search term
  const filteredContacts = contacts.filter(contact =>
    contact.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.department.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h2>Project Contacts</h2>

      {/* Search Input */}
      <input
        type="text"
        placeholder="Search contacts..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />

      {/* Contacts Table */}
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Email</th>
            <th>Office Location</th>
            <th>Cell Phone</th>
          </tr>
        </thead>
        <tbody>
          {filteredContacts.map(contact => (
            <tr key={contact.id}>
              <td>{contact.first_name}</td>
              <td>{contact.last_name}</td>
              <td>{contact.designation}</td>
              <td>{contact.department}</td>
              <td>{contact.email}</td>
              <td>{contact.office_location}</td>
              <td>{contact.cell_phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectContacts;
