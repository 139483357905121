import React, { useState } from "react";
import { Box } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReportHeader from "../../../components/ReportHeader";
import Footer from "../../global/Footer";

const TodaysCases = () => {
   
    function createData(agent_no, agent, start_time, end_time, duration, duration_wait, queue, type, phone, transfer, status, recording) {
      return { agent_no, agent, start_time, end_time, duration, duration_wait, queue, type, phone, transfer, status, recording };
    }
    
    const rows = [
      createData(3994, 'John Doe', '10:00 AM', '11:30 AM', 1.5, 0.5, 'Support', 'Inbound', '123-456-7890', 'No', 'Connected', 'Yes'),
      createData(3995, 'Jane Smith', '1:00 PM', '2:30 PM', 2.5, 1.0, 'Sales', 'Outbound', '987-654-3210', 'Yes', 'On Hold', 'No'),
      createData(3996, 'Bob Johnson', '3:00 PM', '5:00 PM', 2.0, 0.8, 'Service', 'Inbound', '555-123-4567', 'No', 'Disconnected', 'Yes'),
      createData(3996, 'Bob Johnson', '3:00 PM', '5:00 PM', 2.0, 0.8, 'Service', 'Inbound', '555-123-4567', 'No', 'Disconnected', 'Yes'),
      createData(3996, 'Bob Johnson', '3:00 PM', '5:00 PM', 2.0, 0.8, 'Service', 'Inbound', '555-123-4567', 'No', 'Disconnected', 'Yes'),
    ];
    
    return (
      <Box>
        <ReportHeader title="Today's Cases" subtitle="Total Cases Today" />
        <TableContainer  sx={{ maxWidth: 750 }}>
          <Table aria-label="caption table" style={{ whiteSpace: 'nowrap' }}>
           <caption>Total cases</caption>
            <TableHead>
            <TableRow style={{backgroundColor:" #3498db", color: "#fff"}}>
                <TableCell>No.</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Duration Wait</TableCell>
                <TableCell>Queue</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Transfer</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Recording</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.agent_no}
                  </TableCell>
                  <TableCell>{row.agent}</TableCell>
                  <TableCell>{row.start_time}</TableCell>
                  <TableCell>{row.end_time}</TableCell>
                  <TableCell>{row.duration}</TableCell>
                  <TableCell>{row.duration_wait}</TableCell>
                  <TableCell>{row.queue}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.transfer}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.recording}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Footer/>
      </Box>
    );
  }
export default TodaysCases;
