import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hark from 'hark';
import { LinearProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    colorSecondary: {
        backgroundColor: green[100]
    },
    barColorSecondary: {
        backgroundColor: green[500]
    },
    root: {
        height: '10px',
        opacity: '0.7'
    },
    bar1Determinate: {
        transition: 'transform 0.2s linear'
    }
});

const VolumeBar = ({ localMedia }) => {
    const classes = useStyles();
    const [volume, setVolume] = useState(0);
    const [speechEvents, setSpeechEvents] = useState(null);

    useEffect(() => {
        if (localMedia) {
            const options = { interval: 225, play: false };
            const harkEvents = hark(localMedia, options);

            harkEvents.on('volume_change', (vol) => {
                setVolume(2 * (vol + 75));
            });

            setSpeechEvents(harkEvents);

            return () => {
                if (harkEvents) {
                    harkEvents.stop();
                }
            };
        }
    }, [localMedia]);

    useEffect(() => {
        if (speechEvents) {
            speechEvents.stop();
        }

        const options = { interval: 225, play: false };
        const harkEvents = hark(localMedia, options);

        harkEvents.on('volume_change', (vol) => {
            setVolume(2 * (vol + 75));
        });

        setSpeechEvents(harkEvents);

        return () => {
            if (harkEvents) {
                harkEvents.stop();
            }
        };
    }, [localMedia]);

    const color = volume > 20 ? 'secondary' : 'primary';

    return (
        <LinearProgress
            classes={classes}
            variant="determinate"
            color={color}
            value={volume}
        />
    );
};

VolumeBar.propTypes = {
    localMedia: PropTypes.object.isRequired
};

export default VolumeBar;
