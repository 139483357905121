import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { Box } from '@mui/material';
import { utils as sylkrtcutils } from 'sylkrtc';

const PreMedia = ({ localMedia, hide }) => {
    const [show, setShow] = useState(false);
    const [init, setInit] = useState(false);
    const localVideo = useRef(null);

    useEffect(() => {
        const localVideoElementPlaying = () => {
            setShow(true);
        };

        if (localVideo.current && localMedia) {
            if (localMedia.getVideoTracks().length > 0) {
                localVideo.current.addEventListener('playing', localVideoElementPlaying);
                sylkrtcutils.attachMediaStream(localMedia, localVideo.current, {
                    disableContextMenu: true,
                    muted: true
                });
            }
        }

        return () => {
            if (localVideo.current) {
                localVideo.current.removeEventListener('playing', localVideoElementPlaying);
            }
        };
    }, [localMedia]);

    useEffect(() => {
        if (localVideo.current && hide) {
            localVideo.current.removeEventListener('playing', () => setShow(false));
        }
    }, [hide]);

    const enter = () => {
        if (!init) {
            setInit(true);
        }
    };

    const videoClasses = clsx({
        'video-container': true,
        'hide': !init
    });

    return (
        <div>
            {localMedia && (
                <CSSTransition
                    in={show}
                    timeout={{ enter: 300, exit: 300 }}
                    classNames="premedia-display"
                    onEnter={enter}
                >
                    <Box className={videoClasses} sx={{ position: 'relative' }}>
                        <video
                            className="large mirror"
                            id="localVideo"
                            ref={localVideo}
                            autoPlay
                            muted
                            style={{ zIndex: -1, position: 'absolute', width: '100%', height: '100%' }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(transparent, rgba(0,0,0,.9))',
                            }}
                        />
                    </Box>
                </CSSTransition>
            )}
        </div>
    );
};

PreMedia.propTypes = {
    localMedia: PropTypes.object,
    hide: PropTypes.bool
};

export default PreMedia;
