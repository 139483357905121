'use strict';

const adjectives = [
    'Black',        'White',        'Gray',         'Brown',        'Red',
    'Pink',         'Crimson',      'Carnelian',    'Orange',       'Yellow',
    'Ivory',        'Cream',        'Green',        'Viridian',     'Aquamarine',
    'Cyan',         'Blue',         'Cerulean',     'Azure',        'Indigo',
    'Navy',         'Violet',       'Purple',       'Lavender',     'Magenta',
    'Rainbow',      'Iridescent',   'Spectrum',     'Prism',        'Bold',
    'Vivid',        'Pale',         'Clear',        'Glass',        'Translucent',
    'Misty',        'Dark',         'Light',        'Gold',         'Silver',
    'Copper',       'Bronze',       'Steel',        'Iron',         'Brass',
    'Mercury',      'Zinc',         'Chrome',       'Platinum',     'Titanium',
    'Nickel',       'Lead',         'Pewter',       'Rust',         'Metal',
    'Stone',        'Quartz',       'Granite',      'Marble',       'Alabaster',
    'Agate',        'Jasper',       'Pebble',       'Pyrite',       'Crystal',
    'Geode',        'Obsidian',     'Mica',         'Flint',        'Sand',
    'Gravel',       'Boulder',      'Basalt',       'Ruby',         'Beryl',
    'Scarlet',      'Citrine',      'Sulpher',      'Topaz',        'Amber',
    'Emerald',      'Malachite',    'Jade',         'Abalone',      'Lapis',
    'Sapphire',     'Diamond',      'Peridot',      'Gem',          'Jewel',
    'Bevel',        'Coral',        'Jet',          'Ebony',        'Wood',
    'Tree',         'Cherry',       'Maple',        'Cedar',        'Branch',
    'Bramble',      'Rowan',        'Ash',          'Fir',          'Pine',
    'Cactus',       'Alder',        'Grove',        'Forest',       'Jungle',
    'Palm',         'Bush',         'Mulberry',     'Juniper',      'Vine',
    'Ivy',          'Rose',         'Lily',         'Tulip',        'Daffodil',
    'Honeysuckle',  'Fuschia',      'Hazel',        'Walnut',       'Almond',
    'Lime',         'Lemon',        'Apple',        'Blossom',      'Bloom',
    'Crocus',       'Rose',         'Buttercup',    'Dandelion',    'Iris',
    'Carnation',    'Fern',         'Root',         'Branch',       'Leaf',
    'Seed',         'Flower',       'Petal',        'Pollen',       'Orchid',
    'Mangrove',     'Cypress',      'Sequoia',      'Sage',         'Heather',
    'Snapdragon',   'Daisy',        'Mountain',     'Hill',         'Alpine',
    'Chestnut',     'Valley',       'Glacier',      'Forest',       'Grove',
    'Glen',         'Tree',         'Thorn',        'Stump',        'Desert',
    'Canyon',       'Dune',         'Oasis',        'Mirage',       'Well',
    'Spring',       'Meadow',       'Field',        'Prairie',      'Grass',
    'Tundra',       'Island',       'Shore',        'Sand',         'Shell',
    'Surf',         'Wave',         'Foam',         'Tide',         'Lake',
    'River',        'Brook',        'Stream',       'Pool',         'Pond',
    'Sun',          'Sprinkle',     'Shade',        'Shadow',       'Rain',
    'Cloud',        'Storm',        'Hail',         'Snow',         'Sleet',
    'Thunder',      'Lightning',    'Wind',         'Hurricane',    'Typhoon',
    'Dawn',         'Sunrise',      'Morning',      'Noon',         'Twilight',
    'Evening',      'Sunset',       'Midnight',     'Night',        'Sky',
    'Star',         'Stellar',      'Comet',        'Nebula',       'Quasar',
    'Solar',        'Lunar',        'Planet',       'Meteor',       'Sprout',
    'Pear',         'Plum',         'Kiwi',         'Berry',        'Apricot',
    'Peach',        'Mango',        'Pineapple',    'Coconut',      'Olive',
    'Ginger',       'Root',         'Plain',        'Fancy',        'Stripe',
    'Spot',         'Speckle',      'Spangle',      'Ring',         'Band',
    'Blaze',        'Paint',        'Pinto',        'Shade',        'Tabby',
    'Brindle',      'Patch',        'Calico',       'Checker',      'Dot',
    'Pattern',      'Glitter',      'Glimmer',      'Shimmer',      'Dull',
    'Dust',         'Dirt',         'Glaze',        'Scratch',      'Quick',
    'Swift',        'Fast',         'Slow',         'Clever',       'Fire',
    'Flicker',      'Flash',        'Spark',        'Ember',        'Coal',
    'Flame',        'Chocolate',    'Vanilla',      'Sugar',        'Spice',
    'Cake',         'Pie',          'Cookie',       'Candy',        'Caramel',
    'Spiral',       'Round',        'Jelly',        'Square',       'Narrow',
    'Long',         'Short',        'Small',        'Tiny',         'Big',
    'Giant',        'Great',        'Atom',         'Peppermint',   'Mint',
    'Butter',       'Fringe',       'Rag',          'Quilt',        'Truth',
    'Lie',          'Holy',         'Curse',        'Noble',        'Sly',
    'Brave',        'Shy',          'Lava',         'Foul',         'Leather',
    'Fantasy',      'Keen',         'Luminous',     'Feather',      'Sticky',
    'Gossamer',     'Cotton',       'Rattle',       'Silk',         'Satin',
    'Cord',         'Denim',        'Flannel',      'Plaid',        'Wool',
    'Linen',        'Silent',       'Flax',         'Weak',         'Valiant',
    'Fierce',       'Gentle',       'Rhinestone',   'Splash',       'North',
    'South',        'East',         'West',         'Summer',       'Winter',
    'Autumn',       'Spring',       'Season',       'Equinox',      'Solstice',
    'Paper',        'Motley',       'Torch',        'Ballistic',    'Rampant',
    'Shag',         'Freckle',      'Wild',         'Free',         'Chain',
    'Sheer',        'Crazy',        'Mad',          'Candle',       'Ribbon',
    'Lace',         'Notch',        'Wax',          'Shine',        'Shallow',
    'Deep',         'Bubble',       'Harvest',      'Fluff',        'Venom',
    'Boom',         'Slash',        'Rune',         'Cold',         'Quill',
    'Love',         'Hate',         'Garnet',       'Zircon',       'Power',
    'Bone',         'Void',         'Horn',         'Glory',        'Cyber',
    'Nova',         'Hot',          'Helix',        'Cosmic',       'Quark',
    'Quiver',       'Holly',        'Clover',       'Polar',        'Regal',
    'Ripple',       'Ebony',        'Wheat',        'Phantom',      'Dew',
    'Chisel',       'Crack',        'Chatter',      'Laser',        'Foil',
    'Tin',          'Clever',       'Treasure',     'Maze',         'Twisty',
    'Curly',        'Fortune',      'Fate',         'Destiny',      'Cute',
    'Slime',        'Ink',          'Disco',        'Plume',        'Time',
    'Psychadelic',  'Relic',        'Fossil',       'Water',        'Savage',
    'Ancient',      'Rapid',        'Road',         'Trail',        'Stitch',
    'Button',       'Bow',          'Nimble',       'Zest',         'Sour',
    'Bitter',       'Phase',        'Fan',          'Frill',        'Plump',
    'Pickle',       'Mud',          'Puddle',       'Pond',         'River',
    'Spring',       'Stream',       'Battle',       'Arrow',        'Plume',
    'Roan',         'Pitch',        'Tar',          'Cat',          'Dog',
    'Horse',        'Lizard',       'Bird',         'Fish',         'Saber',
    'Scythe',       'Sharp',        'Soft',         'Razor',        'Neon',
    'Dandy',        'Weed',         'Swamp',        'Marsh',        'Bog',
    'Peat',         'Moor',         'Muck',         'Mire',         'Grave',
    'Fair',         'Just',         'Brick',        'Puzzle',       'Skitter',
    'Prong',        'Fork',         'Dent',         'Dour',         'Warp',
    'Luck',         'Coffee',       'Split',        'Chip',         'Hollow',
    'Heavy',        'Legend',       'Hickory',      'Mesquite',     'Nettle',
    'Rogue',        'Charm',        'Prickle',      'Bead',         'Sponge',
    'Whip',         'Bald',         'Frost',        'Fog',          'Oil',
    'Veil',         'Cliff',        'Volcano',      'Rift',         'Maze',
    'Proud',        'Dew',          'Mirror',       'Shard',        'Salt',
    'Pepper',       'Honey',        'Thread',       'Bristle',      'Ripple',
    'Glow',         'Zenith'
];

const nouns = [
    'Head',         'Crest',        'Crown',        'Tooth',        'Fang',
    'Horn',         'Frill',        'Skull',        'Bone',         'Tongue',
    'Throat',       'Voice',        'Nose',         'Snout',        'Chin',
    'Eye',          'Sight',        'Seer',         'Speaker',      'Singer',
    'Song',         'Chanter',      'Howler',       'Chatter',      'Shrieker',
    'Shriek',       'Jaw',          'Bite',         'Biter',        'Neck',
    'Shoulder',     'Fin',          'Wing',         'Arm',          'Lifter',
    'Grasp',        'Grabber',      'Hand',         'Paw',          'Foot',
    'Finger',       'Toe',          'Thumb',        'Talon',        'Palm',
    'Touch',        'Racer',        'Runner',       'Hoof',         'Fly',
    'Flier',        'Swoop',        'Roar',         'Hiss',         'Hisser',
    'Snarl',        'Dive',         'Diver',        'Rib',          'Chest',
    'Back',         'Ridge',        'Leg',          'Legs',         'Tail',
    'Beak',         'Walker',       'Lasher',       'Swisher',      'Carver',
    'Kicker',       'Roarer',       'Crusher',      'Spike',        'Shaker',
    'Charger',      'Hunter',       'Weaver',       'Crafter',      'Binder',
    'Scribe',       'Muse',         'Snap',         'Snapper',      'Slayer',
    'Stalker',      'Track',        'Tracker',      'Scar',         'Scarer',
    'Fright',       'Killer',       'Death',        'Doom',         'Healer',
    'Saver',        'Friend',       'Foe',          'Guardian',     'Thunder',
    'Lightning',    'Cloud',        'Storm',        'Forger',       'Scale',
    'Hair',         'Braid',        'Nape',         'Belly',        'Thief',
    'Stealer',      'Reaper',       'Giver',        'Taker',        'Dancer',
    'Player',       'Gambler',      'Twister',      'Turner',       'Painter',
    'Dart',         'Drifter',      'Sting',        'Stinger',      'Venom',
    'Spur',         'Ripper',       'Swallow',      'Devourer',     'Knight',
    'Lady',         'Lord',         'Queen',        'King',         'Master',
    'Mistress',     'Prince',       'Princess',     'Duke',         'Dutchess',
    'Samurai',      'Ninja',        'Knave',        'Slave',        'Servant',
    'Sage',         'Wizard',       'Witch',        'Warlock',      'Warrior',
    'Jester',       'Paladin',      'Bard',         'Trader',       'Sword',
    'Shield',       'Knife',        'Dagger',       'Arrow',        'Bow',
    'Fighter',      'Bane',         'Follower',     'Leader',       'Scourge',
    'Watcher',      'Cat',          'Panther',      'Tiger',        'Cougar',
    'Puma',         'Jaguar',       'Ocelot',       'Lynx',         'Lion',
    'Leopard',      'Ferret',       'Weasel',       'Wolverine',    'Bear',
    'Raccoon',      'Dog',          'Wolf',         'Kitten',       'Puppy',
    'Cub',          'Fox',          'Hound',        'Terrier',      'Coyote',
    'Hyena',        'Jackal',       'Pig',          'Horse',        'Donkey',
    'Stallion',     'Mare',         'Zebra',        'Antelope',     'Gazelle',
    'Deer',         'Buffalo',      'Bison',        'Boar',         'Elk',
    'Whale',        'Dolphin',      'Shark',        'Fish',         'Minnow',
    'Salmon',       'Ray',          'Fisher',       'Otter',        'Gull',
    'Duck',         'Goose',        'Crow',         'Raven',        'Bird',
    'Eagle',        'Raptor',       'Hawk',         'Falcon',       'Moose',
    'Heron',        'Owl',          'Stork',        'Crane',        'Sparrow',
    'Robin',        'Parrot',       'Cockatoo',     'Carp',         'Lizard',
    'Gecko',        'Iguana',       'Snake',        'Python',       'Viper',
    'Boa',          'Condor',       'Vulture',      'Spider',       'Fly',
    'Scorpion',     'Heron',        'Oriole',       'Toucan',       'Bee',
    'Wasp',         'Hornet',       'Rabbit',       'Bunny',        'Hare',
    'Brow',         'Mustang',      'Ox',           'Piper',        'Soarer',
    'Flasher',      'Moth',         'Mask',         'Hide',         'Hero',
    'Antler',       'Chill',        'Chiller',      'Gem',          'Ogre',
    'Myth',         'Elf',          'Fairy',        'Pixie',        'Dragon',
    'Griffin',      'Unicorn',      'Pegasus',      'Sprite',       'Fancier',
    'Chopper',      'Slicer',       'Skinner',      'Butterfly',    'Legend',
    'Wanderer',     'Rover',        'Raver',        'Loon',         'Lancer',
    'Glass',        'Glazer',       'Flame',        'Crystal',      'Lantern',
    'Lighter',      'Cloak',        'Bell',         'Ringer',       'Keeper',
    'Centaur',      'Bolt',         'Catcher',      'Whimsey',      'Quester',
    'Rat',          'Mouse',        'Serpent',      'Wyrm',         'Gargoyle',
    'Thorn',        'Whip',         'Rider',        'Spirit',       'Sentry',
    'Bat',          'Beetle',       'Burn',         'Cowl',         'Stone',
    'Gem',          'Collar',       'Mark',         'Grin',         'Scowl',
    'Spear',        'Razor',        'Edge',         'Seeker',       'Jay',
    'Ape',          'Monkey',       'Gorilla',      'Koala',        'Kangaroo',
    'Yak',          'Sloth',        'Ant',          'Roach',        'Weed',
    'Seed',         'Eater',        'Razor',        'Shirt',        'Face',
    'Goat',         'Mind',         'Shift',        'Rider',        'Face',
    'Mole',         'Vole',         'Pirate',       'Llama',        'Stag',
    'Bug',          'Cap',          'Boot',         'Drop',         'Hugger',
    'Sargent',      'Snagglefoot',  'Carpet',       'Curtain'
];

function randomNoun() {
    return nouns[Math.floor(Math.random() * nouns.length)];
}

function randomAdjective() {
    return adjectives[Math.floor(Math.random() * adjectives.length)];
}

exports.randomAdjective = randomAdjective;
exports.randomNoun = randomNoun;
