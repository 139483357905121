import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Popover } from '@mui/material';
import { CancelRounded as CancelIcon } from '@mui/icons-material';
import { useReactMediaRecorder } from 'react-media-recorder';

import VoiceMessageRecorderRenderer from './VoiceMessageRecorderRenderer';

const VoiceMessageRecorderModal = ({ show, close, sendAudioMessage, anchorElement }) => {
    const { status, startRecording, stopRecording, previewAudioStream } = useReactMediaRecorder({
        video: false,
        mediaRecorderOptions: { mimeType: 'audio/wav' },
        onStop: (blobUrl, blob) => recordingStopped(blobUrl, blob),
    });

    const [voiceMessage, setVoiceMessage] = useState(null);
    const [isPreviewStarted, setIsPreviewStarted] = useState(false);
    const shouldUnmount = useRef(true);

    useEffect(() => {
        let ignore = false;
        if (show && startRecording && !isPreviewStarted) {
            if (!ignore) {
                setIsPreviewStarted(true);
                startRecording();
            }
        }
        return () => {
            ignore = true;
        };
    }, [startRecording, isPreviewStarted, show]);

    const recordingStopped = (blobUrl, blob) => {
        if (shouldUnmount.current !== false) {
            setVoiceMessage(blob);
        } else {
            close();
        }
    };

    const handleClose = () => {
        shouldUnmount.current = false;
        if (status !== 'stopped') {
            stopRecording();
        } else {
            close();
        }
    };

    return (
        <Popover
            open={show}
            onClose={handleClose}
            anchorEl={anchorElement}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: { overflow: 'visible' },
            }}
            BackdropProps={{ invisible: false, className: 'bd' }}
        >
            <div>
                <VoiceMessageRecorderRenderer
                    height={32}
                    waveColor="rgb(150,150,150)"
                    progressColor="rgb(51, 122, 183)"
                    barWidth={4}
                    barAlign="middle"
                    stopRecording={stopRecording}
                    stream={previewAudioStream}
                    loadData={voiceMessage}
                    normalize={!!voiceMessage}
                    send={() => {
                        sendAudioMessage([
                            new File(
                                [voiceMessage],
                                'sylk-audio-recording.' + (voiceMessage.type.split(';')[0].split('/')[1] || 'webm'),
                                { type: voiceMessage.type }
                            ),
                        ]);
                        handleClose();
                    }}
                />
                <IconButton
                    disableRipple
                    disableFocusRipple
                    sx={{ position: 'absolute', top: -20, right: -20 }}
                    onClick={handleClose}
                >
                    <CancelIcon sx={{ fontSize: '2rem' }} />
                </IconButton>
            </div>
        </Popover>
    );
};

VoiceMessageRecorderModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    sendAudioMessage: PropTypes.func.isRequired,
    anchorElement: PropTypes.object.isRequired,
};

export default VoiceMessageRecorderModal;
