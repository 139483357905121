import { Typography } from "@mui/material";
import React from "react";

const Script = ({ text }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "25vh",
        // border: "1px solid black",
        padding: "5px",
        margin: "5px",
        overflowY: "auto",
      }}
    >
      <Typography variant="body2" gutterBottom>
        [Opening]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Verification]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Addressing Issue]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Understanding Circumstances]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Offering Solutions]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Negotiation]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Confirmation and Next Steps]
      </Typography>
      <Typography variant="body2" gutterBottom>
        [Closing]
      </Typography>
    </div>
  );
};

export default Script;
