import {utils as sylkrtcutils} from 'sylkrtc';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Switch, FormGroup, FormControl, FormControlLabel, Typography, ButtonGroup, Popper, ClickAwayListener, Paper, Grow, MenuItem, MenuList, ListItemIcon, Button } from '@mui/material';

const Conference = require('./Conference');
const FooterBox  = require('./FooterBox');
const Logo       = require('./Logo');
const PreMedia   = require('./PreMedia');


const GreenSwitch = (props) => (
  <Switch
    {...props}
    sx={{
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#5cb85c',
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#5cb85c',
      },
      '& .MuiSwitch-track': {
        backgroundColor: '#aaa',
      },
    }}
  />
);

class ConferenceByUriBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      lowBandwidth: props.lowBandwidth || false,
      preferredMedia: {
        audio: true,
        video: true,
      },
      open: false,
    };

    this.anchorRef = React.createRef();

    this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
    this.handleAudio = this.handleAudio.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.callStateChanged = this.callStateChanged.bind(this);
  }

  componentDidMount() {
    if (!this.props.localMedia) {
      this.props.getLocalMedia();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lowBandwidth !== this.state.lowBandwidth) {
      let preferredMedia = { ...this.state.preferredMedia };
      if (this.state.lowBandwidth) {
        preferredMedia.video = false;
        sylkrtcutils.closeMediaStream(this.props.localMedia);
        this.props.getLocalMedia(preferredMedia);
      } else {
        sylkrtcutils.closeMediaStream(this.props.localMedia);
        this.props.getLocalMedia(preferredMedia);
      }
    }

    if (!prevProps.currentCall && this.props.currentCall) {
      this.props.currentCall.on('stateChanged', this.callStateChanged);
    }
  }

  callStateChanged(oldState, newState) {
    if (newState === 'terminated') {
      console.log('Call terminated');
    }
  }

  handleDisplayNameChange(event) {
    this.setState({ displayName: event.target.value });
  }

  handleAudio(event) {
    let preferredMedia = { ...this.state.preferredMedia };
    preferredMedia.video = false;
    this.setState({ preferredMedia, open: false });
    this.handleSubmit(event);
  }

  handleToggle() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  handleClose(event) {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    let displayName = this.state.displayName || 'Guest';
    let preferredMedia = { ...this.state.preferredMedia };

    if (this.state.lowBandwidth) {
      preferredMedia.video = false;
    }

    this.props.handler(displayName, this.props.targetUri, {
      lowBandwidth: this.state.lowBandwidth,
      mediaConstraints: preferredMedia,
    });
  }

  render() {
    const { lowBandwidth, displayName, open } = this.state;
    const { localMedia, targetUri } = this.props;
    const friendlyName = targetUri.split('@')[0];

    const popoverBottom = (
      <Popover id="popover-positioned-bottom" title="Low Bandwidth mode">
        <Typography align="left">
          In low bandwidth mode you will participate with audio and chat. Video and screen-sharing are not available.
        </Typography>
      </Popover>
    );

    let content;
    if (this.props.account && localMedia) {
      content = <Conference {...this.props} lowBandwidth={lowBandwidth} />;
    } else {
      content = (
        <div>
          <PreMedia localMedia={localMedia} hide={lowBandwidth} />
          <Logo />
          <h2>
            Join conference room:
            <br />
            <strong>{friendlyName}</strong>
          </h2>
          <form onSubmit={this.handleSubmit}>
            <label className="sr-only">Name</label>
            <div className="input-group">
              <span className="input-group-addon">
                <i className="fa fa-globe fa-fw" />
              </span>
              <input
                id="inputName"
                className="form-control"
                placeholder="Enter your name (optional)"
                value={displayName}
                onChange={this.handleDisplayNameChange}
              />
            </div>
            <br />
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverBottom}>
              <FormControl fullWidth>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={lowBandwidth}
                        onChange={(event) => this.setState({ lowBandwidth: event.target.checked })}
                        inputProps={{ 'aria-label': 'enable low bandwidth mode' }}
                      />
                    }
                    label="Low-bandwidth mode"
                  />
                </FormGroup>
              </FormControl>
            </OverlayTrigger>
            <ButtonGroup fullWidth variant="contained" disableElevation ref={this.anchorRef} aria-label="split button">
              <Button type="submit" size="large">
                <i className="fa fa-sign-in" /> Join
              </Button>
              <Button onClick={this.handleToggle} size="large">
                <i className={clsx('fa', open ? 'fa-caret-up' : 'fa-caret-down')} />
              </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={this.anchorRef.current} transition style={{ marginTop: '8px' }}>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <MenuItem onClick={this.handleAudio}>
                          <ListItemIcon>
                            <i className="fa fa-video-camera-slash" />
                          </ListItemIcon>
                          Join without video
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </form>
        </div>
      );
    }

    return (
      <div className="cover-container">
        {!this.props.account && localMedia && <FooterBox />}
        <div className="inner cover">{content}</div>
      </div>
    );
  }
}

ConferenceByUriBox.propTypes = {
  notificationCenter: PropTypes.func.isRequired,
  handler: PropTypes.func.isRequired,
  hangupCall: PropTypes.func.isRequired,
  setDevice: PropTypes.func.isRequired,
  shareScreen: PropTypes.func.isRequired,
  propagateKeyPress: PropTypes.func.isRequired,
  toggleShortcuts: PropTypes.func.isRequired,
  getLocalMedia: PropTypes.func.isRequired,
  targetUri: PropTypes.string.isRequired,
  localMedia: PropTypes.object,
  account: PropTypes.object,
  currentCall: PropTypes.object,
  generatedVideoTrack: PropTypes.bool,
  lowBandwidth: PropTypes.bool,
};

export default ConferenceByUriBox;
