import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix default icon issue with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const DeviceMap = () => {
  const [deviceData, setDeviceData] = useState([]);

  // Fetch data from the Django backend
  useEffect(() => {
    axios.get('https://vii.ke/api/v1/scraper/devicedata/')
      .then(response => {
        setDeviceData(response.data);
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <MapContainer center={[0, 0]} zoom={2} style={{ height: '90vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {deviceData.map(device => (
        <Marker key={device.id} position={[device.latitude, device.longitude]}>
          <Popup>
            <div>
              <h3>Device Details</h3>
              <p><strong>IMSI:</strong> {device.IMSI}</p>
              <p><strong>RSSI:</strong> {device.RSSI}</p>
              <p><strong>RSRP:</strong> {device.RSRP}</p>
              <p><strong>SINR:</strong> {device.SINR}</p>
              <p><strong>CELL ID:</strong> {device.CELL_ID}</p>
              <p><strong>SINR:</strong> {device.SINR}</p>
              <p><strong>Latitude:</strong> {device.latitude}</p>
              <p><strong>Longitude:</strong> {device.longitude}</p>
              {/* Include other details as needed */}
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default DeviceMap;
