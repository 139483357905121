import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Avatar } from '@mui/material';
import clsx from 'clsx';
import utils from '../utils';

const useStyles = makeStyles({
    root: {
        transition: 'box-shadow 0.3s'
    },
    drawerAvatar: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        textTransform: 'uppercase'
    },
    margin: {
        margin: '5px'
    },
    card: {
        width: '70px',
        height: '70px',
        fontSize: '2.5rem',
        margin: '10px'
    },
    chatContact: {
        width: '50px',
        height: '50px',
        fontSize: '1.5625rem',
        margin: 0
    },
    carousel: {
        width: '80px',
        height: '80px',
        fontSize: '2.85rem',
        margin: 'auto'
    },
    large: {
        width: '144px',
        height: '144px',
        fontSize: '5rem',
        margin: 'auto'
    },
    shadow: {
        boxShadow: '0 0 2px 2px #999'
    },
    shadowSmall: {
        boxShadow: '0 0 5px 2px #999'
    }
});

const UserIcon = ({ identity, large, card, carousel, small, active, chatContact }) => {
    const classes = useStyles();
    const name = identity.displayName || identity.uri;
    let initials = name.split(' ', 2).map(x => x[0]).join('');
    const color = utils.generateMaterialColor(identity.uri)['300'];
    
    const avatarClasses = clsx(
        classes.root,
        classes.drawerAvatar,
        {
            [classes.card]: card,
            [classes.chatContact]: chatContact,
            [classes.large]: large,
            [classes.shadow]: active,
            [classes.shadowSmall]: active && small,
            [classes.carousel]: carousel,
            [classes.margin]: small
        }
    );

    if (identity.uri === 'anonymous@anonymous.invalid') {
        initials = <i className="fa fa-user fa-2x fa-fw"></i>;
    }

    return (
        <Avatar className={avatarClasses} style={{ backgroundColor: color }}>
            {initials}
        </Avatar>
    );
};

UserIcon.propTypes = {
    identity: PropTypes.object.isRequired,
    large: PropTypes.bool,
    card: PropTypes.bool,
    carousel: PropTypes.bool,
    small: PropTypes.bool,
    active: PropTypes.bool,
    chatContact: PropTypes.bool
};

export default UserIcon;
