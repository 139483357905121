export const campaignsData = [
  {
    id: 8971,
    idname: "collections_ke.nelly.wanjiru@4g-capital.com_17 Oct 14_14",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Running",
    progress: "53%",
    monitor: "Link",
  },
  {
    id: 8970,
    idname: "collections_ke.beth.wangui@4g-capital.com_17 Oct 14_14",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Running",
    progress: "73%",
    monitor: "Link",
  },
  {
    id: 8969,
    idname: "collections_ke.bramwel.angulo@4g-capital.com_17 Oct 14_14",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Running",
    progress: "90%",
    monitor: "Link",
  },
  {
    id: 8968,
    idname: "collections_ke.lilian.chepkemoi@4g-capital.com_17 Oct 13_13",
    totalCalls: 900,
    successfulCalls: 23,
    talkTime: "0:25:56",
    holdTime: "0:02:56",
    placedCalls: 72,
    status: "Running",
    progress: "8%",
    monitor: "Link",
  },
  {
    id: 8967,
    idname: "collections_ke.ndanu.kingi@4g-capital.com_17 Oct 13_13",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Running",
    progress: "0%",
    monitor: "Link",
  },
  {
    id: 8966,
    idname: "ussd_conversions.no-email@4g-capital.com_17 Oct 13_13",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Running",
    progress: "0%",
    monitor: "Link",
  },
  {
    id: 8965,
    idname: "collections_ke.grace.mwambia@4g-capital.com_17 Oct 13_13",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Running",
    progress: "0%",
    monitor: "Link",
  },
  {
    id: 8964,
    idname: "ussd_conversions.no-email@4g-capital.com_17 Oct 11_11",
    totalCalls: 900,
    successfulCalls: 0,
    talkTime: "00:00:00",
    holdTime: "00:00:00",
    placedCalls: 0,
    status: "Inactive",
    progress: "0%",
    monitor: "Link",
  },
  {
    id: 8963,
    idname: "collections_ke.beth.wangui@4g-capital.com_17 Oct 10_10",
    totalCalls: 278,
    successfulCalls: 12,
    talkTime: "0:29:10",
    holdTime: "0:01:23",
    placedCalls: 71,
    status: "Inactive",
    progress: "26%",
    monitor: "Link",
  },
];

export const callCenterData = [
  {
    id: 1,
    bound: "IN",
    phoneNumber: "+254701234567",
    dateTime: new Date("2023-10-26T09:15:00").toLocaleString(),
    duration: "12:45",
    user: "Customer2",
    disposition: "answered",
  },
  {
    id: 2,
    bound: "IN",
    phoneNumber: "+254712345678",
    dateTime: new Date("2023-10-26T10:30").toLocaleString(),
    duration: "8:20",
    user: " Client2",
    disposition: "no answer",
  },
  {
    id: 3,
    bound: "IN",
    phoneNumber: "+254733333333",
    dateTime: new Date("2023-10-26T11:45").toLocaleString(),
    duration: "15:00",
    user: "User1",
    disposition: "answered",
  },
  {
    id: 4,
    bound: "IN",
    phoneNumber: "+254754321987",
    dateTime: new Date("2023-10-26T13:00").toLocaleString(),
    duration: "10:10",
    user: "Resident1",
    disposition: "no answer",
  },
  {
    id: 5,
    bound: "IN",
    phoneNumber: "+254798765432",
    dateTime: new Date("2023-10-26T14:15").toLocaleString(),
    duration: "18:30",
    user: "Customer5",
    disposition: "answered",
  },
  {
    id: 6,
    bound: "IN",
    phoneNumber: "+254711111111",
    dateTime: new Date("2023-10-26T15:30").toLocaleString(),
    duration: "14:15",
    user: "User6",
    disposition: "answered",
  },
  {
    id: 7,
    bound: "OUT",
    phoneNumber: "+254711111111",
    dateTime: new Date("2023-10-26T15:30").toLocaleString(),
    duration: "14:15",
    user: "User7",
    disposition: "answered",
  },
];
