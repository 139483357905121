'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { styled } from '@mui/system';

// Styles using MUI's styled utility
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    '& h2': {
        fontSize: '20px',
    },
    '& div > p': {
        fontSize: '14px',
    },
    '& li.MuiListSubheader-root': {
        fontSize: '14px',
        textAlign: 'left',
    },
}));

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    fontFamily: 'inherit',
    fontSize: '14px',
    textAlign: 'left',
}));

const getContent = () => (
    <>
        To decrypt your messages, your private key is required.<br /><br />
        Please choose <strong><em>'Export private key'</em></strong> on a device/browser where you signed in before.<br /><br />
        If you <strong>lost access</strong> to this device/browser, please continue with 'Generate a new private key',
        or 'Cancel' and messaging will be <strong>disabled</strong>.
        If you choose to generate a new key, your previous messages cannot be read on newer devices.
    </>
);

const NewDeviceModal = ({ show, close, generatePGPKeys, privateKeyDisabled }) => (
    <Dialog
        open={show}
        onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                close();
            }
        }}
        maxWidth="sm"
        fullWidth
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        disableEscapeKeyDown
    >
        <StyledDialogTitle id="dialog-title">
            New device/browser?
        </StyledDialogTitle>
        <DialogContent dividers>
            <StyledDialogContentText id="dialog-description">
                {getContent()}
            </StyledDialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={close} variant="text" title="Cancel">
                Cancel
            </Button>
            <Button
                variant="contained"
                onClick={generatePGPKeys}
                title="Generate keys"
                disabled={privateKeyDisabled}
            >
                Generate Private Key
            </Button>
        </DialogActions>
    </Dialog>
);

NewDeviceModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    generatePGPKeys: PropTypes.func.isRequired,
    privateKeyDisabled: PropTypes.bool.isRequired,
};

export default NewDeviceModal;
