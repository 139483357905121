import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Button
} from '@mui/material';

const useStyles = makeStyles({
    bigger: {
        '& > h2': {
            fontSize: '20px'
        },
        '& li > div > div > span': {
            fontSize: '14px'
        },
        '& li.MuiListSubheader-root': {
            fontSize: '14px',
            textAlign: 'left'
        }
    }
});

const ShortcutsModal = ({ show, close }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={show}
            onClose={close}
            maxWidth="sm"
            fullWidth
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" className={classes.bigger}>
                Keyboard Shortcuts
            </DialogTitle>
            <DialogContent dividers>
                <List className={classes.bigger} dense>
                    <ListItem>
                        <ListItemText>Mute or unmute your microphone</ListItemText>
                        <ListItemSecondaryAction><kbd>M</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Mute or unmute your video</ListItemText>
                        <ListItemSecondaryAction><kbd>V</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText>View or exit full screen</ListItemText>
                        <ListItemSecondaryAction><kbd>F</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Switch between camera and screen sharing</ListItemText>
                        <ListItemSecondaryAction><kbd>S</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListSubheader>Conferences:</ListSubheader>
                    <ListItem>
                        <ListItemText>Open or close the chat</ListItemText>
                        <ListItemSecondaryAction><kbd>C</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Open or close dialog to switch devices</ListItemText>
                        <ListItemSecondaryAction><kbd>D</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Cycle through active speakers</ListItemText>
                        <ListItemSecondaryAction><kbd>Space</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Raise or lower your hand</ListItemText>
                        <ListItemSecondaryAction><kbd>H</kbd></ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText>Show help</ListItemText>
                        <ListItemSecondaryAction><kbd>?</kbd></ListItemSecondaryAction>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ShortcutsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
};

export default ShortcutsModal;
