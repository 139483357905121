import React from 'react';
import { styled } from '@mui/system';
import { Typography, Link } from '@mui/material';

const MainFooter = styled('footer')(({ theme }) => ({
  marginTop: 10,
  textAlign: 'center',
  bottom: 0,
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  justifyContent: 'space-between',
}));

const Version = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

// Usage example in a component
const useStyles = () => {
  return (
    <MainFooter>
      {/* Footer content */}
      <Version>
        {/* Version information */}
      </Version>
    </MainFooter>
  );
};

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.mainFooter}>
      <Typography variant="body2">
        <strong>
          Copyright © {new Date().getFullYear()}{' '}
          <Link href="https://zerxis.com/" target="_blank" rel="noopener noreferrer" color="inherit">
            Zerxis Limited
          </Link>
          . All rights reserved.
        </strong>
      </Typography>
      <Typography variant="body2" className={classes.version}>
        <b>Version</b> 1.0.1
      </Typography>
    </footer>
  );
};

export default Footer;
