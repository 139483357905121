import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import parse from 'html-react-parser';
import linkifyUrls from 'linkify-urls';
import { Chip } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';

const Message = ({ message }) => {
    const [parsedContent, setParsedContent] = useState();

    const preHtmlEntities = (str) => {
        return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    };

    const postHtmlEntities = (str) => {
        return String(str).replace(/(?!&amp;|&lt;|&gt;|&quot;)&/g, '&amp;');
    };

    const customUrlRegexp = () => (/((?:https?(?::\/\/))(?:www\.)?(?:[a-zA-Z\d-_.]+(?:(?:\.|@)[a-zA-Z\d]{2,})|localhost)(?:(?:[-a-zA-Z\d:%_+.~#!?&//=@();]*)(?:[,](?![\s]))*)*)/g);

    useEffect(() => {
        if (message.contentType === 'text/html') {
            setParsedContent(parse(message.content.trim(), {
                replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.href) {
                        domNode.attribs.target = '_blank';
                        return;
                    }
                    if (domNode.type === 'text') {
                        if (!domNode.parent || (domNode.parent.type === 'tag' && domNode.parent.name !== 'a')) {
                            let url = linkifyUrls(preHtmlEntities(domNode.data), {
                                customUrlRegexp,
                                attributes: {
                                    target: '_blank',
                                    rel: 'noopener noreferrer'
                                }
                            });
                            return (<span>{parse(postHtmlEntities(url))}</span>);
                        }
                    }
                }
            }));
        } else if (message.contentType.startsWith('image/')) {
            const image = `data:${message.contentType};base64,${message.content}`;
            setParsedContent(<img className="img-responsive" src={image} alt="Message Content" />);
        } else if (message.contentType === 'text/plain') {
            const linkfiedContent = linkifyUrls(preHtmlEntities(message.content), {
                customUrlRegexp,
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            });

            setParsedContent(
                <pre>{parse(postHtmlEntities(linkfiedContent))}</pre>
            );
        } else if (message.contentType === 'text/pgp-public-key') {
            setParsedContent(
                <Chip
                    sx={{ height: 18, fontSize: 11 }}
                    variant="outlined"
                    size="small"
                    icon={<LockIcon />}
                    label="Public key"
                />
            );
        }
    }, [message]);

    return (
        <Card className="vertical-center" style={{ paddingLeft: '2px', textOverflow: 'ellipsis', marginRight: '10px', overflow: 'hidden' }}>
            <Card.Header>Edit message</Card.Header>
            <Card.Body>
                {parsedContent}
            </Card.Body>
        </Card>
    );
};

Message.propTypes = {
    message: PropTypes.object.isRequired
};

export default Message;
