import React, { useEffect, useRef } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import backgroundImage from '../../../assets/images/ripple-bg.jpg'; // Adjust this path as needed

// Note: You need to install these packages:
// npm install jquery jquery.ripples
import $ from 'jquery';
import 'jquery.ripples';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const HomeSection = () => {
  const boxRef = useRef(null);

  useEffect(() => {
    // Initialize ripple effect
    $(boxRef.current).ripples({
      resolution: 510,
      dropRadius: 20,
      perturbance: 0.01,
    });

    // Cleanup function
    return () => {
      $(boxRef.current).ripples('destroy');
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return (
    <StyledBox ref={boxRef}>
      <ContentBox>
        <Container maxWidth="md">
          <StyledTypography variant="h2" component="h1">
            Bringing Your Customer Experience Up to Speed
          </StyledTypography>
          <StyledTypography variant="h5" component="p">
            With real-world solutions led by a team of senior consultants and subject matter experts, 
            we help guide our clients to improve customer experiences and strengthen business results
          </StyledTypography>
        </Container>
      </ContentBox>
    </StyledBox>
  );
};

export default HomeSection;
