import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import {
  Typography,
  useTheme,
  useMediaQuery,
  Drawer,
  Menu,
  MenuItem,
  Popover,
  Badge,
  Avatar,
} from "@mui/material";
import { Box, IconButton } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import GTranslateRoundedIcon from "@mui/icons-material/GTranslateRounded";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import OnlinePredictionRoundedIcon from "@mui/icons-material/OnlinePredictionRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase_setup/firebase";
import AgentProfile from "../profile/Profile";
import { ColorModeContext, tokens } from "../../theme";
import {
  getFirestore,
  collection,
  onSnapshot,
  ref,
  onValue,
  off,
} from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";

const TopBar = ({ toggleSidebar, signOut }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [time, setTime] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [user] = useAuthState(auth);
  const [profileOpen, setProfileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const { currentUser, userInfo } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const handleIconButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleClose();
    logout();
    navigate('/login');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Update time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    // Clear interval on unmount
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleNewNotification = (notification) => {
    // Your logic to handle new notifications
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      notification,
    ]);
    setNotificationCount((prevCount) => prevCount + 1);
  };

  const [notificationMenuAnchor, setNotificationMenuAnchor] = useState(null);

  const handleNotificationMenuClose = () => {
    setNotificationMenuAnchor(null);
  };

  const handleNotificationIconClick = (event) => {
    setNotificationMenuAnchor(event.currentTarget);
    setNotificationCount(0); // Reset the count when the menu is opened
  };


  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pl={2}
      pr={2}
      backgroundColor={colors.primary[500]}
      width="100%"
    >
      <Box display="flex" alignItems="center">
        <IconButton onClick={toggleSidebar}>
          <MenuOutlinedIcon sx={{ color: colors.custom[100] }} />
        </IconButton>
        <Typography
          variant="body1"
          component="div"
          sx={{ p: 2, color: colors.custom[100] }}
        >
          {userInfo?.user?.username}
        </Typography>
        <OnlinePredictionRoundedIcon sx={{ color: "green" }} />
      </Box>

      {matches ? (
        <>
          <Box display="flex" alignItems="center">
            <AccessTimeRoundedIcon sx={{ color: colors.custom[100] }} />
            <Typography
              variant="h6"
              component="div"
              pl={1}
              sx={{ color: colors.custom[100] }}
            >
              {time.toLocaleTimeString()}
            </Typography>
          </Box>
          {/* ICONS */}
          <Box display="flex">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <LightModeOutlinedIcon sx={{ color: colors.custom[100] }} />
              ) : (
                <DarkModeOutlinedIcon sx={{ color: colors.custom[100] }} />
              )}
            </IconButton>
            <IconButton>
              <GTranslateRoundedIcon sx={{ color: colors.custom[100] }} />
            </IconButton>
            <IconButton onClick={handleNotificationIconClick}>
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsOutlinedIcon sx={{ color: colors.custom[100] }} />
              </Badge>
            </IconButton>

            <Menu
              anchorEl={notificationMenuAnchor}
              open={Boolean(notificationMenuAnchor)}
              onClose={handleNotificationMenuClose}
            >
              {notifications.length > 0 ? (
                notifications.map((notification, index) => {
                  // console.log("Current Notification:", notification); // Added console log
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        width: "300px",
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        display: "flex",
                        flexDirection: "row", // Adjusted to display avatar and content side by side
                        alignItems: "center", // Align items vertically in the center
                      }}
                    >
                      <Avatar
                        alt="Sender Avatar"
                        src={notification.photoURL}
                        style={{ marginRight: "16px" }} // Add margin to separate avatar from text
                      />
                      <div>
                        {notification.date && (
                          <p style={{ marginBottom: "4px", color: "#555" }}>
                            Date: {formatTimeAgo(notification.date.toDate())}
                          </p>
                        )}
                        {notification.senderId && (
                          <p style={{ marginBottom: "4px", color: "#333" }}>
                            Sender: {notification.from}
                          </p>
                        )}
                        {notification.text && (
                          <p style={{ color: "#000" }}>
                            Text: {notification.text}
                          </p>
                        )}
                      </div>
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem>No notifications</MenuItem>
              )}
            </Menu>

            <IconButton>
              <SettingsOutlinedIcon sx={{ color: colors.custom[100] }} />
            </IconButton>
            <IconButton onClick={handleIconButtonClick}>
              <PersonOutlinedIcon sx={{ color: colors.custom[100] }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => setProfileOpen(true)}>Profile</MenuItem>
              {logout && (
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
              )}
            </Menu>
          </Box>
        </>
      ) : (
        <IconButton onClick={handleDrawerOpen}>
          <MenuOutlinedIcon />
        </IconButton>
      )}

      {!matches && (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
          width="40%"
        >
          <Box display="flex" flexDirection="column">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
            <IconButton>
              <GTranslateRoundedIcon />
            </IconButton>
            <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleIconButtonClick}>
              <PersonOutlinedIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => setProfileOpen(true)}>Profile</MenuItem>
              {logout && (
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
              )}
            </Menu>
          </Box>
        </Drawer>
      )}

      {/* Pop-up profile */}
      <Popover
        open={profileOpen}
        anchorEl={anchorEl}
        onClose={() => setProfileOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AgentProfile user={user} onClose={() => setProfileOpen(false)} />
      </Popover>
    </Box>
  );
};

function formatTimeAgo(date) {
  const now = new Date();
  const diffInMilliseconds = now - date;

  // Define the thresholds in milliseconds
  const minuteThreshold = 60 * 1000;
  const hourThreshold = 60 * minuteThreshold;
  const dayThreshold = 24 * hourThreshold;
  const weekThreshold = 7 * dayThreshold;
  const monthThreshold = 4 * weekThreshold;

  if (diffInMilliseconds < minuteThreshold) {
    const secondsAgo = Math.floor(diffInMilliseconds / 1000);
    return `${secondsAgo} second${secondsAgo === 1 ? "" : "s"} ago`;
  } else if (diffInMilliseconds < hourThreshold) {
    const minutesAgo = Math.floor(diffInMilliseconds / minuteThreshold);
    return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
  } else if (diffInMilliseconds < dayThreshold) {
    const hoursAgo = Math.floor(diffInMilliseconds / hourThreshold);
    return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  } else if (diffInMilliseconds < weekThreshold) {
    const daysAgo = Math.floor(diffInMilliseconds / dayThreshold);
    return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  } else if (diffInMilliseconds < monthThreshold) {
    const weeksAgo = Math.floor(diffInMilliseconds / weekThreshold);
    return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
  } else {
    // If it's more than a month, show the date
    return date.toLocaleDateString();
  }
}

export default TopBar;
