import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { tokens } from "../../../theme";
import Footer from "../../global/Footer";

function Breaks() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [newBreak, setNewBreak] = useState({
    id: null,
    name: "",
    duration: 15,
    frequency: "Daily",
    startTime: "09:00 AM",
    endTime: "09:15 AM",
  });

  const [isEditing, setIsEditing] = useState(false);

  const [breaksData, setBreaksData] = useState([
    {
      id: 1,
      name: "Tea",
      status: "Active",
      time: "15 minutes",
      frequency: "Daily",
      startTime: "10:00 AM",
      endTime: "10:15 AM",
    },
    {
      id: 2,
      name: "Lunch",
      status: "Active",
      time: "30 minutes",
      frequency: "Weekly",
      startTime: "02:00 PM",
      endTime: "02:30 PM",
    },
    {
      id: 3,
      name: "Meeting",
      status: "Inactive",
      time: "45 minutes",
      frequency: "Weekly",
      startTime: "09:05 PM",
      endTime: "09:50 PM",
    },
    // Add more break data as needed
  ]);

  const handleNewBreakChange = (event) => {
    setNewBreak({ ...newBreak, [event.target.name]: event.target.value });
  };

  const handleCreateBreak = () => {
    if (isEditing) {
      // Implement logic to update the break with the specified ID
      console.log("Updating break:", newBreak.id);
      setBreaksData((prevBreaks) =>
        prevBreaks.map((breakInfo) =>
          breakInfo.id === newBreak.id
            ? { ...breakInfo, ...newBreak }
            : breakInfo
        )
      );
    } else {
      // Implement logic to create a new break
      console.log("Creating new break:", newBreak);
      setBreaksData([
        ...breaksData,
        { id: breaksData.length + 1, ...newBreak, status: "Inactive" },
      ]);
    }

    setIsEditing(false);
    setNewBreak({
      id: null,
      name: "",
      duration: 15,
      frequency: "Daily",
      startTime: "09:00 AM",
      endTime: "09:15 AM",
    });
  };

  const handleUpdateBreak = (id) => {
    // Set the form for editing the break
    const breakToEdit = breaksData.find((breakInfo) => breakInfo.id === id);
    setNewBreak({ ...breakToEdit });
    setIsEditing(true);
  };

  const handleDeleteBreak = (id) => {
    // Implement logic to delete the break with the specified ID
    console.log("Deleting break:", id);
    setBreaksData((prevBreaks) =>
      prevBreaks.filter((breakInfo) => breakInfo.id !== id)
    );
  };

  const handleActivateBreak = (id) => {
    // Implement logic to activate the break with the specified ID
    console.log("Activating break:", id);
    setBreaksData((prevBreaks) =>
      prevBreaks.map((breakInfo) =>
        breakInfo.id === id ? { ...breakInfo, status: "Active" } : breakInfo
      )
    );
  };

  const handleDeactivateBreak = (id) => {
    // Implement logic to deactivate the break with the specified ID
    console.log("Deactivating break:", id);
    setBreaksData((prevBreaks) =>
      prevBreaks.map((breakInfo) =>
        breakInfo.id === id ? { ...breakInfo, status: "Inactive" } : breakInfo
      )
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" p={1}>
        <Typography
          variant="h3"
          color={colors.skyblueAccent[500]}
          fontWeight="bold"
        >
          Agent Breaks
        </Typography>
      </Box>
      <Divider />
      <Box mt={3}>
        <Typography variant="h6">Existing Breaks</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Break Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {breaksData.map((breakInfo) => (
                <TableRow key={breakInfo.id}>
                  <TableCell>{breakInfo.name}</TableCell>
                  <TableCell>{breakInfo.status}</TableCell>
                  <TableCell>{breakInfo.time}</TableCell>
                  <TableCell>{breakInfo.frequency}</TableCell>
                  <TableCell>{breakInfo.startTime}</TableCell>
                  <TableCell>{breakInfo.endTime}</TableCell>
                  <TableCell>
                    {/* Action buttons for activate, deactivate, update, and delete */}
                    <Button
                      variant="outlined"
                      color={
                        breakInfo.status === "Active" ? "primary" : "success"
                      }
                      onClick={() =>
                        breakInfo.status === "Active"
                          ? handleDeactivateBreak(breakInfo.id)
                          : handleActivateBreak(breakInfo.id)
                      }
                    >
                      {breakInfo.status === "Active"
                        ? "Deactivate"
                        : "Activate"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleUpdateBreak(breakInfo.id)}
                    >
                      Update
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteBreak(breakInfo.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box display="flex">
          <Box>
            <Button
              sx={{ margin: "5px", fontSize: "0.8rem", textTransform: "none" }}
              variant="outlined"
              color="error"
              onClick={() => {
                setIsEditing(!isEditing); // Toggle the isEditing state

                // If switching to editing mode, set initial form values
                if (!isEditing) {
                  setNewBreak({
                    id: null,
                    name: "",
                    duration: 15,
                    frequency: "Daily",
                    startTime: "09:00 AM",
                    endTime: "09:15 AM",
                  });
                }
              }}
            >
              {isEditing ? "Cancel Edit" : "Create New Break"}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Conditional rendering of Create New Break form */}
      {isEditing && (
        <Box mt={3}>
          <Typography variant="h6">"Edit Break"</Typography>
          <Box mt={2}>
            <TextField
              label="Break Name"
              variant="outlined"
              fullWidth
              name="name"
              value={newBreak.name}
              onChange={handleNewBreakChange}
            />
          </Box>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="duration-label">Duration (minutes)</InputLabel>
              <Select
                label="Duration (minutes)"
                id="duration"
                name="duration"
                value={newBreak.duration}
                onChange={handleNewBreakChange}
              >
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={180}>180</MenuItem>
                <MenuItem value={360}>360</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="frequency-label">Frequency</InputLabel>
              <Select
                label="Frequency"
                id="frequency"
                name="frequency"
                value={newBreak.frequency}
                onChange={handleNewBreakChange}
              >
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                {/* Add more frequency options as needed */}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <TextField
              label="Start Time"
              variant="outlined"
              fullWidth
              name="startTime"
              value={newBreak.startTime}
              onChange={handleNewBreakChange}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label="End Time"
              variant="outlined"
              fullWidth
              name="endTime"
              value={newBreak.endTime}
              onChange={handleNewBreakChange}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateBreak}
            >
              "Update"
            </Button>
          </Box>
        </Box>
      )}
      {/* Conditional rendering of Create New Break form */}
      {!isEditing && (
        <Box mt={3}>
          <Typography variant="h6">
            <Typography variant="h6">Create New Break</Typography>
          </Typography>
          <Box mt={2}>
            <TextField
              label="Break Name"
              variant="outlined"
              fullWidth
              name="name"
              value={newBreak.name}
              onChange={handleNewBreakChange}
            />
          </Box>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="duration-label">Duration (minutes)</InputLabel>
              <Select
                label="Duration (minutes)"
                id="duration"
                name="duration"
                value={newBreak.duration}
                onChange={handleNewBreakChange}
              >
                <MenuItem value={15}>5</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={180}>180</MenuItem>
                <MenuItem value={360}>360</MenuItem>
                {/* Add more duration options as needed */}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="frequency-label">Frequency</InputLabel>
              <Select
                label="Frequency"
                id="frequency"
                name="frequency"
                value={newBreak.frequency}
                onChange={handleNewBreakChange}
              >
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                {/* Add more frequency options as needed */}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <TextField
              label="Start Time"
              variant="outlined"
              fullWidth
              name="startTime"
              value={newBreak.startTime}
              onChange={handleNewBreakChange}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label="End Time"
              variant="outlined"
              fullWidth
              name="endTime"
              value={newBreak.endTime}
              onChange={handleNewBreakChange}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateBreak}
            >
              Create
            </Button>
          </Box>
        </Box>
      )}

      {/* Table displaying existing breaks */}
      <Footer/>
    </Box>
  );
}

export default Breaks;
