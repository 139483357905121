import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import xss from 'xss';
import { DateTime } from 'luxon';
import { styled } from '@mui/material/styles';
import {
    Avatar,
    ListSubheader,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
    Grid,
    Typography,
    Divider,
    MenuItem,
    Chip
} from '@mui/material';
import {
    Lock as LockIcon,
    Done as DoneIcon,
    DoneAll as DoneAllIcon,
    ErrorOutline as ErrorOutlineIcon,
    GetApp as DownloadIcon,
    GraphicEq as GraphicEqRounded,
    Add as AddIcon
} from '@mui/icons-material';

import UserIcon from '../UserIcon';
import CustomContextMenu from '../CustomContextMenu';
import DragAndDrop from '../DragAndDrop';

const DEBUG = debug('blinkrtc:ContactList');

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    fontFamily: 'inherit',
    fontSize: 16,
    textTransform: 'uppercase',
    height: '66px',
    lineHeight: '66px',
    color: '#333'
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
    '&:before': {
        backgroundColor: selected ? '#EBEBEB' : '#fff',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -15,
        right: -15,
        zIndex: -1,
        content: '""'
    }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    height: 18,
    fontSize: 11,
    '& .MuiChip-icon': {
        width: 12,
        height: 12
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontFamily: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const formatTime = (message) => {
    // ... (Keep the existing formatTime function)
};

const ContactList = (props) => {
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const contactRef = useRef(null);

    useEffect(() => {
        if (props.filter !== '') {
            setFilteredMessages(
                [].concat.apply(
                    [],
                    Object.values(props.messages)
                ).filter(contact =>
                    contact.content.indexOf(props.filter) !== -1 && contact.contentType !== 'text/pgp-public-key'
                )
            );
        } else {
            setFilteredMessages([]);
        }
    }, [props.filter, props.messages]);

    const getDisplayName = useCallback((uri) => {
        if (props.contactCache.has(uri)) {
            return { uri: uri, displayName: props.contactCache.get(uri) };
        }
        return { uri: uri };
    }, [props.contactCache]);

    const contacts = useMemo(() => {
        // ... (Keep the existing contacts memo logic)
    }, [props.messages, props.filter, getDisplayName]);

    const numbers = useMemo(() => {
        // ... (Keep the existing numbers memo logic)
    }, [props.messages, props.unread]);

    const statusIcon = (message) => {
        // ... (Keep the existing statusIcon function)
    };

    const parseContent = (message, contact) => {
        // ... (Keep the existing parseContent function, but update Chip components to use StyledChip)
    };

    const getHighlightedText = (text, highlight) => {
        // ... (Keep the existing getHighlightedText function)
    };

    const switchChat = (uri, id) => {
        props.loadMessages(uri, id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ overflow: 'auto', marginRight: -15, paddingRight: 15, marginLeft: -15, paddingLeft: 15, scrollbarWidth: 'thin' }}>
            <List disablePadding>
                {props.filter && props.defaultDomain && Object.keys(props.messages).indexOf(props.filter) === -1 && (
                    <>
                        <ListItem key="new" style={{ height: '66px' }}>
                            Start a new chat to {props.filter.indexOf('@') === -1 ? `${props.filter}@${props.defaultDomain}` : props.filter}
                            <ListItemSecondaryAction>
                                <IconButton aria-label="add" edge="end" onClick={props.startChat}>
                                    <AddIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider component="li" key="divider" />
                    </>
                )}
                {props.filter && contacts.length !== 0 && (
                    <>
                        <StyledListSubheader>Chats</StyledListSubheader>
                        <Divider component="li" key="divider" variant="inset" />
                    </>
                )}
                <CustomContextMenu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    keepMounted
                >
                    <MenuItem onClick={() => { props.removeChat(contactRef.current); handleClose() }}>
                        Remove Chat
                    </MenuItem>
                </CustomContextMenu>
                {contacts.map(contact => (
                    <React.Fragment key={contact.uri}>
                        <StyledListItem
                            selected={props.selectedUri === contact.uri}
                            alignItems="flex-start"
                            onClick={() => switchChat(contact.uri)}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                contactRef.current = contact.uri;
                                const { clientX, clientY } = e;
                                setAnchorEl({ getBoundingClientRect: () => ({ top: clientY, left: clientX }) });
                            }}
                            disableGutters
                        >
                            <DragAndDrop
                                title="Drop files to share them"
                                small
                                useFlex
                                handleDrop={(files) => { props.uploadFiles(files, contact.uri) }}
                            >
                                <ListItemAvatar style={{ minWidth: 60, marginTop: 0 }}>
                                    <UserIcon identity={contact} active={false} chatContact={true} />
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Grid container spacing={0} justifyContent="space-between" alignItems="baseline" wrap="nowrap">
                                            <Grid item zeroMinWidth>
                                                <Typography
                                                    variant="h4"
                                                    style={{ fontSize: '17px', fontFamily: 'inherit', flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                >
                                                    {getHighlightedText(contact.displayName || contact.uri, props.filter)}
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{ flexShrink: 0, marginLeft: 4 }}>
                                                <Typography style={{ fontSize: '14px', fontFamily: 'inherit', color: '#888' }}>
                                                    {contact.message && statusIcon(contact.message)}
                                                    {contact.message && formatTime(contact.message)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    secondary={
                                        <Grid container spacing={2} justifyContent="space-between" alignItems="baseline" wrap="nowrap">
                                            <Grid item zeroMinWidth>
                                                <StyledTypography
                                                    style={{
                                                        flexGrow: 1,
                                                        color: contact.message && (contact.message.state === 'error' || contact.message.state === 'failed') && '#a94442'
                                                    }}
                                                >
                                                    {contact.message && parseContent(contact.message, contact)}
                                                </StyledTypography>
                                            </Grid>
                                            {props.selectedUri !== contact.uri && numbers[contact.uri] !== 0 && (
                                                <Grid item>
                                                    <Avatar style={{ backgroundColor: 'rgb(92,184,92)', width: '20px', height: '20px' }}>
                                                        {numbers[contact.uri]}
                                                    </Avatar>
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                />
                            </DragAndDrop>
                        </StyledListItem>
                        <Divider style={props.selectedUri === contact.uri ? { background: 'transparent' } : {}} component="li" />
                    </React.Fragment>
                ))}
                {props.filter && filteredMessages.length !== 0 && (
                    <>
                        <StyledListSubheader>Messages</StyledListSubheader>
                        <Divider component="li" />
                        {filteredMessages.map(message => (
                            <React.Fragment key={message.id}>
                                <StyledListItem
                                    alignItems="flex-start"
                                    onClick={() => {
                                        let group = message.receiver;
                                        if (message.state === 'received') {
                                            group = message.sender.uri;
                                        }
                                        switchChat(group, message.id)
                                    }}
                                    disableGutters
                                >
                                    <ListItemText
                                        primary={
                                            <Grid container spacing={2} justifyContent="space-between" alignItems="baseline">
                                                <Grid item zeroMinWidth>
                                                    <Typography
                                                        variant="h4"
                                                        style={{ fontSize: '17px', fontFamily: 'inherit', flexGrow: 1 }}
                                                    >
                                                        {message.state === 'received'
                                                            ? (getDisplayName(message.sender.uri).displayName || message.sender.uri)
                                                            : (getDisplayName(message.receiver).displayName || message.receiver)
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{ flexShrink: 0, marginLeft: 4 }}>
                                                    <Typography style={{ fontSize: '14px', fontFamily: 'inherit', color: '#888' }}>
                                                        {message && statusIcon(message)}
                                                        {message && formatTime(message)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        secondary={
                                            <StyledTypography>
                                                {message && getHighlightedText(parseContent(message, message.state === 'received' ? message.sender.uri : message.receiver), props.filter)}
                                            </StyledTypography>
                                        }
                                    />
                                </StyledListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </>
                )}
            </List>
        </div>
    );
}

ContactList.propTypes = {
    // ... (Keep the existing propTypes)
};

export default ContactList;
