// src/pages/PrivacyPolicyPage.js
import { useState, React } from 'react';
import axios from 'axios';
import Navbar from '../scenes/landing/Public/Navbar';
import Footer from '../scenes/landing/Public/Footer';
import { Box, Container, Typography } from '@mui/material';


const DataDeletionRequestPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/delete-request`, { email });
      if (response.status === 200) {
        setMessage('Your data deletion request has been submitted successfully.');
      } else {
        setError('There was an issue submitting your request. Please try again.');
      }
    } catch (err) {
      setError('There was an error submitting your request. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <Container
      component="main"
      sx={{ marginTop: '64px' }} // Adjust the margin based on your navbar height
      >
      <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="80vh" // Center the login form vertically
      >
      <h1>Data Deletion Request</h1>
      <p>If you would like to request the deletion of your data, please enter your email address below and submit the form.</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Submit Request</button>
      </form>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      </Box>
      </Container>
      <Footer />
    </>
  );
};

export default DataDeletionRequestPage;
