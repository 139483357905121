// CallComponent.js
import React, { useEffect, useState } from 'react';

function CallComponent({ serverId = 1 }) {
  const [socket, setSocket] = useState(null);
  const [callInfo, setCallInfo] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_BACKEND_WS_URL}/ws/asterisk/`);

    ws.onopen = () => {
      console.log('WebSocket connected');
      setSocket(ws); // Set socket only when connected
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Update callInfo based on incoming AMI events
      setCallInfo(data);
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
      setSocket(null); // Reset socket on close
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) { // Check if WebSocket is open
        ws.close();
      }
    };
  }, [serverId]);

  const hangupCall = () => {
    if (socket && callInfo) { // Ensure callInfo is available
      socket.send(JSON.stringify({ action: 'hangup', channel: callInfo.channel }));
    }
  };

  return (
    <div>
      {callInfo && (
        <div>
          <p>Extension: {callInfo.extension}</p>
          <p>Caller ID: {callInfo.callerid}</p>
          <button onClick={hangupCall}>Hang Up</button>
        </div>
      )}
    </div>
  );
}

export default CallComponent;
