'use strict';

import React, { useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { DateTime } from 'luxon';
import Notify from 'notifyjs';
import { LinearProgress } from '@mui/material';
import config from '../config';

const NotificationCenter = (props) => {
    const notificationSystemRef = useRef();

    const postSystemNotification = (title, options = {}) => {
        const defaultOptions = {
            icon: 'assets/images/blink-48.png',
            body: '',
            timeout: 5,
            silent: true,
        };

        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const android = !!ua.match(/Android/i);
        const chrome = !!ua.match(/Chrome/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        const chromeAndroid = android && chrome;

        if (!iOSSafari && !chromeAndroid) {
            if (Notify.needsPermission) {
                Notify.requestPermission(() => {
                    _postSystemNotification(title, { ...defaultOptions, ...options });
                });
            } else {
                _postSystemNotification(title, { ...defaultOptions, ...options });
            }
        }
    };

    const _postSystemNotification = (title, options) => {
        const n = new Notify(title, options);
        n.show();
        return n;
    };

    const postConferenceInvite = (originator, room, cb) => {
        if (originator.uri.endsWith(config.defaultGuestDomain)) {
            return;
        }
        const idx = room.indexOf('@');
        if (idx === -1) {
            return;
        }
        const currentDate = DateTime.local().toFormat('DDD, HH:mm:ss');
        const action = {
            label: 'Join',
            callback: () => cb(room),
        };

        notificationSystemRef.current.addNotification({
            message: `${originator.displayName || originator.uri} invited you to join conference room ${room.substring(0, idx)}<br />On ${currentDate}`,
            title: 'Conference Invite',
            autoDismiss: 0,
            action,
            level: 'success',
            position: 'br',
        });
    };

    const postMissedCall = (originator, cb) => {
        const currentDate = DateTime.local().toFormat('DDD, HH:mm:ss');
        const action = originator.uri.endsWith(config.defaultGuestDomain)
            ? null
            : { label: 'Call', callback: () => cb(originator.uri) };

        notificationSystemRef.current.addNotification({
            message: `From ${originator.displayName || originator.uri} <br />On ${currentDate}`,
            title: 'Missed Call',
            autoDismiss: 0,
            action,
            level: 'info',
            position: 'br',
        });
    };

    const postFileUploadProgress = (filename, cb) => {
        const progressNotification = notificationSystemRef.current.addNotification({
            message: `${filename}`,
            title: 'Uploading file',
            autoDismiss: 0,
            level: 'info',
            position: 'br',
            onRemove: cb,
            children: (
                <div>
                    <LinearProgress
                        sx={{ marginTop: 2 }}
                        variant="determinate"
                        value={0}
                    />
                </div>
            ),
        });
        return progressNotification;
    };

    const editFileUploadNotification = (progress = 100, notification) => {
        notificationSystemRef.current.editNotification(notification, {
            level: 'success',
            children: (
                <div>
                    <LinearProgress
                        sx={{ marginTop: 2 }}
                        variant="determinate"
                        value={progress}
                    />
                </div>
            ),
        });
    };

    const removeFileUploadNotification = (notification) => {
        setTimeout(() => {
            notificationSystemRef.current.removeNotification(notification);
        }, 3000);
    };

    const removeNotification = (notification) => {
        notificationSystemRef.current.removeNotification(notification);
    };

    const postFileUploadFailed = (filename) => {
        notificationSystemRef.current.addNotification({
            message: `Uploading of ${filename} failed`,
            title: 'File sharing failed',
            autoDismiss: 10,
            level: 'error',
            position: 'br',
        });
    };

    const postFileShared = (file, cb) => {
        const uploader = file.uploader.displayName || file.uploader.uri || file.uploader;
        notificationSystemRef.current.addNotification({
            message: `${uploader} shared ${file.filename}`,
            title: 'File shared',
            autoDismiss: 10,
            level: 'info',
            position: 'br',
            action: {
                label: 'Show Files',
                callback: cb,
            },
        });
    };

    const postNewMessage = (message, cb) => {
        const sender = message.sender.displayName || message.sender.uri;
        const title = message.contentType === 'application/sylk-file-transfer'
            ? `File from ${sender}`
            : `Message from ${sender}`;

        const notification = {
            title,
            autoDismiss: 10,
            level: 'info',
            position: 'bl',
        };

        if (cb) {
            notification.action = { label: 'Show Chat', callback: cb };
        }

        return notificationSystemRef.current.addNotification(notification);
    };

    const style = {
        Containers: {
            bc: {
                width: '460px',
                marginLeft: '-230px',
            },
        },
    };

    return (
        <NotificationSystem ref={notificationSystemRef} allowHTML style={style} />
    );
};

export default NotificationCenter;
