import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const CallToAction = () => {
  const handleClick = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledSection>
      <Container maxWidth="md">
        <Box textAlign="center">
          <Typography variant="h3" component="h3" gutterBottom>
            Ready to grow your business?
          </Typography>
          <StyledButton 
            variant="contained" 
            size="large"
            onClick={handleClick}
          >
            Get A Quote Today
          </StyledButton>
        </Box>
      </Container>
    </StyledSection>
  );
};

export default CallToAction;
