import React, { useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReportHeader from "../../../components/ReportHeader";
import { tokens } from "../../../theme";
import DownloadIcon from "@mui/icons-material/Download";

const WalkIn = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const defaultStartDate = new Date(
    new Date().getTime() - 1 * 24 * 60 * 60 * 1000
  ); // 4 days ago
  const defaultEndDate = new Date(
    new Date().getTime() + 0 * 24 * 60 * 60 * 1000
  ); //  now
  const [values, setValues] = useState([defaultStartDate, defaultEndDate]);

  const handleChange = (newValues) => {
    setValues(newValues);
  };

  const isDateValid = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };
  function createData(
    agent_no,
    agent,
    call_date,
    start_time,
    end_time,
    duration,
    duration_wait,
    queue,
    type,
    phone,
    transfer,
    status,
    recording
  ) {
    return {
      agent_no,
      agent,
      call_date,
      start_time,
      end_time,
      duration,
      duration_wait,
      queue,
      type,
      phone,
      transfer,
      status,
      recording,
    };
  }

  const rows = [
    createData(
      3994,
      "John Doe",
      "2023-11-23",
      "10:00 AM",
      "11:30 AM",
      "04:21",
      "00:35",
      "Support",
      "Inbound",
      "123-456-7890",
      "No",
      "Connected",
      "Yes"
    ),
    createData(
      3995,
      "Jane Smith",
      "2023-11-23",
      "1:00 PM",
      "2:30 PM",
      "01:21",
      "0:14",
      "Sales",
      "Outbound",
      "987-654-3210",
      "Yes",
      "On Hold",
      "No"
    ),
    createData(
      3996,
      "Bob Johnson",
      "2023-11-23",
      "3:00 PM",
      "5:00 PM",
      "02:21",
      "00:58",
      "Service",
      "Inbound",
      "555-123-4567",
      "No",
      "Disconnected",
      "Yes"
    ),
  ];

  const [data, setData] = React.useState(rows);

  const handleDownload = () => {
    const csvData = data
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Total Calls.csv";
    link.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <Box>
      <ReportHeader title="Walkin" subtitle="Report on Walkin" />
      <DatePicker value={values} onChange={handleChange} range />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
      >
        <Typography
          variant="h6"
          // color={colors.skyblueAccent[500]}
          // fontWeight="bold"
        >
          <div>
            {Array.isArray(values) &&
            values.length === 2 &&
            isDateValid(values[0]) &&
            isDateValid(values[1]) ? (
              <div>
                Selected Date Range: {values[0].toLocaleDateString()} to{" "}
                {values[1].toLocaleDateString()}
              </div>
            ) : (
              <div>
                Please select a valid date range. Debug:{" "}
                {JSON.stringify(values)}
              </div>
            )}
          </div>
        </Typography>
        <Button
          style={{
            background: "#3498db",
            color: "white",
            padding: "7px 15px",
            display: "flex",
            jystifyContent: "space-between",
            fontSize: "0.9em",
            textIndent: "15px",
          }}
          onClick={handleDownload}
        >
          <DownloadIcon />
          Download
        </Button>
      </Box>
      <TableContainer sx={{ maxWidth: 750 }}>
        <Table
          size="medium"
          dense="true"
          aria-label="caption table"
          style={{ whiteSpace: "nowrap" }}
        >
          <caption>Walkin</caption>
          <TableHead>
          <TableRow style={{backgroundColor:" #3498db", color: "#fff"}}>
              <TableCell>No.</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Duration Wait</TableCell>
              <TableCell>Queue</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Transfer</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Recording</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.agent_no}
                </TableCell>
                <TableCell>{row.agent}</TableCell>
                <TableCell>{row.start_time}</TableCell>
                <TableCell>{row.end_time}</TableCell>
                <TableCell>{row.duration}</TableCell>
                <TableCell>{row.duration_wait}</TableCell>
                <TableCell>{row.queue}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.transfer}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.recording}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default WalkIn;
