import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Box, Button } from '@mui/material';
import { InView } from 'react-intersection-observer';

import ConferenceModal from './ConferenceModal';
import HistoryCard from './HistoryCard';
import HistoryTileBox from './HistoryTileBox';
import FooterBox from './FooterBox';
import URIInput from './URIInput';
import config from '../config';
import utils from '../utils';

const ReadyBox = ({
    account,
    startCall,
    startConference,
    startChat,
    missedTargetUri = '',
    history = [],
    serverHistory = [],
    noConnection = false
}) => {
    const [targetUri, setTargetUri] = useState(missedTargetUri);
    const [showConferenceModal, setShowConferenceModal] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [height, setHeight] = useState(utils.getWindowHeight() - 50);

    const stickyTopRef = useRef(null);
    const conference = useRef('');

    useEffect(() => {
        const observer = new IntersectionObserver(([e]) => {
            setSticky(e.intersectionRatio < 1);
        }, { threshold: [1] });

        if (stickyTopRef.current) {
            observer.observe(stickyTopRef.current);
        }

        const handleResize = () => {
            setHeight(utils.getWindowHeight() - 50);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            if (stickyTopRef.current) {
                observer.unobserve(stickyTopRef.current);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getTargetUri = () => {
        const defaultDomain = account.id.substring(account.id.indexOf('@') + 1);
        return utils.normalizeUri(targetUri, defaultDomain);
    };

    const handleTargetChange = (value) => {
        if (value.startsWith(config.publicUrl)) {
            const url = new URL(value);
            if (url.pathname.startsWith('/conference')) {
                conference.current = url.pathname.split('/').pop();
                setShowConferenceModal(true);
            } else if (url.pathname.startsWith('/call')) {
                value = url.pathname.split('/').pop();
            }
        }
        setTargetUri(value);
    };

    const handleTargetSelect = () => {
        if (noConnection) return;
        if (targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            startConference(targetUri);
        } else {
            startCall(getTargetUri(), { audio: true, video: true });
        }
    };

    const handleAudioCall = (e) => {
        e.preventDefault();
        if (targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            startConference(targetUri);
        } else {
            startCall(getTargetUri(), { audio: true, video: false });
        }
    };

    const handleVideoCall = (e) => {
        e.preventDefault();
        if (targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            startConference(targetUri);
        } else {
            startCall(getTargetUri(), { audio: true, video: true });
        }
    };

    const handleChat = (e) => {
        e.preventDefault();
        if (!targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            startChat(getTargetUri());
        }
    };

    const showConferenceModalFn = (e) => {
        e.preventDefault();
        if (targetUri.length !== 0) {
            const uri = `${targetUri.split('@')[0].replace(/[\s()-]/g, '')}@${config.defaultConferenceDomain}`;
            handleConferenceCall(uri.toLowerCase());
        } else {
            setShowConferenceModal(true);
        }
    };

    const handleConferenceCall = (targetUri, extraOptions) => {
        setShowConferenceModal(false);
        conference.current = '';
        if (targetUri) {
            startConference(targetUri, extraOptions);
        }
    };

    const buttonClasses = clsx({
        'btn-round-big': true,
        'btn-success': targetUri.length !== 0,
        'btn-default': targetUri.length === 0
    });

    const stickyClasses = clsx({
        'sticky-wrapper': true,
        'sticky': sticky
    });

    // Join URIs from local and server history for input
    let mergedHistory = history.concat(serverHistory.map(e => e.remoteParty));
    mergedHistory = [...new Set(mergedHistory)];

    return (
        <div>
            <Box className="cover-container">
                <Box className="inner cover scroll" style={{ height }}>
                    <Box className={stickyClasses} ref={stickyTopRef}>
                        <Box className="form-dial">
                            <p className="lead">Enter the address you wish to call</p>
                            <URIInput
                                defaultValue={targetUri}
                                data={mergedHistory}
                                onChange={handleTargetChange}
                                onSelect={handleTargetSelect}
                                autoFocus={!/Mobi|Android/i.test(navigator.userAgent) || true}
                                placeholder="Eg. alice@sip2sip.info or 3333"
                            />
                            <Box className="form-group">
                                <Button
                                    aria-label="Start an audio call"
                                    title="Audio call"
                                    type="button"
                                    className={buttonClasses}
                                    disabled={targetUri.length === 0 || noConnection}
                                    onClick={handleAudioCall}
                                >
                                    <i className="fa fa-phone"></i>
                                </Button>
                                <Button
                                    aria-label="Start a video call"
                                    title="Video call"
                                    type="button"
                                    className={buttonClasses}
                                    disabled={targetUri.length === 0 || noConnection}
                                    onClick={handleVideoCall}
                                >
                                    <i className="fa fa-video-camera"></i>
                                </Button>
                                <Button
                                    aria-label="Start a chat"
                                    title="Chat"
                                    type="button"
                                    className={buttonClasses}
                                    disabled={targetUri.length === 0 || noConnection || targetUri.endsWith(`@${config.defaultConferenceDomain}`)}
                                    onClick={handleChat}
                                >
                                    <i className="fa fa-comments"></i>
                                </Button>
                                <Button
                                    aria-label="Join a video conference"
                                    title="Join video conference"
                                    type="button"
                                    className="btn btn-primary btn-round-big"
                                    disabled={noConnection}
                                    onClick={showConferenceModalFn}
                                >
                                    <i className="fa fa-users"></i>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="extra-shadow"></Box>
                    <HistoryTileBox>
                        {serverHistory
                            .filter(historyItem => historyItem.remoteParty.startsWith(targetUri))
                            .map((historyItem, idx) => (
                                <Grid item md={4} sm={6} xs={12} key={idx}>
                                    <InView threshold={0.01}>
                                        {({ inView, ref }) => (
                                            <Box
                                                ref={ref}
                                                className={inView ? 'card-visible animated bounceIn' : 'card-hidden'}
                                            >
                                                <HistoryCard
                                                    historyItem={historyItem}
                                                    setTargetUri={handleTargetChange}
                                                    startVideoCall={handleVideoCall}
                                                    startAudioCall={handleAudioCall}
                                                    startChat={handleChat}
                                                    noConnection={noConnection}
                                                />
                                            </Box>
                                        )}
                                    </InView>
                                </Grid>
                            ))}
                    </HistoryTileBox>
                    <FooterBox />
                </Box>
            </Box>
            <ConferenceModal
                show={showConferenceModal}
                handleConferenceCall={handleConferenceCall}
                conference={conference.current}
            />
        </div>
    );
};

ReadyBox.propTypes = {
    account: PropTypes.object.isRequired,
    startCall: PropTypes.func.isRequired,
    startConference: PropTypes.func.isRequired,
    startChat: PropTypes.func.isRequired,
    missedTargetUri: PropTypes.string,
    history: PropTypes.array,
    serverHistory: PropTypes.array,
    noConnection: PropTypes.bool
};

export default ReadyBox;
