import React from "react";
import { tokens } from "../../../../theme";
import {
  Box,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { callCenterData } from "../../../../data/mockData";
import { useEffect } from "react";
import { useState } from "react";

const CallHistory = ({ text, includeTable }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [zoom, setZoom] = useState("0.60");

  const boxItemStyle = {
    width: "100%",
    height: "35vh",
    margin: "5px",
    overflow: "auto",
    paddingRight:"10px",
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
      backgroundColor: colors.skyblueAccent[600],
      color: theme.palette.common.white,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    '&.MuiTableCell-body': {
      fontSize: 12,
    },
  }));


  const columns = [
    { id: "bound", label: "",},
    { id: "phoneNumber", label: "Phone Number", minWidth: 50 },
    { id: "dateTime", label: "Date & Time", minWidth: 50 },
    { id: "duration", label: "Duration", minWidth: 30 },
    { id: "disposition", label: "Disposition", minWidth: 50 },
    { id: "user", label: "User", minWidth: 50 },
  ];

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 600) {
        setZoom("0.58");
      } else if (screenWidth > 600 && screenWidth <= 1200) {
        setZoom("0.83");
      } else {
        setZoom("0.83");
      }
    };

    // Set initial zoom value
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={boxItemStyle}>
      <Box>
        <Box>
          {includeTable && (
            <div style={{ width: "100%", overflowX: "auto" , paddingLeft: 1}}>
              <TableContainer component={Paper} style={{ zoom: zoom }}>
                <Table  size="small" dense="true">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: colors.primary[901] }}>
                    {callCenterData.map((row) => (
                      <TableRow key={row.id}>
                        {columns.map((column) => (
                          <StyledTableCell key={column.id}>
                            {column.id === "disposition" ? (
                              <div
                                style={{
                                  color:
                                    row[column.id] === "answered"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {row[column.id]}
                              </div>
                            ) : (
                              row[column.id]
                            )}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default CallHistory;
