import React from "react";
import Navbar from '../Public/Navbar';
import Footer from '../Public/Footer';
import LoginForm from './Login';
import Authentication from "../auth/Authentication";
import { Box, Container, Typography } from '@mui/material';

const LoginPage = () => {
  return (
    <div>
      <Navbar/>
      
      {/* Page content for login form */}
      <Container
      component="main"
      maxWidth="xs"
      sx={{ marginTop: '64px' }} // Adjust the margin based on your navbar height
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="80vh" // Center the login form vertically
        >
          
          {/* LoginForm */}
          <LoginForm />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default LoginPage;
