import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Box, Button, Backdrop, Modal, Typography } from '@mui/material';

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

const RedialScreen = ({ router, hide }) => {
    const [progress, setProgress] = useState(0);

    const retryTime = router.getPath().startsWith('/conference') ? 180 : 60;
    const interval = 500;

    useInterval(() => {
        setProgress(prev => {
            const newProgress = prev + 0.5;
            if (newProgress >= retryTime) {
                handleHide();
                return prev;  // To stop further updates once max progress is reached
            }
            return newProgress;
        });
    }, interval);

    const handleHide = () => {
        hide();
        router.navigate('/ready');
    };

    return (
        <Backdrop open style={{ zIndex: 1200 }}>
            <Modal open style={{ display: 'block' }}>
                <Box className="loading">
                    <Box className="loading-inner">
                        <i className="fa fa-4x fa-spin fa-cog" />
                        <Typography variant="h4">Please wait...</Typography>
                        <Typography variant="body1" gutterBottom>
                            The connection has been lost. Attempting to resume the call.
                        </Typography>
                        <Box sx={{ maxWidth: '50%', margin: 'auto', marginTop: 2 }}>
                            <LinearProgress
                                sx={{ marginTop: 2 }}
                                variant="determinate"
                                value={progress * (100 / retryTime)}
                            />
                            <Button
                                sx={{ marginTop: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={handleHide}
                            >
                                Cancel resume
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Backdrop>
    );
};

RedialScreen.propTypes = {
    router: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired,
};

export default RedialScreen;
