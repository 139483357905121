import { BrowserRouter as Router } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react'; // Import useState, useEffect, and useRef
import ReactDOM from 'react-dom';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import adapter from 'webrtc-adapter';
import { createConnection, utils as sylkrtcUtils } from 'sylkrtc'; // Import createConnection and rename utils
import cloneDeep from 'lodash/cloneDeep';
import debug from 'debug';
import DigestAuthRequest from 'digest-auth-request';
import io from 'intersection-observer';
import RegisterBox from './components/RegisterBox';
import ReadyBox from './components/ReadyBox';
import Call from './components/Call';
import CallByUriBox from './components/CallByUriBox';
import CallCompleteBox from './components/CallCompleteBox';
import Chat from './components/Chat';
import Conference from './components/Conference';
import ConferenceByUriBox from './components/ConferenceByUriBox';
import AudioPlayer from './components/AudioPlayer';
import ErrorPanel from './components/ErrorPanel';
import FooterBox from './components/FooterBox';
import StatusBox from './components/StatusBox';
import IncomingCallModal from './components/IncomingCallModal';
import IncomingCallWindow from './components/IncomingCallWindow';
import NotificationCenter from './components/NotificationCenter';
import LoadingScreen from './components/LoadingScreen';
import RedialScreen from './components/RedialScreen';
import MessagesLoadingScreen from './components/MessagesLoadingScreen';
import NavigationBar from './components/NavigationBar';
import Preview from './components/Preview';
import ScreenSharingModal from './components/ScreenSharingModal';
import ShortcutsModal from './components/ShortcutsModal';
import EncryptionModal from './components/EncryptionModal';
import ImportModal from './components/ImportModal';
import NewDeviceModal from './components/NewDeviceModal';
import LogoutModal from './components/LogoutModal';
import utils from './utils';
import config from './config';
import storage from './storage';
import messageStorage from './messageStorage';
import keyStorage from './keyStorage';
import cacheStorage from './cacheStorage';
import history from './history';

const Locations = Router.Locations;
const Location = Router.Location;
const NotFound = Router.NotFound;

// attach debugger to the window for console access
window.blinkDebugger = debug;

const DEBUG = debug('blinkrtc:App');

// Application modes
const MODE_NORMAL = Symbol('mode-normal');
const MODE_PRIVATE = Symbol('mode-private');
const MODE_GUEST_CALL = Symbol('mode-guest-call');
const MODE_GUEST_CONFERENCE = Symbol('mode-guest-conference');


const Blink = () => {
    const [state, setState] = useState({
        accountId: '',
        password: '',
        displayName: '',
        account: null,
        registrationState: null,
        loading: null,
        currentCall: null,
        connection: null,
        contactCache: new Map(),
        oldMessages: {},
        inboundCall: null,
        showIncomingModal: false,
        showScreenSharingModal: false,
        showShortcutsModal: false,
        showImportModal: false,
        showEncryptionModal: false,
        showLogoutModal: false,
        status: null,
        targetUri: '',
        missedTargetUri: '',
        mode: MODE_PRIVATE,
        localMedia: null,
        generatedVideoTrack: false,
        history: [],
        serverHistory: [],
        devices: {},
        propagateKeyPress: false,
        showRedialScreen: false,
        resumeCall: false,
        messagesLoading: false,
        messagesLoadingProgress: false,
        importMessage: {},
        export: false,
        transmitKeys: false,
        showNewDeviceModal: false,
        enableMessaging: false,
        haveFocus: false,
        unreadMessages: 0,
        unreadCallMessages: 0,
        showChatInCall: false,
        storageLoadEmpty: false
    });

    const notificationCenterRef = useRef(null);
    const router = useRef();
    const audioPlayerInbound = useRef();
    const audioPlayerOutbound = useRef();
    const audioPlayerHangup = useRef();
    // Check if we should use hash routing
    const shouldUseHashRouting = typeof window.process !== 'undefined' && window.process.versions && window.process.versions.electron !== '';
    const connectionNotification = null;
    const entryPath = '/ready';
    const failureReason = '';
    const participantsToInvite = null;
    const resumeVideoCall = false;
    const retransmittedMessages = [];
    const isRetry = false;
    const isSyncing = false;
    const isSyncingConversations = false;
    const isSyncingMessages = false;
    const lastMessageFocus = null;
    const muteIncoming = false;
    const muteOutgoing = false;
    const showChat = false;
    const showChatInCall = false;
    const showShortcuts = false;
    const showShortcutsInCall = false;
    const showRedialScreen = false;
    const showRedialScreenInCall = false;
    const showCallComplete = false;
    const showCallCompleteInCall = false;
    const redirectTo = '';
    const prevPath = useRef(''); // Define prevPath using useRef
    const forceUpdate = () => setState(prevState => ({ ...prevState })); // Define forceUpdate
    const unreadTimer = null;

    const generatePGPKeys = () => {
        setState(prevState => ({ ...prevState, loading: 'Generating keys for PGP encryption' }));
        setImmediate(() => {
            state.account.generatePGPKeys((result) => {
                storage.set(`pgpKeys-${state.accountId}`, result);
                setState(prevState => ({ ...prevState, loading: null, transmitKeys: true }));
                // Additional logic for handling generated keys
            });
        });
    };

    const sendPublicKey = (uri) => {
        storage.get(`pgpKeys-${state.accountId}`).then(pgpKeys => {
            if (pgpKeys) {
                state.account.sendMessage(uri, pgpKeys.publicKey, 'text/pgp-public-key');
            }
        });
    };


    const checkRoute = (nextPath, navigation, match) => {
        if (nextPath !== prevPath.current) {
            DEBUG(`Transition from ${prevPath.current} to ${nextPath}`);
            prevPath.current = nextPath; // Update prevPath

            // Don't navigate if the app is not supported
            if (!window.RTCPeerConnection && nextPath !== '/not-supported') {
                router.current.navigate('/not-supported');
                forceUpdate();
                return false;
            }

            if (config.useServerCallHistory && nextPath === '/ready' && state.registrationState === 'registered') {
                getServerHistory();
            }
            // Press back in ready after a login, prevent initial navigation
            // don't deny if there is no registrationState (connection fail)
            if (prevPath.current === '/ready' && nextPath === '/login' && state.registrationState !== null) {
                DEBUG('Transition denied redirecting to /logout');
                router.current.navigate('/logout');
                return false;

                // Press back in ready after a call
            } else if ((nextPath === '/call' || nextPath === '/conference') && state.localMedia === null && state.registrationState === 'registered') {
                return false;

                // Press back from within a call/conference, don't navigate terminate the call and
                // let termination take care of navigating
            } else if (nextPath === '/ready' && state.registrationState === 'registered' && state.currentCall !== null) {
                state.currentCall.terminate();
                return false;

                // Guest call ended, needed to logout and display msg and logout
            } else if (nextPath === '/ready' && (state.mode === MODE_GUEST_CALL || state.mode === MODE_GUEST_CONFERENCE)) {
                router.current.navigate('/logout');
                forceUpdate();
            }

            // Preserve entry path
            if (nextPath === '/ready' || nextPath === '/chat') {
                entryPath = nextPath;
            }
        }
    };
    
    // useEffect for componentDidMount and componentWillMount logic
    useEffect(() => {

        storage.initialize(shouldUseHashRouting);

        if (window.location.hash.startsWith('#!/')) {
            const redirectTo = window.location.hash.replace('#!', '');
            setState(prevState => ({ ...prevState, redirectTo }));
        } else {
            // Disallowed routes, they will rendirect to /login
            const disallowedRoutes = new Set(['/', '/ready', '/call', '/chat', '/preview']);

            if (disallowedRoutes.has(window.location.pathname)) {
                setState(prevState => ({ ...prevState, redirectTo: '/login' }));
            }

            if (/^\/conference\/?$/g.test(window.location.pathname)) {
                const redirectTo = `/conference/${utils.generateSillyName()}`;
                setState(prevState => ({ ...prevState, redirectTo }));
            }

        }

        history.load().then((entries) => {
            if (entries) {
                setState(prevState => ({ ...prevState, history: entries }));
            }
        });

        // Load camera/mic preferences
        storage.get('devices').then((devices) => {
            if (devices) {
                setState(prevState => ({ ...prevState, devices }));
            }
        });

        // Load contact cache
        storage.get('contactCache').then((cache) => {
            if (cache) {
                setState(prevState => ({ ...prevState, contactCache: new Map(cache) }));
            }
        });

        return () => {
            // Cleanup logic if necessary
        };
    }, []); // Empty dependency array for componentDidMount

    useEffect(() => {
        if (!window.RTCPeerConnection) {
            setTimeout(() => {
                router.current.navigate('/not-supported');
            });
        }

        if (shouldUseHashRouting) {
            setTimeout(() => {
                router.current.navigate('/login');
            });
        }
        // prime the ref
        DEBUG('NotificationCenter ref: %o', notificationCenterRef.current);
        document.addEventListener('keydown', (event) => {
            if (!state.propagateKeyPress) {
                switch (event.key) {
                    case '?':
                        event.preventDefault();
                        toggleShortcutsModal();
                        break;
                    default:
                        break;
                }
            }
        });
    }, [state.propagateKeyPress]);



    const connectionStateChanged = (oldState, newState) => {
        DEBUG(`Connection state changed! ${oldState} -> ${newState}`);
        switch (newState) {
            case 'closed':
                setState(prevState => ({ ...prevState, connection: null, loading: null }));
                break;
            case 'ready':
                if (connectionNotification !== null) {
                    notificationCenterRef.current.removeNotification(connectionNotification);
                    connectionNotification = null;
                }
                if (state.showRedialScreen === true) {
                    toggleRedialScreen(true);
                }
                processRegistration(state.accountId, state.password, state.displayName);
                break;
            case 'disconnected':
                audioPlayerOutbound.current.stop();
                audioPlayerInbound.current.stop();

                if (state.localMedia) {
                    if (state.localMedia.getVideoTracks().length === 0) {
                        resumeVideoCall = false;
                    } else if (state.localMedia.getVideoTracks()[0].readyState === 'ended') {
                        resumeVideoCall = false;
                    }
                    sylkrtcUtils.closeMediaStream(state.localMedia);
                }

                if (state.currentCall) {
                    if (state.currentCall.direction === 'outgoing') {
                        toggleRedialScreen(false);
                    } else {
                        router.current.navigate(entryPath);
                    }
                    state.currentCall.removeListener('stateChanged', callStateChanged);
                    state.currentCall.terminate();
                }

                if (state.inboundCall && state.inboundCall !== state.currentCall) {
                    state.inboundCall.removeListener('stateChanged', inboundCallStateChanged);
                    state.inboundCall.terminate();
                }

                setState(prevState => ({
                    ...prevState,
                    registrationState: null,
                    showIncomingModal: false,
                    currentCall: null,
                    inboundCall: null,
                    localMedia: null,
                    generatedVideoTrack: false
                }));
                break;
            default:
                if (state.account === null) {
                    setState(prevState => ({ ...prevState, loading: 'Connecting...' }));
                } else {
                    if (!state.showRedialScreen) {
                        const reconnect = () => {
                            notificationCenterRef.current.toggleConnectionLostNotification(true, connectionNotification);
                            state.connection.reconnect();
                        };
                        if (connectionNotification === null) {
                            connectionNotification = notificationCenterRef.current.postConnectionLost(reconnect);
                        } else {
                            notificationCenterRef.current.toggleConnectionLostNotification(false, connectionNotification, reconnect);
                        }
                    }
                }
                break;
        }
    };

    const notificationCenter = () => {
        return notificationCenterRef.current;
    };

    const retransmitMessages = () => {
        let num = retransmittedMessages.length;
        if (num !== 0) {
            setState(prevState => ({ ...prevState, messagesLoading: true }));
        }
        for (let message of retransmittedMessages.reverse()) {
            if (message.state === 'pending' || message.state === 'failed') {
                DEBUG('Try to remove: %o', message.id);
                messageStorage.removeMessage(message).then(() => {
                    DEBUG('Message removed: %o', message.id);
                });
                DEBUG('Retransmitting: %o', message);
                let messagesLoading = true;
                let newMessage = state.account.sendMessage(message.receiver, message.content, message.contentType, { timestamp: message.timestamp }, () => {
                    const messagesCopy = cloneDeep(state.oldMessages)
                    const contact = message.receiver;
                    const newMessages = []
                    if (messagesCopy[contact]) {
                        for (let i = 0; i < messagesCopy[contact].length; i++) {
                            if (messagesCopy[contact][i].id !== message.id) {
                                newMessages.push(messagesCopy[contact][i]);
                            }
                        }
                        messagesCopy[contact] = newMessages;
                        num--;
                        if (num === 0) {
                            messagesLoading = false;
                        }
                        setState(prevState => ({ ...prevState, oldMessages: messagesCopy, messagesLoading }));
                    }
                });
            }
        }
    };


    const registrationStateChanged = (oldState, newState, data) => {
        DEBUG('Registration state changed! ' + newState);
        setState(prevState => ({ ...prevState, registrationState: newState }));
        const path = router.current.getPath();
        if (newState === 'failed') {
            if (path === '/login') {
                let reason = data.reason;
                if (reason.match(/904/)) {
                    // Sofia SIP: WAT
                    reason = 'Wrong account or password';
                } else {
                    reason = 'Connection failed';
                }
                setState(prevState => ({
                    ...prevState,
                    loading: null,
                    status: {
                        msg: 'Sign In failed: ' + reason,
                        level: 'danger'
                    }
                }));
            } else {
                setTimeout(() => {
                    state.account.register();
                }, 5000);
            }
        } else if (newState === 'registered') {
            if (path === '/login') {
                setState(prevState => ({ ...prevState, loading: null }));
            }
            messageStorage.initialize(state.accountId, storage.instance(), shouldUseHashRouting);
            keyStorage.initialize(state.accountId, storage.instance(), shouldUseHashRouting);
            cacheStorage.initialize(state.accountId, storage.instance(), shouldUseHashRouting);

            let { privateKey, publicKey, revocationCertificate } = '';

            if (state.enableMessaging) {
                DEBUG('Trying to re-enable messaging');
                enableMessaging(true);
            }
            storage.get(`pgpKeys-${state.accountId}`).then(pgpKeys => {
                if (pgpKeys) {
                    privateKey = pgpKeys.privateKey;
                    publicKey = pgpKeys.publicKey;
                } else {
                    return Promise.reject();
                }
                state.account.checkIfKeyExists((fetchedPublicKey) => {
                    fetchedPublicKey = fetchedPublicKey !== null ? fetchedPublicKey.trim() : null;
                    if (fetchedPublicKey !== publicKey.trim()) {
                        if (fetchedPublicKey == null) {
                            // We have a key, but the server has not
                            setState(prevState => ({ ...prevState, loading: null, transmitKeys: true }));

                            state.account.addPGPKeys({ publicKey, privateKey });
                            keyStorage.getAll().then(key =>
                                state.account.pgp.addPublicPGPKeys(key)
                            );
                            state.account.pgp.on('publicKeyAdded', (key) => {
                                keyStorage.add(key);
                            });
                            enableMessaging();
                            loadMessages();
                        } else {
                            // We have a key, but the server has a different key
                            setState(prevState => ({ ...prevState, showEncryptionModal: true, export: false }));
                        }
                    } else {
                        // Our key and the key on the server matches
                        state.account.addPGPKeys({ publicKey, privateKey });
                        keyStorage.getAll().then(key =>
                            state.account.pgp.addPublicPGPKeys(key)
                        );
                        state.account.pgp.on('publicKeyAdded', (key) => {
                            keyStorage.add(key);
                        });
                        enableMessaging();
                        loadMessages();
                    }
                });
            }).catch(() => {
                DEBUG('No keys found in storage');
                state.account.checkIfKeyExists((publicKey) => {
                    if (publicKey === null) {
                        // There is no key on the server and we have none
                        if (state.mode !== MODE_PRIVATE) {
                            setState(prevState => ({ ...prevState, loading: 'Generating keys for PGP encryption' }));
                            setImmediate(() => {
                                state.account.generatePGPKeys((result) => {
                                    storage.set(`pgpKeys-${state.accountId}`, result);
                                    setState(prevState => ({ ...prevState, loading: null, transmitKeys: true }));
                                    keyStorage.getAll().then(key =>
                                        state.account.pgp.addPublicPGPKeys(key)
                                    );
                                    state.account.pgp.on('publicKeyAdded', (key) => {
                                        keyStorage.add(key);
                                    });
                                    enableMessaging();
                                    loadMessages();
                                });
                            });
                        } else {
                            // messaging in private mode without encryption
                            setState(prevState => ({ ...prevState, loading: null }));
                            enableMessaging();
                            loadMessages();
                        }
                    } else {
                        setState(prevState => ({ ...prevState, showNewDeviceModal: true }));
                        // There is a key on the server and we have none
                    }
                });
            })
            if (path === '/login') {
                router.current.navigate('/ready');
                return;
            }
        } else {
            setState(prevState => ({ ...prevState, status: null }));
        }
    };

    const callStateChanged = (oldState, newState, data) => {
        DEBUG(`Call state changed! ${oldState} -> ${newState}`);

        switch (newState) {
            case 'progress':
                audioPlayerOutbound.current.play(true);
                break;
            case 'accepted':
                audioPlayerOutbound.current.stop();
            case 'established':
                audioPlayerOutbound.current.stop();
                audioPlayerInbound.current.stop();
                break;
            case 'terminated':
                audioPlayerOutbound.current.stop();
                audioPlayerInbound.current.stop();
                audioPlayerHangup.current.play();

                let callSuccesfull = false;
                let reason = data.reason;
                if (!reason || reason.match(/200/)) {
                    reason = 'Hangup';
                    callSuccesfull = true;
                } else if (reason.match(/403/)) {
                    reason = 'Forbidden';
                } else if (reason.match(/404/)) {
                    reason = 'User not found';
                } else if (reason.match(/408/)) {
                    reason = 'Timeout';
                } else if (reason.match(/480/)) {
                    reason = 'User not online';
                } else if (reason.match(/486/) || reason.match(/60[036]/)) {
                    reason = 'Busy';
                } else if (reason.match(/487/)) {
                    reason = 'Cancelled';
                } else if (reason.match(/488/)) {
                    reason = 'Unacceptable media';
                } else if (reason.match(/5\d\d/)) {
                    reason = 'Server failure';
                } else if (reason.match(/904/)) {
                    // Sofia SIP: WAT
                    reason = 'Bad account or password';
                } else {
                    reason = 'Connection failed';
                }
                notificationCenterRef.current.postSystemNotification('Call Terminated', { body: reason, timeout: callSuccesfull ? 5 : 10 });
                let resetTargetUri = callSuccesfull || config.useServerCallHistory;
                if (state.mode === MODE_GUEST_CALL || state.mode === MODE_GUEST_CONFERENCE) {
                    if (callSuccesfull === false) {
                        resetTargetUri = false;
                        failureReason = reason;
                    }
                    entryPath = '/ready';
                }
                setState(prevState => ({
                    ...prevState,
                    currentCall: null,
                    targetUri: resetTargetUri ? '' : state.targetUri,
                    showIncomingModal: false,
                    inboundCall: null,
                    localMedia: null,
                    generatedVideoTrack: false,
                    previousTargetUri: state.targetUri,
                    showChatInCall: false
                }));
                setFocusEvents(false);
                participantsToInvite = null;
                router.current.navigate(entryPath);
                break;
            default:
                break;
        }
    };

    const inboundCallStateChanged = (oldState, newState, data) => {
        DEBUG('Inbound Call state changed! ' + newState);
        if (newState === 'terminated') {
            setState(prevState => ({ ...prevState, inboundCall: null, showIncomingModal: false }));
            setFocusEvents(false);
        }
    };

    const connect = () => {
        setState(prevState => ({ ...prevState, loading: 'Connecting...' }));
        const connection = createConnection({
            server: config.wsServer,
            userAgent: {
                name: `Sylk${shouldUseHashRouting ? 'App' : 'Web'}`,
                version: 'PACKAGE_VERSION'
            }
        });
        connection.on('stateChanged', connectionStateChanged);
        setState(prevState => ({ ...prevState, connection }));
    };

    const handleCallByUri = (displayName, targetUri) => {
        const accountId = `${utils.generateUniqueId()}@${config.defaultGuestDomain}`;
        setState(prevState => ({
            ...prevState,
            accountId,
            password: '',
            displayName,
            mode: MODE_GUEST_CALL,
            targetUri: utils.normalizeUri(targetUri, config.defaultDomain),
            loading: 'Connecting...'
        }));

        if (state.connection === null) {
            connect();
        } else {
            DEBUG('Connection Present, try to register');
            processRegistration(accountId, '', displayName);
        }
    };

    const handleConferenceByUri = (displayName, targetUri, extraOptions = {}) => {
        const accountId = `${utils.generateUniqueId()}@${config.defaultGuestDomain}`;
        const mediaConstraints = extraOptions.mediaConstraints || { audio: true, video: true };
        const lowBandwidth = extraOptions.lowBandwidth || false;
        setState(prevState => ({
            ...prevState,
            accountId,
            password: '',
            displayName,
            mode: MODE_GUEST_CONFERENCE,
            targetUri,
            loading: 'Connecting...',
            lowBandwidth,
            preferredGuestMedia: mediaConstraints
        }));

        if (state.connection === null) {
            connect();
        } else {
            DEBUG('Connection Present, try to register');
            processRegistration(accountId, '', displayName);
        }
    };

    const handleRetry = () => {
        const accountId = `${utils.generateUniqueId()}@${config.defaultGuestDomain}`;
        isRetry = true;
        setState(prevState => ({
            ...prevState,
            accountId,
            password: '',
            loading: 'Connecting...',
            targetUri: state.targetUri || state.previousTargetUri
        }));

        if (state.connection === null) {
            connect();
        } else {
            DEBUG('Connection Present, try to register');
            processRegistration(accountId, '', state.displayName);
        }
    };

    const handleRegistration = (accountId, password, remember) => {
        // Needed for ready event in connection
        remember = shouldUseHashRouting ? true : remember;
        setState(prevState => ({
            ...prevState,
            accountId,
            password,
            mode: remember ? MODE_NORMAL : MODE_PRIVATE,
            loading: 'Connecting...'
        }));

        if (state.connection === null) {
            connect();
        } else {
            DEBUG('Connection Present, try to register');
            processRegistration(accountId, password, '');
        }
    };

    function processRegistration(accountId, password, displayName) {
        let pendingFailedMessages = [];
        let retransmittedMessages = [];
        let oldMessages = {};
        if (state.account !== null) {
            DEBUG('We already have an account, removing it');
            // Preserve messages from account
            if (accountId === state.accountId) {
                oldMessages = cloneDeep(state.oldMessages);
                const messages = state.account.messages;
                let index = 0;
                for (let message of messages.reverse()) {
                    if (message.state === 'pending' || message.state === 'failed') {
                        index = index + 1;
                        pendingFailedMessages.push(message);
                    } else {
                        break;
                    }
                }
                retransmittedMessages = pendingFailedMessages;
                let counter = 0;
                for (let message of state.account.messages) {
                    const senderUri = message.sender.uri;
                    const receiver = message.receiver;
                    let key = receiver;
                    if (message.state === 'received') {
                        key = senderUri;
                    }
                    if (!oldMessages[key]) {
                        oldMessages[key] = [];
                    }
                    if (!oldMessages[key].find(loadedMessage => loadedMessage.id === message.id)) {
                        oldMessages[key].push(message);
                        counter += 1;
                    }
                };
                DEBUG('Old messages to save: %s', counter);
                DEBUG('Messages to send again: %s', pendingFailedMessages.length);
            }
            try {
                state.connection.removeAccount(state.account,
                    (error) => {
                        if (error) {
                            DEBUG(error);
                        }
                        setState(prevState => ({ ...prevState, account: null, registrationState: null }));
                    }
                );
            }
            catch (error) {
                setState(prevState => ({ ...prevState, registrationState: null }));
            }
            setState(prevState => ({ ...prevState, serverHistory: [] }));
            getServerHistory();
        }

        const options = {
            account: accountId,
            password: password,
            displayName: displayName,
            ha1: true
        };

        if (accountId.indexOf('@') !== -1) {
            const [usename, domain] = accountId.split('@');
            if (config.nonSipDomains.indexOf(domain) !== -1) {
                options.ha1 = false;
            }
        }

        try {
            const account = state.connection.addAccount(options, (error, account) => {
                if (!error) {
                    account.on('outgoingCall', outgoingCall);
                    account.on('conferenceCall', outgoingCall);
                    if (state.resumeCall === true) {
                        resumeCall();
                        setState(prevState => ({ ...prevState, resumeCall: false }));
                    }
                    switch (state.mode) {
                        case MODE_PRIVATE:
                        case MODE_NORMAL:
                            account.on('registrationStateChanged', registrationStateChanged);
                            account.on('incomingCall', incomingCall);
                            account.on('missedCall', missedCall);
                            account.on('conferenceInvite', conferenceInvite);

                            // Messaging handlers
                            account.on('processingFetchedMessages', (progress) => {
                                const newState = {};
                                if (!state.messagesLoading) {
                                    newState.messagesLoading = true;
                                }
                                if (progress) {
                                    newState.messagesLoadingProgress = progress;
                                }
                                setState(prevState => ({ ...prevState, ...newState }));
                            });
                            account.on('outgoingMessage', outgoingMessage);
                            setState(prevState => ({ ...prevState, account: account, oldMessages: oldMessages }));
                            state.account.register();
                            if (state.mode !== MODE_PRIVATE) {
                                if (shouldUseHashRouting) {
                                    storage.set('account', { accountId: state.accountId, password: state.password });
                                } else {
                                    storage.get('account').then((account) => {
                                        if (account && account.accountId !== state.accountId) {
                                            history.clear().then(() => {
                                                setState(prevState => ({ ...prevState, history: [] }));
                                            });
                                        }
                                    });
                                    storage.set('account', { accountId: state.accountId, password: '' });
                                }
                            } else {
                                // Wipe storage if private login
                                storage.remove('account');
                                history.clear().then(() => {
                                    setState(prevState => ({ ...prevState, history: [] }));
                                });
                            }
                            break;
                        case MODE_GUEST_CALL:
                            setState(prevState => ({ ...prevState, account: account, loading: null, registrationState: 'registered' }));
                            DEBUG(`${accountId} (guest) signed in`);
                            // Start the call immediately, this is call started with "Call by URI"
                            startGuestCall(state.targetUri, { audio: true, video: true });
                            break;
                        case MODE_GUEST_CONFERENCE:
                            setState(prevState => ({ ...prevState, account: account, loading: null, registrationState: 'registered' }));
                            DEBUG(`${accountId} (conference guest) signed in`);
                            // Start the call immediately, this is call started with "Conference by URI"
                            startGuestConference(state.targetUri);
                            break;
                        default:
                            DEBUG(`Unknown mode: ${state.mode}`);
                            break;
                    }
                } else {
                    DEBUG('Add account error: ' + error);
                    setState(prevState => ({ ...prevState, loading: null, status: { msg: error.message, level: 'danger' } }));
                }
            });
        } catch (error) {
            DEBUG('Add account error: ' + error);
        }
    }

    const setDevice = (device) => {
        const oldDevices = Object.assign({}, state.devices);

        if (device.kind === 'videoinput') {
            oldDevices['camera'] = device;
        } else if (device.kind === 'audioinput') {
            oldDevices['mic'] = device;
        }

        setState(prevState => ({ ...prevState, devices: oldDevices }));
        storage.set('devices', oldDevices);
        const path = router.current.getPath();
        if (path === '/preview') {
            sylkrtcUtils.closeMediaStream(state.localMedia);
            getLocalMedia();
        }
    }

    const getLocalScreen = (source) => {
        let screenConstraints = {
            video: {
                mozMediaSource: 'window',
                mediaSource: 'window'
            }
        };

        if (shouldUseHashRouting) {
            screenConstraints = {
                audio: false,
                video: {
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: source
                    }
                }
            };
            DEBUG('This is electron, modifying constraints %o', screenConstraints);
            toggleScreenSharingModal();
        }

        if (!shouldUseHashRouting && navigator.getDisplayMedia) {
            navigator.getDisplayMedia({
                video: true
            }).then(screenStream => {
                state.currentCall.startScreensharing(screenStream.getVideoTracks()[0]);
                screenStream.getVideoTracks()[0].addEventListener('ended', (ev) => {
                    DEBUG('Screensharing stream ended by user action');
                    switchScreensharing();
                });
            }).catch((error) => {
                DEBUG('Error getting screen %o', error);
            });
        } else if (!shouldUseHashRouting && navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia({
                video: true
            }).then(screenStream => {
                state.currentCall.startScreensharing(screenStream.getVideoTracks()[0]);
                screenStream.getVideoTracks()[0].addEventListener('ended', (ev) => {
                    DEBUG('Screensharing stream ended by user action');
                    switchScreensharing();
                });
            }).catch((error) => {
                DEBUG('Error getting screen %o', error);
            });
        } else {
            DEBUG('Modern Screensharing API not available using getUserMedia');
            navigator.mediaDevices.getUserMedia(screenConstraints)
                .then((screenStream) => {
                    state.currentCall.startScreensharing(screenStream.getVideoTracks()[0]);
                    if (shouldUseHashRouting) {
                        const ipcRenderer = window.require('electron').ipcRenderer;
                        ipcRenderer.send('minimize');
                    }
                    screenStream.getVideoTracks()[0].addEventListener('ended', (ev) => {
                        DEBUG('Screensharing stream ended by user action');
                        switchScreensharing();
                    });
                }).catch((error) => {
                    DEBUG('Error getting screen %o', error);
                });
        }
    }

    const getLocalMediaGuestWrapper = (mediaConstraints = { audio: true, video: true }, nextRoute = null) => {    // eslint-disable-line space-infix-ops
        setState(prevState => ({ ...prevState, mode: MODE_GUEST_CONFERENCE }));
        setImmediate(() => getLocalMedia(mediaConstraints, nextRoute));
    }

    const getLocalMedia = (mediaConstraints = { audio: true, video: true }, nextRoute = null) => {    // eslint-disable-line space-infix-ops
        DEBUG('getLocalMedia(), mediaConstraints=%o', mediaConstraints);
        const constraints = Object.assign({}, mediaConstraints);

        const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;

        if (constraints.video === true) {
            if ((nextRoute === '/conference' || state.mode === MODE_GUEST_CONFERENCE) && navigator.userAgent.indexOf('Firefox') > 0) {
                constraints.video = {
                    'width': {
                        'ideal': 640
                    },
                    'height': {
                        'ideal': 480
                    }
                };

                // TODO: remove this, workaround so at least safari works wehn joining a video conference
            } else if ((nextRoute === '/conference' || state.mode === MODE_GUEST_CONFERENCE) && isSafari) {
                constraints.video = false;
            } else {
                // ask for 720p video
                constraints.video = {
                    'width': {
                        'ideal': 1280
                    },
                    'height': {
                        'ideal': 720
                    }
                };
            }
        }

        DEBUG('getLocalMedia(), (modified) mediaConstraints=%o', constraints);

        const loadScreenTimer = setTimeout(() => {
            setState(prevState => ({ ...prevState, loading: 'Please allow access to your media devices' }));
        }, 750);


        new Promise((resolve, reject) => {
            if (isSafari) {
                return navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream) => {
                        sylkrtcUtils.closeMediaStream(stream);
                        resolve();
                    }).catch((error) => {
                        DEBUG('Intial access failed: %o', error);
                        resolve();
                    });
            }
            resolve();
        })
            .then(() => {
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devices) => {
                devices.forEach((device) => {
                    if ('video' in constraints && 'camera' in state.devices) {
                        if (constraints.video !== false && (device.deviceId === state.devices.camera.deviceId || device.label === state.devices.camera.label)) {
                            constraints.video.deviceId = {
                                exact: device.deviceId
                            };
                        }
                    }
                    if ('mic' in state.devices) {
                        if (device.deviceId === state.devices.mic.deviceId || device.label === state.devices.mic.Label) {
                            constraints.audio = {
                                deviceId: {
                                    exact: device.deviceId
                                }
                            };
                        }
                    }
                });
            })
            .catch((error) => {
                DEBUG('Device enumeration failed: %o', error);
            })
            .then(() => {
                return navigator.mediaDevices.getUserMedia(constraints)
            })
            .then((localStream) => {
                clearTimeout(loadScreenTimer);
                setState(prevState => ({ ...prevState, status: null, loading: null, localMedia: localStream }));

                if (nextRoute !== null) {
                    router.current.navigate(nextRoute);
                }
            })
            .catch((error) => {
                DEBUG('Access failed, trying audio only: %o', error);
                navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false
                })
                    .then((localStream) => {
                        clearTimeout(loadScreenTimer);

                        if (nextRoute != '/preview') {
                            DEBUG('Audio only media, but video was requested, creating generated video track');
                            const generatedVideoTrack = utils.generateVideoTrack(localStream);
                            localStream.addTrack(generatedVideoTrack);
                        }

                        setState(prevState => ({ ...prevState, status: null, loading: null, localMedia: localStream, generatedVideoTrack: true }));
                        if (nextRoute !== null) {
                            router.current.navigate(nextRoute);
                        }
                    })
                    .catch((error) => {
                        DEBUG('Access to local media failed: %o', error);
                        clearTimeout(loadScreenTimer);
                        notificationCenterRef.current.postSystemNotification("Can't access camera or microphone", { timeout: 10 });
                        setState(prevState => ({ ...prevState, loading: null }));
                    });
            });
    }

    const startCall = (targetUri, options) => {
        setState(prevState => ({ ...prevState, targetUri: targetUri }));
        addCallHistoryEntry(targetUri);
        getLocalMedia(Object.assign({ audio: true, video: true }, options), '/call');
    }

    const startGuestCall = (targetUri, options) => {
        setState(prevState => ({ ...prevState, targetUri: targetUri }));
        if (isRetry) {
            getLocalMedia({ audio: true, video: true }, `/call/${targetUri}`);
        } else {
            // this.getLocalMedia(Object.assign({audio: true, video: true}, options));
        }
    }

    const switchScreensharing = () => {
        if (!state.currentCall.sharingScreen) {
            if (shouldUseHashRouting) {
                toggleScreenSharingModal();
            } else {
                getLocalScreen();
            }
        } else {
            state.currentCall.stopScreensharing();
        }
    }

    const toggleScreenSharingModal = () => {
        setState(prevState => ({ ...prevState, showScreenSharingModal: !prevState.showScreenSharingModal }));
    }

    const toggleShortcutsModal = () => {
        setState(prevState => ({ ...prevState, showShortcutsModal: !prevState.showShortcutsModal }));
    }

    const toggleEncryptionModal = () => {
        setState(prevState => ({ ...prevState, showEncryptionModal: !prevState.showEncryptionModal, export: prevState.showEncryptionModal && false }));
    }

    const toggleImportModal = () => {
        setState(prevState => ({ ...prevState, showImportModal: !prevState.showImportModal }));
    }

    const toggleNewDeviceModal = () => {
        setState(prevState => ({ ...prevState, showNewDeviceModal: !prevState.showNewDeviceModal }));
    }

    const toggleLogoutModal = () => {
        setState(prevState => ({ ...prevState, showLogoutModal: !prevState.showLogoutModal }));
    }

    const toggleRedialScreen = (resume = false) => {
        let nextState = !state.showRedialScreen;
        setState(prevState => ({ ...prevState, showRedialScreen: nextState, resumeCall: resume }));
        if (state.connection.state !== 'ready' && nextState === false && (state.mode === MODE_NORMAL || state.mode === MODE_PRIVATE)) {
            const reconnect = () => {
                notificationCenterRef.current.toggleConnectionLostNotification(true, connectionNotification);
                state.connection.reconnect();
            };
            if (connectionNotification === null) {
                connectionNotification = notificationCenterRef.current.postConnectionLost(reconnect);
            }
        }
    }

    const togglePropagateKeyPress = () => {
        setState(prevState => ({ ...prevState, propagateKeyPress: !prevState.propagateKeyPress }));
    }

    const toggleChatInCall = () => {
        const path = router.current.getPath();
        const domain = state.currentCall && state.currentCall.remoteIdentity.uri.substring(state.currentCall.remoteIdentity.uri.indexOf('@') + 1) || '';
        if (path !== '/conference' && !domain.startsWith('guest') && lastMessageFocus === '') {
            lastMessageFocus = state.currentCall.remoteIdentity.uri;
        }
        setState(prevState => ({ ...prevState, showChatInCall: !prevState.showChatInCall }));
    }

    const toggleChatInConference = () => {
        setState(prevState => ({ ...prevState, showChatInCall: !prevState.showChatInCall }));
    }

    const resumeCall = () => {
        if (state.targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            startConference(state.targetUri, {
                mediaConstraints: { audio: true, video: resumeVideoCall },
                roomMedia: state.roomMedia,
                lowBandwidth: state.lowBandwidth
            });
        } else {
            startCall(state.targetUri, { audio: true, video: resumeVideoCall });
            resumeVideoCall = true;
        }
    }

    const answerCall = (options) => {
        setState(prevState => ({ ...prevState, showIncomingModal: false, loading: null }));
        audioPlayerInbound.current.stop();
        setFocusEvents(false);
        if (state.inboundCall !== state.currentCall) {
            // terminate current call to switch to incoming one
            state.inboundCall.removeListener('stateChanged', inboundCallStateChanged);
            state.currentCall.removeListener('stateChanged', callStateChanged);
            state.currentCall.terminate();
            setState(prevState => ({ ...prevState, currentCall: prevState.inboundCall, inboundCall: prevState.inboundCall, localMedia: null }));
            state.inboundCall.on('stateChanged', callStateChanged);
        }
        getLocalMedia(Object.assign({ audio: true, video: true }, options), '/call');
    }

    const rejectCall = () => {
        setState(prevState => ({ ...prevState, showIncomingModal: false, loading: null }));
        state.inboundCall.terminate();
    }

    const hangupCall = () => {
        if (state.currentCall !== null) {
            state.currentCall.terminate();
        } else {
            // We have no call but we still want to cancel
            if (state.localMedia !== null) {
                sylkrtcUtils.closeMediaStream(state.localMedia);
            }
            router.current.navigate(entryPath);
        }
    }

    const escalateToConference = (participants) => {
        state.currentCall.removeListener('stateChanged', callStateChanged);
        state.currentCall.terminate();
        router.current.navigate('/ready');
        setState(prevState => ({ ...prevState, currentCall: null, localMedia: null }));
        participantsToInvite = participants;
        const uri = `${utils.generateSillyName()}@${config.defaultConferenceDomain}`;
        startConference(uri);
    }

    const startConference = (targetUri, extraOptions = {}) => {
        const mediaConstraints = extraOptions.mediaConstraints || { audio: true, video: true };
        const roomMedia = extraOptions.roomMedia || { audio: true, video: true };
        const lowBandwidth = extraOptions.lowBandwidth || false;
        setState(prevState => ({ ...prevState, targetUri: targetUri, roomMedia: roomMedia, lowBandwidth: lowBandwidth }));
        toggleNewDeviceModal();
        getLocalMedia(mediaConstraints, '/conference');
    }

    const startGuestConference = (targetUri) => {
        setState(prevState => ({ ...prevState, targetUri: targetUri }));
        if (isRetry) {
            getLocalMedia(state.preferredGuestMedia, `/conference/${targetUri}`);
        } else {
            // this.getLocalMedia(this.state.preferredGuestMedia);
        }
    }

    const toggleMute = () => {
        muteIncoming = !muteIncoming;
    }

    const outgoingCall = (call) => {
        call.on('stateChanged', callStateChanged);
        setState(prevState => ({ ...prevState, currentCall: call }));
    }

    const incomingCall = (call, mediaTypes) => {
        DEBUG('New incoming call from %o with %o', call.remoteIdentity, mediaTypes);
        if (!mediaTypes.audio && !mediaTypes.video) {
            call.terminate();
            return;
        }
        call.mediaTypes = mediaTypes;
        if (state.currentCall !== null) {
            // detect if we called ourselves
            if (state.currentCall.localIdentity.uri === call.remoteIdentity.uri) {
                DEBUG('Aborting call to myself');
                call.terminate();
                return;
            }
            setState(prevState => ({ ...prevState, showIncomingModal: true, inboundCall: call }));
            setFocusEvents(true);
            call.on('stateChanged', inboundCallStateChanged);
        } else {
            if (!muteIncoming) {
                audioPlayerInbound.current.play(true);
            }
            setFocusEvents(true);
            call.on('stateChanged', callStateChanged);
            setState(prevState => ({ ...prevState, currentCall: call, inboundCall: call, showIncomingModal: true }));
        }
        if (!shouldUseHashRouting) {
            notificationCenterRef.current.postSystemNotification('Incoming call', { body: `From ${call.remoteIdentity.displayName || call.remoteIdentity.uri}`, timeout: 15, silent: false });
        }
    }

    const setFocusEvents = (enabled) => {
        if (shouldUseHashRouting) {
            const remote = window.require('electron').remote;
            if (enabled) {
                const currentWindow = remote.getCurrentWindow();
                currentWindow.on('focus', hasFocus);
                currentWindow.on('blur', hasNoFocus);
                setState(prevState => ({ ...prevState, haveFocus: currentWindow.isFocused() }));
            } else {
                const currentWindow = remote.getCurrentWindow();
                currentWindow.removeListener('focus', hasFocus);
                currentWindow.removeListener('blur', hasNoFocus);
            }
        }
    }

    const hasFocus = () => {
        setState(prevState => ({ ...prevState, haveFocus: true }));
    }

    const hasNoFocus = () => {
        setState(prevState => ({ ...prevState, haveFocus: false }));
    }

    const missedCall = (data) => {
        DEBUG('Missed call from ' + data.originator);
        notificationCenterRef.current.postSystemNotification('Missed call', { body: `From ${data.originator.displayName || data.originator.uri}`, timeout: 15, silent: false });
        if (state.currentCall !== null || !config.useServerCallHistory) {
            notificationCenterRef.current.postMissedCall(data.originator, () => {
                if (state.currentCall !== null) {
                    state.currentCall.removeListener('stateChanged', callStateChanged);
                    state.currentCall.terminate();
                    setState(prevState => ({ ...prevState, currentCall: null, missedTargetUri: data.originator.uri, showIncomingModal: false, localMedia: null }));
                } else {
                    setState(prevState => ({ ...prevState, missedTargetUri: data.originator.uri }));
                }
                router.current.navigate('/ready');
            });
        } else {
            getServerHistory();
        }
    }

    const importKey = (message) => {
        let { privateKey, publicKey, revocationCertificate } = '';

        const regexp = /(?<publicKey>-----BEGIN PGP PUBLIC KEY BLOCK-----[^]*-----END PGP PUBLIC KEY BLOCK-----)[^]*(?<privateKey>-----BEGIN PGP PRIVATE KEY BLOCK-----[^]*-----END PGP PRIVATE KEY BLOCK-----)/gi;

        DEBUG(regexp)
        let match = regexp.exec(message.content);
        do {
            if (match === null) {
                return;
            }
            publicKey = match.groups.publicKey;
            privateKey = match.groups.privateKey;
            if (privateKey === '' || publicKey === '') {
                DEBUG('Import failed');
                return;
            }
        } while ((match = regexp.exec(message.content)) !== null);

        storage.get(`pgpKeys-${state.accountId}`).then(storedKeys => {
            if (storedKeys && publicKey === storedKeys.publicKey) {
                DEBUG('Imported key(s) are the same, skipping');
                return;
            }
            if (state.mode !== MODE_PRIVATE) {
                storage.set(`pgpKeys-${state.accountId}`, { publicKey, privateKey });
            }
            state.account.addPGPKeys({ publicKey, privateKey });

            keyStorage.getAll().then(key =>
                state.account.pgp.addPublicPGPKeys(key)
            );
            state.account.pgp.on('publicKeyAdded', (key) => {
                keyStorage.add(key);
            });
            setState(prevState => ({ ...prevState, showEncryptionModal: false, export: false }));
            enableMessaging();
            loadMessages();
        });
    }

    const useExistingKey = (password) => {
        storage.get(`pgpKeys-${state.accountId}`).then(pgpKeys => {
            if (pgpKeys !== null && pgpKeys.publicKey && pgpKeys.privateKey) {
                state.account.addPGPKeys(pgpKeys);
                state.account.exportPrivateKey(password);

                setState(prevState => ({ ...prevState, transmitKeys: true }));
                keyStorage.getAll().then(key =>
                    state.account.pgp.addPublicPGPKeys(key)
                );
                state.account.pgp.on('publicKeyAdded', (key) => {
                    keyStorage.add(key);
                });
                enableMessaging();
                loadMessages();
            }
        });
    }

    const enableMessaging = (reenable = false) => {
        if (!state.enableMessaging || reenable) {
            DEBUG('Enable message events');
            setState(prevState => ({ ...prevState, enableMessaging: true }));
            // Add message events
            state.account.on('incomingMessage', incomingMessage);
            state.account.on('sendingMessage', sendingMessage);
            state.account.on('sendingDispositionNotification', sendingDispositionNotification);
            state.account.on('messageStateChanged', messageStateChanged);
            state.account.on('syncConversations', syncConversations);
            state.account.on('readConversation', readConversation);
            state.account.on('removeConversation', removeConversation);
            state.account.on('removeMessage', removeMessage);
        }
    }

    const loadMessages = () => {
        setState(prevState => ({ ...prevState, messagesLoading: true }));
        messageStorage.loadLastMessages().then((cache) => {
            setState(prevState => ({ ...prevState, oldMessages: cache }));
            storage.get(`lastMessageId-${state.accountId}`).then(id =>
                state.account.syncConversations(id, (error) => {
                    if (error) {
                        retransmitMessages();
                    }
                })
            );
        });
    }

    const incomingMessage = (message) => {
        DEBUG('Incoming Message from: %s', message.sender.uri);
        if (retransmittedMessages.findIndex(m => message.id === m.id) !== -1) {
            DEBUG('Message was previously removed, not adding: %s', message.id);
            return;
        }

        storage.set(`lastMessageId-${state.accountId}`, message.id);
        messageStorage.add(message);

        if (message.sender.displayName !== null
            && (!state.contactCache.has(message.sender.uri)
                || (state.contactCache.has(message.sender.uri)
                    && state.contactCache.get(message.sender.uri) !== message.sender.displayName)
            )
        ) {
            DEBUG('Updating contact cache');
            const oldContactCache = new Map(state.contactCache);
            oldContactCache.set(message.sender.uri, message.sender.displayName)
            setState(prevState => ({ ...prevState, contactCache: oldContactCache }));
            storage.set('contactCache', Array.from(oldContactCache));
        }
        const path = router.current.getPath();
        if (path !== '/chat') {
            if (state.currentCall === null) {
                notificationCenterRef.current.postNewMessage(message, () => {
                    lastMessageFocus = message.sender.uri;
                    router.current.navigate('/chat');
                });
            }
        }
        if (shouldUseHashRouting) {
            const remote = window.require('electron').remote;
            const currentWindow = remote.getCurrentWindow();
            if (!currentWindow.isFocused()) {
                notificationCenterRef.current.postSystemNotification('New message',
                    {
                        body: `From ${message.sender.displayName || message.sender.uri}`,
                        timeout: 15,
                        silent: false
                    }
                );
            }
        }

        setTimeout(() => {
            calculateUnreadMessages(state.oldMessages);
        }, 2000);
    }

    const outgoingMessage = (message) => {
        if (message.contentType === 'text/pgp-private-key') {
            DEBUG('Starting key import');

            const regexp = /(?<publicKey>-----BEGIN PGP PUBLIC KEY BLOCK-----[^]*-----END PGP PUBLIC KEY BLOCK-----)/ig;
            let publicKey = null
            let match = regexp.exec(message.content);
            do {
                if (match) {
                    publicKey = match.groups.publicKey;
                }
            } while ((match = regexp.exec(message.content)) !== null);

            if (publicKey !== null) {
                storage.get(`pgpKeys-${state.accountId}`).then(pgpKeys => {
                    if (pgpKeys && publicKey === pgpKeys.publicKey.trim()) {
                        DEBUG('Public keys are the same, aborting');
                        return;
                    }
                    if (state.showNewDeviceModal) {
                        toggleNewDeviceModal()
                    }
                    setState(prevState => ({ ...prevState, importMessage: message, showImportModal: true }));
                });
            } else {
                if (state.showNewDeviceModal) {
                    toggleNewDeviceModal()
                }
                setState(prevState => ({ ...prevState, importMessage: message, showImportModal: true }));
            }
        }
    }

    const sendingMessage = (message) => {
        if (message.contentType !== 'text/pgp-private-key') {
            messageStorage.add(message);
        }
    }

    const removeMessage = (message) => {
        messageStorage.removeMessage(message).then(() => {
            DEBUG('Message removed: %o', message.id);
        });
        if (message.contentType == ('application/sylk-file-transfer')) {
            cacheStorage.remove(message.id)
                .then(() => {
                    DEBUG('File removed: %s', message.id);
                    return cacheStorage.remove(`thumb_${message.id}`)
                })
                .catch(() => {
                    DEBUG('File not removed for %s', message.id)
                })
                .then(() => {
                    DEBUG('Thumbnail removed: %s', message.id);
                })
                .catch(() => {
                    DEBUG('Thumbnail not removed for %s', message.id)
                })
        }
        let oldMessages = cloneDeep(state.oldMessages);
        let contact = message.receiver;
        if (message.state === 'received') {
            contact = message.sender.uri;
        }
        state.account.removeMessage(message);
        if (oldMessages[contact]) {
            oldMessages[contact] = oldMessages[contact].filter(loadedMessage => loadedMessage.id !== message.id);
        }
        setState(prevState => ({ ...prevState, oldMessages: oldMessages }));
    };

    function messageStateChanged(id, state, data, fromSync = false) {
        DEBUG('Message state changed: %o', id);
        if (state.importMessage && state.importMessage.id === id) {
            DEBUG('Skipping state update for importKeyMessage: %o', id);
            return;
        }

        messageStorage.update({ messageId: id, state });
        let found = false;
        if (state === 'accepted' && !fromSync) {
            storage.set(`lastMessageId-${state.accountId}`, id);
        }
        const oldMessages = cloneDeep(state.oldMessages);
        for (const [key, messages] of Object.entries(oldMessages)) {
            const newMessages = messages.map(loadedMessage => { // Removed cloneDeep for performance
                if (id === loadedMessage.id && state !== loadedMessage.state && loadedMessage.state !== 'displayed') { // Fixed comparison operator
                    loadedMessage.state = state;
                    found = true;
                    DEBUG('Updating state for loaded messages');
                }
                return loadedMessage;
            });
            if (found) {
                oldMessages[key] = newMessages;
                setState(prevState => ({ ...prevState, oldMessages: oldMessages })); // Simplified state update
                break;
            }
        }
    }

    function sendingDispositionNotification(id, state, error) {
        if (!error) {
            messageStorage.updateDisposition(id, state);
            let found = false;
            const oldMessages = cloneDeep(state.oldMessages);
            for (const [key, messages] of Object.entries(oldMessages)) {
                const newMessages = cloneDeep(messages).map(message => {
                    if (!(message instanceof require('events').EventEmitter)
                        && message.id === id && message.dispositionState !== state) {
                        message.dispositionState = state;
                        found = true;
                        DEBUG('Updating dispositionState for loaded messages');
                    }
                    return message;
                });
                if (found) {
                    oldMessages[key] = newMessages;
                    setState(prevState => ({ ...prevState, oldMessages: oldMessages }));
                    break;
                }
            };
            if (state !== 'delivered') {
                calculateUnreadMessages(oldMessages);
            }
        }
    }

    function calculateUnreadMessages(messages) {
        if (unreadTimer !== null) {
            clearTimeout(unreadTimer);
        }
        unreadTimer = setTimeout(() => {
            let counter = 0;
            for (let key of Object.keys(messages)) {
                for (let message of messages[key]) {
                    if (message.state === 'received'
                        && message.dispositionState !== 'displayed'
                        && message.dispositionNotification.indexOf('display') !== -1
                        && !message.content.startsWith('?OTRv')
                    ) {
                        counter++;
                    }
                }
            }
            for (let message of state.account.messages) {
                if (message.state === 'received'
                    && message.dispositionState !== 'displayed'
                    && message.dispositionNotification.indexOf('display') !== -1
                    && !message.content.startsWith('?OTRv')
                ) {
                    counter++;
                }
            }
            let callCounter = 0;
            if (state.currentCall !== null) {
                for (let key of Object.keys(messages)) {
                    for (let message of messages[key]) {
                        if (message.state === 'received'
                            && message.dispositionState !== 'displayed'
                            && message.dispositionNotification.indexOf('display') !== -1
                            && !message.content.startsWith('?OTRv')
                            && message.sender.uri === state.currentCall.remoteIdentity.uri
                        ) {
                            callCounter++;
                        }
                    }
                }
                for (let message of state.account.messages) {
                    if (message.state === 'received'
                        && message.dispositionState !== 'displayed'
                        && message.dispositionNotification.indexOf('display') !== -1
                        && !message.content.startsWith('?OTRv')
                        && message.sender.uri === state.currentCall.remoteIdentity.uri
                    ) {
                        callCounter++;
                    }
                }

            }
            setState(prevState => ({ ...prevState, unreadMessages: counter, unreadCallMessages: callCounter }));
            DEBUG('There are %s unread messages', counter);
            if (shouldUseHashRouting) {
                const ipcRenderer = window.require('electron').ipcRenderer;
                counter = counter === 0 ? null : counter;
                ipcRenderer.send('update-badge', counter);
            }
            unreadTimer = null;
        }, 500);
    }

    function syncConversations(messages) {
        setState(prevState => ({ ...prevState, messagesLoading: true }));
        DEBUG('Got messages from server: %o', messages);
        const promises = [];
        let lastId = '';

        if (state.messagesLoadingProgress) {
            setState(prevState => ({ ...prevState, messagesLoadingProgress: 'storing' }));
        }
        messageStorage.updateIdMap();

        for (const fetchedMessage of messages) {
            lastId = fetchedMessage.id;

            if (fetchedMessage.contentType === 'message/imdn'
                || fetchedMessage.contentType === 'message/imdn+json') {
                let decodedContent = fetchedMessage.content;
                promises.push(messageStorage.update({ messageId: decodedContent.message_id, state: decodedContent.state }));
                continue;
            }
            if (fetchedMessage.contentType === 'application/sylk-conversation-remove') {
                const contact = fetchedMessage.content
                promises.push(messageStorage.remove(contact));
                continue;
            }
            if (fetchedMessage.contentType === 'application/sylk-message-remove') {
                const message = fetchedMessage.content;
                message.id = message.message_id;
                message.receiver = message.contact;
                promises.push(messageStorage.removeMessage(message).then(() => {
                    DEBUG('Message removed: %o, %o', message.id, message.contact);
                }));
                continue;
            }

            if (fetchedMessage.contentType === 'application/sylk-conversation-read') {
                const contact = fetchedMessage.content
                promises.push(messageStorage.loadLastMessages().then(allMessages => {
                    if (allMessages) {
                        const messages = allMessages[contact];
                        if (!messages) {
                            return;
                        }

                        for (let message of messages) {
                            if (message.state == 'received'
                                && message.dispositionState !== 'displayed'
                                && message.dispositionNotification.indexOf('display') !== -1
                            ) {
                                sendingDispositionNotification(message.id, 'displayed')
                            }
                        }
                    }
                }));
                continue;
            }
            let message = Object.assign({}, fetchedMessage);

            promises.push(messageStorage.add(fetchedMessage));

            if (message.state == 'received'
                && message.dispositionNotification.indexOf('positive-delivery') !== -1
            ) {
                state.account.sendDispositionNotification(
                    message.receiver,
                    message.id,
                    message.timestamp,
                    'delivered'
                );
            }
        }
        if (lastId !== '') {
            promises.push(storage.set(`lastMessageId-${state.accountId}`, lastId));
        }
        Promise.all(promises).then(() => {
            messageStorage.loadLastMessages().then(messages => {
                if (messages) {
                    setState(prevState => ({ ...prevState, oldMessages: messages, messagesLoading: false, messagesLoadingProgress: false }));
                    calculateUnreadMessages(messages);
                }
                setImmediate(() => retransmitMessages());
                if (state.transmitKeys) {
                    setImmediate(() => {
                        storage.get(`pgpKeys-${state.accountId}`).then(pgpKeys => {
                            if (pgpKeys) {
                                if (Object.keys(state.oldMessages).length === 0) {
                                    state.account.sendMessage('inital_key@to.store.in.sylkserver.info', pgpKeys.publicKey, 'text/pgp-public-key');
                                    return;
                                }
                                for (let contact of Object.keys(state.oldMessages)) {
                                    if (contact !== state.accountId) {
                                        state.account.sendMessage(contact, pgpKeys.publicKey, 'text/pgp-public-key')
                                    }
                                }
                            }
                        });
                        setState(prevState => ({ ...prevState, transmitKeys: false }));
                    });
                }
            })
        }
        );
    }

    function readConversation(contact) {
        const oldMessages = cloneDeep(state.oldMessages);
        const messages = oldMessages[contact];
        let found = false;
        const newMessages = cloneDeep(messages).map(message => {
            messageStorage.updateDisposition(message.id, 'displayed');
            if (!(message instanceof require('events').EventEmitter)
                && message.state == 'received'
                && message.dispositionState !== 'displayed'
                && message.dispositionNotification.indexOf('display') !== -1
            ) {
                message.dispositionState = 'displayed';
                DEBUG('Updating dispositionState for loaded message');
                found = true;
            }
            return message;
        });
        if (found) {
            oldMessages[contact] = newMessages;
        }
        for (let message of state.account.messages) {
            if (message.sender.uri === contact
                && message.state === 'received'
                && message.dispositionNotification.indexOf('display') !== -1
            ) {
                messageStorage.updateDisposition(message.id, 'displayed');
            }
        }
        calculateUnreadMessages(oldMessages);
        setState(prevState => ({ ...prevState, oldMessages: oldMessages }));
    }

    function removeConversation(contact) {
        messageStorage.remove(contact)
        cacheStorage.removeAll(contact)
        let oldMessages = cloneDeep(state.oldMessages);
        delete oldMessages[contact];
        if (lastMessageFocus === contact) {
            lastMessageFocus = '';
        }
        calculateUnreadMessages(oldMessages);
        setState(prevState => ({ ...prevState, oldMessages: oldMessages }));
    }


    function conferenceInvite(data) {
        DEBUG('Conference invite from %o to %s', data.originator, data.room);
        if (state.currentCall && state.targetUri === data.room) {
            DEBUG('Skipped conference invite since you are already in the room: %s', data.room);
            return
        }

        notificationCenter.postSystemNotification('Conference invite', { body: `From ${data.originator.displayName || data.originator.uri} for room ${data.room}`, timeout: 15, silent: false });
        notificationCenter.postConferenceInvite(data.originator, data.room, () => {
            if (state.currentCall !== null) {
                state.currentCall.removeListener('stateChanged', callStateChanged);
                state.currentCall.terminate();
                setState(prevState => ({ ...prevState, currentCall: null, showIncomingModal: false, localMedia: null, generatedVideoTrack: false }));
            }
            setTimeout(() => {
                startConference(data.room);
            });
        });
    }

    function startPreview() {
        getLocalMedia({ audio: true, video: true }, '/preview');
    }

    function addCallHistoryEntry(uri) {
        if (state.mode === MODE_NORMAL) {
            history.add(uri).then((entries) => {
                setState(prevState => ({ ...prevState, history: entries }));
            });
        } else {
            let entries = state.history.slice();
            if (entries.length !== 0) {
                const idx = entries.indexOf(uri);
                if (idx !== -1) {
                    entries.splice(idx, 1);
                }
                entries.unshift(uri);
                // keep just the last 50
                entries = entries.slice(0, 50);
            } else {
                entries = [uri];
            }
            setState(prevState => ({ ...prevState, history: entries }));
        }
    }

    function getServerHistory() {
        if (!config.useServerCallHistory) {
            return;
        }

        if (state.mode === MODE_GUEST_CALL || state.mode === MODE_GUEST_CONFERENCE) {
            return;
        }

        DEBUG('Requesting call history from server');
        let getServerCallHistory = new DigestAuthRequest(
            'GET',
            `${config.serverCallHistoryUrl}?action=get_history&realm=${state.account.id.split('@')[1]}`,
            state.account.id.split('@')[0],
            state.password
        );
        // Disable logging
        getServerCallHistory.loggingOn = false;
        getServerCallHistory.request((data) => {
            if (data.success !== undefined && data.success === false) {
                DEBUG('Error getting call history from server: %o', data.error_message)
                return;
            }
            let history = []
            if (data.placed) {
                data.placed.map(elem => { elem.direction = 'placed'; return elem });
            }
            if (data.received) {
                data.received.map(elem => { elem.direction = 'received'; return elem });
            }
            history = data.placed;
            if (data.received && history) {
                history = history.concat(data.received);
            }
            if (history) {
                history.sort((a, b) => {
                    return new Date(b.startTime) - new Date(a.startTime);
                });
                const known = [];
                history = history.filter((elem) => {
                    if (known.indexOf(elem.remoteParty) <= -1) {
                        if ((elem.media.indexOf('audio') > -1 || elem.media.indexOf('video') > -1) &&
                            (elem.remoteParty !== state.account.id || elem.direction !== 'placed')) {
                            known.push(elem.remoteParty);
                            return elem;
                        }
                    }
                });
                setState(prevState => ({ ...prevState, serverHistory: history }));
            }
        }, (errorCode) => {
            DEBUG('Error getting call history from server: %o', errorCode)
        });
    }


    function render() {

        let loadingScreen;
        let redialScreen;
        let incomingCallModal;
        let incomingWindow;
        let screenSharingModal;
        let footerBox = <FooterBox />;

        if (state.loading !== null) {
            loadingScreen = <LoadingScreen text={state.loading} />;
        }

        if (state.showRedialScreen) {
            redialScreen = (
                <RedialScreen
                    router={router.current}
                    hide={toggleRedialScreen}
                />
            );
        }

        if (state.showIncomingModal) {
            incomingCallModal = (
                <CSSTransition
                    key="incoming"
                    classNames="incoming-modal"
                    timeout={{ enter: 300, exit: 300 }}
                >
                    <IncomingCallModal
                        call={state.inboundCall}
                        onAnswer={answerCall}
                        onHangup={rejectCall}
                        autoFocus={state.propagateKeyPress === false}
                    />
                </CSSTransition>
            );
            if (shouldUseHashRouting) {
                incomingWindow = (
                    <IncomingCallWindow
                        enabled={!state.haveFocus}
                        onAnswer={answerCall}
                        onHangup={rejectCall}
                        setFocus={setFocusEvents}
                    >
                        <IncomingCallModal
                            call={state.inboundCall}
                            onAnswer={answerCall}
                            onHangup={rejectCall}
                            autoFocus={state.propagateKeyPress === false}
                            compact={true}
                        />
                    </IncomingCallWindow>
                );
            }
        }

        if (shouldUseHashRouting) {
            screenSharingModal = (
                <ScreenSharingModal
                    show={state.showScreenSharingModal}
                    close={toggleScreenSharingModal}
                    getLocalScreen={getLocalScreen}
                />
            );
        }

        if (state.localMedia || state.registrationState === 'registered') {
            footerBox = '';
        }
        return (
            <React.Fragment>
                <NotificationCenter ref={notificationCenterRef} />
                {loadingScreen}
                {redialScreen}
                    {footerBox}
                    <ShortcutsModal show={state.showShortcutsModal} close={toggleShortcutsModal} />
                    <LogoutModal
                        show={state.showLogoutModal}
                        close={toggleLogoutModal}
                        logout={(removeData) => {
                            toggleLogoutModal();
                            logout(removeData);
                        }}
                    />
                
                <EncryptionModal
                    show={state.showEncryptionModal}
                    close={toggleEncryptionModal}
                    export={state.export}
                    useExistingKey={useExistingKey}
                    privateKeyDisabled={state.mode === MODE_PRIVATE}
                    exportKey={(password) => {
                        state.account.exportPrivateKey(password); // Added missing semicolon
                    }}
                />
                <NewDeviceModal
                    show={state.showNewDeviceModal}
                    close={toggleNewDeviceModal}
                    privateKeyDisabled={state.mode === MODE_PRIVATE}
                    generatePGPKeys={() => {
                        setState({ loading: 'Generating keys for PGP encryption' });
                        setImmediate(() => {
                            state.account.generatePGPKeys((result) => {
                                storage.set(`pgpKeys-${state.accountId}`, result);
                                toggleNewDeviceModal();
                                setState({ loading: null, transmitKeys: true });

                                keyStorage.getAll().then(key =>
                                    state.account.pgp.addPublicPGPKeys(key)
                                );
                                state.account.pgp.on('publicKeyAdded', (key) => {
                                    keyStorage.add(key);
                                });
                                enableMessaging();
                            });
                        });
                    }}

                    private={state.mode === MODE_PRIVATE}
                />
                <ImportModal
                    importKey={importKey}
                    account={state.account}
                    message={state.importMessage}
                    show={state.showImportModal}
                    close={toggleImportModal}
                />
                <AudioPlayer ref={audioPlayerInbound} sourceFile="assets/sounds/inbound_ringtone.wav" />
                <AudioPlayer ref={audioPlayerOutbound} sourceFile="assets/sounds/outbound_ringtone.wav" />
                <AudioPlayer ref={audioPlayerHangup} sourceFile="assets/sounds/hangup_tone.wav" />
                <TransitionGroup>
                    {incomingCallModal}
                </TransitionGroup>
                {incomingWindow}
                {screenSharingModal}
                <Locations hash={shouldUseHashRouting} ref={router} onBeforeNavigation={checkRoute}>
                    <Location path="/" handler={main} />
                    <Location path="/login" handler={login} />
                    <Location path="/logout" handler={logout} />
                    <Location path="/ready" handler={ready} />
                    <Location path="/call" handler={call} />
                    <Location path="/call/:targetUri" urlPatternOptions={{ segmentValueCharset: 'a-zA-Z0-9-_ \.@' }} handler={callByUri} />
                    <Location path="/call-complete" handler={callComplete} />
                    <Location path="/chat" handler={chat} />
                    <Location path="/conference" handler={conference} />
                    <Location path="/conference/:targetUri" urlPatternOptions={{ segmentValueCharset: 'a-zA-Z0-9-_~ %\.@' }} handler={conferenceByUri} />
                    <Location path="/not-supported" handler={notSupported} />
                    <Location path="/preview" handler={preview} />
                    <NotFound handler={notFound} />
                </Locations>
                                </React.Fragment>
        );
    }

    function notSupported() {
        const errorMsg = (
            <span>
                This application works in a browser that supports WebRTC (like recent versions
                of <a href="https://www.google.com/chrome/browser/desktop/" target="_blank" rel="noopener noreferrer">Chrome</a> or <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer">Firefox</a>)
                or in the standalone <a href="http://sylkserver.com/download/" target="_blank" rel="noopener noreferrer">Sylk application.</a>
            </span>
        );
        return (
            <React.Fragment>

                <ErrorPanel errorMsg={errorMsg} />
                <RegisterBox
                    registrationInProgress={false}
                    handleRegistration={() => { }}
                />
            </React.Fragment>
        );
    }

    function chatWrapper(embed = false, hideCallButtons = false) {
        const removeChat = (contact) => {
            // DEBUG("REMOVE %s", contact);
            messageStorage.remove(contact);
            cacheStorage.removeAll(contact);
            let oldMessages = cloneDeep(state.oldMessages);
            delete oldMessages[contact];
            state.account.removeConversation(contact);
            if (lastMessageFocus === contact) {
                lastMessageFocus = '';
            }
            setState(prevState => ({ ...prevState, oldMessages: oldMessages }));
        };

        const loadMoreMessages = (key) => {
            messageStorage.loadMoreMessages(key).then((cache) => {
                if (cache) {
                    let oldMessages = cloneDeep(state.oldMessages);
                    oldMessages[key] = cache.concat(oldMessages[key]);
                    setState(prevState => ({ ...prevState, oldMessages: oldMessages, storageLoadEmpty: false }));
                    return;
                }
                setState(prevState => ({ ...prevState, storageLoadEmpty: true }));
            });
        };

        const domain = state.currentCall && state.currentCall.remoteIdentity.uri.substring(state.currentCall.remoteIdentity.uri.indexOf('@') + 1) || '';
        let lastMessageFocus = lastMessageFocus
        if (embed && !domain.startsWith('guest.')) {
            lastMessageFocus = state.currentCall && state.currentCall.remoteIdentity.uri || '';
        }
        return (
            <Chat
                key={state.account}
                account={state.account}
                contactCache={state.contactCache}
                oldMessages={state.oldMessages}
                startCall={startCall}
                messageStorage={messageStorage}
                propagateKeyPress={togglePropagateKeyPress}
                focusOn={lastMessageFocus}
                removeChat={removeChat}
                loadMoreMessages={loadMoreMessages}
                lastContactSelected={(uri) => {
                    lastMessageFocus = uri;
                }}
                removeMessage={removeMessage}
                isLoadingMessages={state.messagesLoading}
                sendPublicKey={sendPublicKey}
                embed={embed}
                hideCallButtons={hideCallButtons}
                notificationCenter={notificationCenter}
                storageLoadEmpty={state.storageLoadEmpty}
            />)
    }

    function notFound() {
        const status = {
            title: '404',
            message: 'Oops, the page your looking for can\'t found: ' + window.location.pathname,
            level: 'danger',
            width: 'large'
        }
        return (
            <StatusBox
                {...status}
            />
        );
    }

    function ready() {
        return (
            <React.Fragment>

                <NavigationBar
                    notificationCenter={notificationCenter}
                    account={state.account}
                    logout={toggleLogoutModal}
                    preview={startPreview}
                    toggleMute={toggleMute}
                    toggleShortcuts={toggleShortcutsModal}
                    router={router.current}
                    enableMessaging={state.enableMessaging}
                    exportPrivateKey={() => setState(prevState => ({ ...prevState, export: true, showEncryptionModal: true }))}
                    unreadMessages={state.unreadMessages}
                />
                <ReadyBox
                    account={state.account}
                    startCall={startCall}
                    startConference={startConference}
                    startChat={(uri) => {
                        lastMessageFocus = uri;
                        router.current.navigate('/chat');
                    }}
                    missedTargetUri={state.missedTargetUri}
                    history={state.history}
                    key={state.missedTargetUri}
                    serverHistory={state.serverHistory}
                    noConnection={state.connection.state !== 'ready'}
                />
            </React.Fragment>
        );
    }

    function preview() {
        return (
            <React.Fragment>

                <Preview
                    localMedia={state.localMedia}
                    hangupCall={hangupCall}
                    setDevice={setDevice}
                    selectedDevices={state.devices}
                />
            </React.Fragment>
        );
    }

    function call() {
        return (
            <React.Fragment>
                {state.showChatInCall && state.messagesLoadingProgress &&
                    <MessagesLoadingScreen progress={state.messagesLoadingProgress} />
                }
                {state.showChatInCall &&
                    [chatWrapper(false, true)]
                }
                <Call
                    localMedia={state.localMedia}
                    account={state.account}
                    targetUri={state.targetUri}
                    currentCall={state.currentCall}
                    escalateToConference={escalateToConference}
                    hangupCall={hangupCall}
                    shareScreen={switchScreensharing}
                    generatedVideoTrack={state.generatedVideoTrack}
                    setDevice={setDevice}
                    toggleChatInCall={toggleChatInCall}
                    inlineChat={chatWrapper(true)}
                    unreadMessages={{ total: state.unreadMessages, call: state.unreadCallMessages }}
                    notificationCenter={notificationCenter}
                    propagateKeyPress={state.propagateKeyPress}
                />
            </React.Fragment>
        )
    }

    function callByUri(urlParameters) {
        // check if the uri contains a domain
        if (urlParameters.targetUri.indexOf('@') === -1) {
            const status = {
                title: 'Invalid user',
                message: `Oops, the domain of the user is not set in '${urlParameters.targetUri}'`,
                level: 'danger',
                width: 'large'
            }
            return (
                <StatusBox
                    {...status}
                />
            );
        }
        return (
            <React.Fragment>    
                <CallByUriBox
                    handleCallByUri={handleCallByUri}
                notificationCenter={notificationCenter}
                targetUri={urlParameters.targetUri}
                localMedia={state.localMedia}
                account={state.account}
                currentCall={state.currentCall}
                hangupCall={hangupCall}
                shareScreen={switchScreensharing}
                generatedVideoTrack={state.generatedVideoTrack}
                getLocalMedia={getLocalMedia}
                    setDevice={setDevice}
                    />
            </React.Fragment>
        );
    }

    function callComplete() {
        return (
            <React.Fragment>
            <CallCompleteBox
                wasCall={state.mode === MODE_GUEST_CALL}
                targetUri={state.targetUri}
                failureReason={failureReason}
                retryHandler={handleRetry}
            />
            </React.Fragment>
        )
    }

    function chat() {
        return (
            <React.Fragment>

                <NavigationBar
                    notificationCenter={notificationCenter}
                    account={state.account}
                    logout={toggleLogoutModal}
                    preview={startPreview}
                    toggleMute={toggleMute}
                    toggleShortcuts={toggleShortcutsModal}
                    router={router.current}
                    enableMessaging={state.enableMessaging}
                    exportPrivateKey={() => setState(prevState => ({ ...prevState, export: true, showEncryptionModal: true }))}
                    unreadMessages={state.unreadMessages}
                />
                {state.messagesLoadingProgress &&
                    <MessagesLoadingScreen progress={state.messagesLoadingProgress} />
                }
                {chatWrapper()}
            </React.Fragment>
        )
    }

    function conference() {
        return (
            <React.Fragment>
                {state.showChatInCall && state.messagesLoadingProgress &&
                    <MessagesLoadingScreen progress={state.messagesLoadingProgress} />
                }
                {state.showChatInCall &&
                    [chatWrapper(false, true)]
                }
                <Conference
                    notificationCenter={notificationCenter}
                    localMedia={state.localMedia}
                    account={state.account}
                    targetUri={state.targetUri}
                    currentCall={state.currentCall}
                    participantsToInvite={participantsToInvite}
                    hangupCall={hangupCall}
                    shareScreen={switchScreensharing}
                    generatedVideoTrack={state.generatedVideoTrack}
                    propagateKeyPress={togglePropagateKeyPress}
                    toggleShortcuts={toggleShortcutsModal}
                    roomMedia={state.roomMedia}
                    lowBandwidth={state.lowBandwidth}
                    setDevice={setDevice}
                    toggleChatInCall={toggleChatInConference}
                    unreadMessages={{ total: state.unreadMessages }}
                />
            </React.Fragment>
        )
    }

    function conferenceByUri(urlParameters) {
        const targetUri = utils.normalizeUri(urlParameters.targetUri, config.defaultConferenceDomain);
        const idx = targetUri.indexOf('@');
        const uri = {};
        const pattern = /^[A-Za-z0-9\-\_]+$/g;
        uri.user = targetUri.substring(0, idx);

        // check if the uri.user is valid
        if (!pattern.test(uri.user)) {
            const status = {
                title: 'Invalid conference',
                message: `Oops, the conference ID is invalid: ${targetUri}`,
                level: 'danger',
                width: 'large'
            }
            return (
                <StatusBox
                    {...status}
                />
            );
        }

        return (
            <React.Fragment>            
            <ConferenceByUriBox
                notificationCenter={notificationCenter}
                handler={handleConferenceByUri}
                targetUri={targetUri}
                localMedia={state.localMedia}
                account={state.account}
                currentCall={state.currentCall}
                hangupCall={hangupCall}
                shareScreen={switchScreensharing}
                generatedVideoTrack={state.generatedVideoTrack}
                propagateKeyPress={togglePropagateKeyPress}
                toggleShortcuts={toggleShortcutsModal}
                lowBandwidth={state.lowBandwidth}
                getLocalMedia={getLocalMediaGuestWrapper}
                setDevice={setDevice}
            />
            </React.Fragment>
        );
    }

    function login() {
        let registerBox;
        let statusBox;
        if (state.status !== null) {
            statusBox = (
                <StatusBox
                    message={state.status.msg}
                    level={state.status.level}
                />
            );
        }

        if (state.registrationState !== 'registered') {
            registerBox = (
                <React.Fragment>
                <RegisterBox
                    registrationInProgress={state.registrationState !== null && state.registrationState !== 'failed'}
                    handleRegistration={handleRegistration}
                    autoLogin={shouldUseHashRouting}
                />
                </React.Fragment>

            );
        }

        return (
            <React.Fragment>
                {registerBox}
                {statusBox}
            </React.Fragment>

        );
    }

    function logout(removeData = false) {
        setTimeout(() => {
            if (state.registrationState !== null && (state.mode === MODE_NORMAL || state.mode === MODE_PRIVATE)) {
                state.account.unregister();
            }

            if (shouldUseHashRouting) {
                const ipcRenderer = window.require('electron').ipcRenderer;
                ipcRenderer.send('update-badge', null);
            }
            if (removeData === true) {
                DEBUG('Clearing message storage for: %s', state.accountId);
                Promise.all([messageStorage.dropInstance().then(() => {
                    messageStorage.close();
                    DEBUG('Closing storage: %s', state.accountId);
                    setState(prevState => ({ ...prevState, oldMessages: {} }));
                })]);
                storage.remove(`pgpKeys-${state.accountId}`);
                storage.remove(`lastMessageId-${state.accountId}`);
            }

            messageStorage.close()
            if (state.account !== null) {
                try {
                    state.connection.removeAccount(state.account,
                        (error) => {
                            if (error) {
                                DEBUG(error);
                            }
                        }
                    );
                } catch (error) {
                    DEBUG(error);
                }
            }
            if (shouldUseHashRouting || removeData === true) {
                storage.set('account', { accountId: state.accountId, password: '' });
            }

            if (state.connection.state !== 'ready') {
                state.connection.close();
            }
            setState(prevState => ({ ...prevState, registrationState: null, status: null, serverHistory: [], oldMessages: {}, enableMessaging: false, unreadMessages: 0, unreadCallMessages: 0 }));
            setImmediate(() => setState(prevState => ({ ...prevState, account: null })));
            isRetry = false;
            if (config.showGuestCompleteScreen && (state.mode === MODE_GUEST_CALL || state.mode === MODE_GUEST_CONFERENCE)) {
                router.current.navigate('/call-complete');
            } else {
                router.current.navigate('/login');
            }
        });
        return;
    }

    return render();

    function main() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default Blink;
