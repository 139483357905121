import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate
} from 'react-router-dom';

import React, { useContext, useEffect, useState } from "react";
import TopBar from "./AppTopbar";
import AppSidebar from "./AppSidebar";
import { Box, CssBaseline, Drawer, Grid, Tab, Tabs, ThemeProvider } from "@mui/material";
import { ColorModeContext } from "../../theme";
import { useMode } from "../../theme";
import Dashboard from "../dashbord";
import Team from "../team";
import { Backdrop } from "@mui/material";
import TodaysCases from "../callcenter/case/TodaysCases";
import PendingCases from "../callcenter/case/PendingCases";
import TotalCases from "../callcenter/case/TotalCases";
import AgentSession from "../callcenter/reports/AgentSession";
import VoiceMail from "../callcenter/reports/VoiceMail";
import TotalCalls from "../callcenter/reports/TotalCalls";
import BreaksReport from "../callcenter/reports/Breaks";
import CallSummary from "../callcenter/reports/CallSummary";
import AnsweredCalls from "../callcenter/reports/AnsweredCalls";
import AbandonedCalls from "../callcenter/reports/AbandonedCalls";
import NewWalkIn from "../callcenter/case/NewWalkIn";
import WalkIn from "../callcenter/reports/WalkIn";
import Inbound from "../callcenter/monitor";
import Breaks from "../callcenter/agentconsole/Breaks";
import AgentConsole from "../callcenter/agentconsole";
import Campaigns from "../callcenter/agentconsole/Campaigns";
import NotificationReq from "./Notification";
import Configuration from "../callcenter/configuration";
import Callbacks from "../callcenter/monitor/Callbacks";
import { AuthContext } from "../../context/AuthContext";

import { createConnection } from "../../lib/sylkrtc.js";
import { Call } from "../../lib/call";

import DeviceMap from "../devices/DeviceMap";

import ScopeOfWork from '../../components/ScopeOfWork';
import ProjectContacts from '../../components/ProjectContacts';


const MainLayout = ({ signOut }) => {
  const [theme, colorMode] = useMode();
  const [isSidebar] = useState(true);
  const [sidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const { userInfo } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { isLoading, userToken } = useContext(AuthContext);

  const toggleSidebar = () => {
    if (!isSmallScreen) {
      setIsCollapsed(!isCollapsed);
      setCollapsed(!collapsed);
    } else {
      setToggled(!toggled);
    }
  };

  const handleOverlayClick = () => {
    setToggled(false);
  };
  const [showChat, setShowChat] = useState(false);

  const toggleComponent = () => {
    setShowChat(!showChat);
  };
  const handleToggle = (isChatVisible) => {
    // Your toggle logic here
    console.log("Switch toggled. Chat visibility:", isChatVisible);
    // Additional logic...
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ display: "flex", flexDirection: "row" }}>
          {isSmallScreen ? (
            <>
              <Drawer
                variant="temporary"
                open={toggled}
                onClick={handleOverlayClick}
                ModalProps={{
                  keepMounted: true,
                  container: () => document.body,
                }}
              >
                <AppSidebar
                  sidebarOpen={sidebarOpen}
                  toggleSidebar={toggleSidebar}
                  isCollapsed={isCollapsed}
                  collapsed={collapsed}
                  toggled={toggled}
                />
              </Drawer>
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer }}
                open={toggled}
                onClick={handleOverlayClick}
              />
            </>
          ) : (
            <AppSidebar
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              isCollapsed={isCollapsed}
              collapsed={collapsed}
              toggled={toggled}
            />
          )}
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <TopBar toggleSidebar={toggleSidebar} signOut={signOut} />
            <Box display="flex" m="10px">
              <Grid container spacing={1}>
                <NotificationReq />
                <Grid item xs={12} sm={8}>
                  <Routes>
                    <Route path="/app/helpline/dashboard" element={<Dashboard />} />
                    <Route path="/app/helpline/team" element={<Team />} />
                    <Route path="/app/helpline/inbound" element={<Inbound />} />
                    <Route path="/app/helpline/callbacks" element={<Callbacks />} />
                    {/* <Route path="/app/helpline/report" element={<Report />} /> */}
                    <Route path="/app/helpline/" element={<AgentConsole />} />
                    <Route path="/app/helpline/break" element={<Breaks />} />
                    <Route
                      path="/app/helpline/abandoned_calls"
                      element={<AbandonedCalls />}
                    />
                    <Route path="/app/helpline/agent_session" element={<AgentSession />} />
                    <Route
                      path="/app/helpline/answered_calls"
                      element={<AnsweredCalls />}
                    />
                    <Route path="/app/helpline/call_summary" element={<CallSummary />} />
                    <Route path="/app/helpline/break_report" element={<BreaksReport />} />
                    <Route path="/app/helpline/total_calls" element={<TotalCalls />} />
                    <Route path="/app/helpline/voicemail" element={<VoiceMail />} />
                    <Route path="/app/helpline/walkin" element={<WalkIn />} />
                    <Route path="/app/helpline/campaigns" element={<Campaigns />} />
                    <Route path="/app/helpline/new-walkin" element={<NewWalkIn />} />
                    <Route path="/app/devicemap" element={<DeviceMap/>} />
                    <Route path="/app/helpline/todays-cases" element={<TodaysCases />} />
                    <Route path="/app/helpline/pending-cases" element={<PendingCases />} />
                    <Route path="/app/helpline/total-cases" element={<TotalCases />} />
                    <Route path="/app/helpline/configuration" element={<Configuration />} />
                    <Route path="/app/management/scope-of-work" element={<ScopeOfWork />} />
                    <Route path="/app/management/contacts" element={<ProjectContacts />} />
                  </Routes>
                </Grid>
                {!isSmallScreen ? (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                      position: "relative" /* or "absolute" */,
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "fixed",
                      }}
                    >
                    </div>
                  </Grid>
                ) : (
                  <Grid alignItems="center" width="90%">
                    <Box>
                      <Tabs
                        value={showChat ? 0 : 1}
                        sx={{ "& .MuiTab-root": { height: "34px" } }}
                        onChange={() => toggleComponent()}
                        aria-label="icon label tabs example"
                      >
                        <Tab label="Chat" />
                        <Tab label="Dialer" />
                      </Tabs>
                      <div>
                      </div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
export default MainLayout;
