import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ScopeOfWork = () => {
  const [scopeOfWork, setScopeOfWork] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/management/api/scope-of-work/`)
      .then(response => setScopeOfWork(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h2>Scope of Work</h2>
      <table>
        <thead>
          <tr>
            <th>Particulars</th>
            <th>Input Field</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {scopeOfWork.map(item => (
            <tr key={item.id}>
              <td>{item.particulars}</td>
              <td>{item.input_field}</td>
              <td>{item.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScopeOfWork;
