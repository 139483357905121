'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    sticky: {
        position: 'sticky',
        top: '-1px',
        backgroundColor: 'rgba(230, 230, 230, .85)',
        zIndex: 1
    }
}));

const ListWithStickyHeader = ({ children, header }) => {
    const classes = useStyles();

    const { ref, inView } = useInView({
        threshold: 0
    });

    const { ref: ref2, inView: inView2 } = useInView({
        threshold: [1]
    });

    return (
        <>
            <div ref={ref2} className={inView && !inView2 ? classes.sticky : ''}>{header}</div>
            <div ref={ref}>{children}</div>
        </>
    );
}

ListWithStickyHeader.propTypes = {
    header: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired
};

export default ListWithStickyHeader;
