import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AreaGradientChart from './AreaGradientChart';
import LineChart from './LineChart';

const useStyles = makeStyles((theme) => ({
    graphText: {
        width: '48%',
        fontSize: '12px'
    }
}));

const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
};

const prefixBits = (bits) => {
    let i = -1;
    const byteUnits = 'kMGTPEZY';
    do {
        bits = bits / 1000;
        i++;
    } while (bits > 1000);

    return `${Math.max(bits, 0.1).toFixed(bits < 10 ? 1 : 0)} ${byteUnits[i]}bits/s`;
};

const Charts = ({
    data,
    videoElements,
    lastData,
    video,
    details
}) => {
    const classes = useStyles();
    const lastGraphData = data[data.length - 1];
    const videoGraphs = video !== undefined && video !== false;
    const showDetails = details !== undefined && details !== false;

    let localFrameRate = 0;
    let remoteFrameRate = 0;
    let packetsLostOutboundPercentage = 0;
    let packetsLostInboundPercentage = 0;
    let remoteResolution, localResolution;

    if (videoGraphs) {
        const videoData = lastData?.video;
        const videoDataInbound = videoData?.inbound?.[0];
        localFrameRate = videoData?.outbound?.[0]?.framesPerSecond || videoData?.outbound?.[0]?.framerateMean;
        localResolution = videoData ? `${videoData.outbound[0].frameWidth} x ${videoData.outbound[0].frameHeight}` : 'x';
        localFrameRate = isFloat(localFrameRate) ? localFrameRate.toFixed(1) : localFrameRate;
        remoteFrameRate = videoDataInbound?.framesPerSecond || videoDataInbound?.framerateMean;
        remoteResolution = videoDataInbound ? `${videoDataInbound.frameWidth} x ${videoDataInbound.frameHeight}` : 'x';
        remoteFrameRate = isFloat(remoteFrameRate) ? remoteFrameRate.toFixed(1) : remoteFrameRate;

        // Use video element data if resolution is unavailable
        if (remoteResolution === ' x ' && videoElements?.remoteVideo?.current) {
            remoteResolution = `${videoElements.remoteVideo.current.videoWidth} x ${videoElements.remoteVideo.current.videoHeight}`;
        }
        if (localResolution === ' x ' && videoElements?.localVideo?.current) {
            localResolution = `${videoElements.localVideo.current.videoWidth} x ${videoElements.localVideo.current.videoHeight}`;
        }
    }

    const inboundPacketsLost = lastGraphData?.packetsLostInbound;
    const outboundPacketsLost = lastGraphData?.packetsLostOutbound;
    const inboundPackets = lastGraphData?.packetRateInbound;
    const outboundPackets = lastGraphData?.packetRateOutbound;
    packetsLostOutboundPercentage = (outboundPacketsLost / outboundPackets) * 100;
    packetsLostInboundPercentage = (inboundPacketsLost / inboundPackets) * 100;

    return (
        <div>
            {videoGraphs && showDetails && (
                <table className="table table-condensed table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ width: '30%' }}>Incoming</th>
                            <th style={{ width: '30%' }}>Outgoing</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Resolution</td>
                            <td>{remoteResolution}</td>
                            <td>{localResolution}</td>
                        </tr>
                        {localFrameRate && remoteFrameRate && (
                            <tr>
                                <td>Framerate</td>
                                <td>{remoteFrameRate}</td>
                                <td>{localFrameRate}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}

            <h5>Bandwidth</h5>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={classes.graphText}>
                    In: {lastGraphData?.incomingBitrate && prefixBits(lastGraphData.incomingBitrate)}
                </div>
                <div className={classes.graphText}>
                    Out: {lastGraphData?.outgoingBitrate && prefixBits(lastGraphData.outgoingBitrate)}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '48%' }}>
                    <AreaGradientChart data={data} dataKey="incomingBitrate" color="green" height={70} />
                </div>
                <div style={{ width: '48%' }}>
                    <AreaGradientChart data={data} dataKey="outgoingBitrate" height={70} />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%' }}>
                    <h5>Latency: {lastGraphData?.latency && lastGraphData.latency * 1000} ms</h5>
                    <AreaGradientChart data={data} dataKey="latency" color="blue" />
                </div>
            </div>

            <h5>Packet Loss</h5>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={classes.graphText}>
                    In: {inboundPacketsLost?.toFixed(0)} &nbsp;({packetsLostInboundPercentage.toFixed(2)} %)
                </div>
                <div className={classes.graphText}>
                    Out: {outboundPacketsLost?.toFixed(0)} &nbsp;({packetsLostOutboundPercentage.toFixed(2)} %)
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '48%' }}>
                    <LineChart data={data} dataKey="packetsLostInbound" height={60} />
                </div>
                <div style={{ width: '48%' }}>
                    <LineChart data={data} dataKey="packetsLostOutbound" height={60} />
                </div>
            </div>
        </div>
    );
};

Charts.propTypes = {
    data: PropTypes.array.isRequired,
    lastData: PropTypes.object.isRequired,
    videoElements: PropTypes.object,
    video: PropTypes.bool,
    details: PropTypes.bool
};

export default Charts;
