import React from "react";
import { Badge, Divider } from "@mui/material";
import { Box, Typography, useTheme } from "@mui/material";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineRoundedIcon from "@mui/icons-material/WorkOutlineRounded";
import AspectRatioRoundedIcon from "@mui/icons-material/AspectRatioRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import PanoramaWideAngleRoundedIcon from "@mui/icons-material/PanoramaWideAngleRounded";
import PauseCircleOutlineRoundedIcon from "@mui/icons-material/PauseCircleOutlineRounded";
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { tokens } from "../../theme";
import SidebarHeader from "./SidebarHeader";
import { Link } from "react-router-dom";

function AppSidebar({ collapsed, toggled }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
      // height: 30,
    },
    icon: {
      color: colors.skyblueAccent[500],
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: level === 0 ? colors.custom[500] : "transparent",
    }),
    button: {
      "&:hover": {
        backgroundColor: colors.custom[300],
        color: colors.custom[400],
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Box display="flex">
      <div
        style={{
          display: "flex",
          position: "relative",
          minHeight: "100%",
          backgroundColor: colors.primary[501],
        }}
      >
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          // onBackdropClick={() => setToggled(false)}
          breakPoint="md"
          height="100%"
          backgroundColor={colors.primary[501]}
          rootStyles={{
            color: colors.skyblueAccent[500],
          }}
          style={{
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <SidebarHeader
              style={{ marginBottom: "14px", marginTop: "16px" }}
            />
            <Divider />
            <div style={{ flex: 1, marginBottom: "32px" }}>
              <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  Call Center
                </Typography>
              </div>
              <Menu menuItemStyles={menuItemStyles}>
                <SubMenu
                  label="Agent"
                  icon={<SupportAgentRoundedIcon />}
                  suffix={<Badge variant="success">New</Badge>}
                >
                  <MenuItem component={<Link to="/app/helpline/" />}>
                    {" "}
                    Agent Console
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/campaigns" />}>
                    {" "}
                    Campaigns
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/break" />}> Breaks</MenuItem>
                </SubMenu>
                <SubMenu label="Cases" icon={<WorkOutlineRoundedIcon />}>
                  <MenuItem component={<Link to="/app/helpline/new-walkin" />}>
                    New WalkIn
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/todays-cases" />}>
                    Today's Cases
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/pending-cases" />}>
                    Pending Cases
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/total-cases" />}>
                    Total Cases
                  </MenuItem>
                </SubMenu>
                <SubMenu label="Monitor" icon={<AspectRatioRoundedIcon />}>
                  <Box>
                    <MenuItem component={<Link to="/app/helpline/inbound" />}>
                      Inbound Teem
                    </MenuItem>
                    <MenuItem component={<Link to="/app/helpline/callbacks" />}>
                      Call Backs
                    </MenuItem>
                  </Box>
                </SubMenu>
                <SubMenu
                  label="Report"
                  icon={<SummarizeRoundedIcon />}
                  suffix={
                    <Badge variant="danger" shape="circle">
                      6
                    </Badge>
                  }
                >
                  <MenuItem component={<Link to="/app/helpline/total_calls" />}>
                    Total Calls
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/answered_calls" />}>
                    Aswered Calls
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/abandoned_calls" />}>
                    Abandoned Calls
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/call_summary" />}>
                    Call Summary
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/agent_session" />}>
                    Agent Session
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/break_report" />}>
                    Breaks
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/voicemail" />}>
                    Voice Mail
                  </MenuItem>
                  <MenuItem component={<Link to="/app/helpline/walkin" />}>Walkin</MenuItem>
                </SubMenu>
                <MenuItem
                  component={<Link to="/app/helpline/configuration" />}
                  icon={<PermDataSettingIcon />}
                >
                  Configuration
                </MenuItem>
              </Menu>

              <div
                style={{
                  padding: "0 24px",
                  marginBottom: "8px",
                  marginTop: "32px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  Admin
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  component={<Link to="/app/helpline/dashboard" />}
                  icon={<HomeOutlinedIcon />}
                >
                  Dashboard
                </MenuItem>
                <MenuItem icon={<SummarizeRoundedIcon />}>Reports</MenuItem>
                <MenuItem icon={<PanoramaWideAngleRoundedIcon />}>
                  Panel
                </MenuItem>
                <MenuItem icon={<PauseCircleOutlineRoundedIcon />}>
                  Break
                </MenuItem>
                <MenuItem disabled icon={<Diversity3RoundedIcon />}>
                  Forms
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Sidebar>
      </div>
    </Box>
  );
}

export default AppSidebar;
