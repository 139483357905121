import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Tab, Tabs, Tooltip, InputBase } from '@mui/material';

const BootstrapButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  fontFamily: 'inherit',
  fontWeight: 'normal',
  padding: '6px 12px',
  border: '1px solid transparent',
  lineHeight: 1.42857143,
  borderRadius: '4px',
  '&.MuiButton-contained': {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#286090',
      borderColor: '#204d74',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, .125)',
      backgroundColor: '#286090',
      borderColor: '#204d74',
    },
    '&:focus': {
      borderColor: '#122b40',
      backgroundColor: '#204d74',
      outlineOffset: '-2px',
      boxShadow: 'inset 0px 3px 5px 0px rgba(0,0,0,.125)',
    },
  },
  '&.Mui-disabled': {
    border: '1px solid #fff',
    cursor: 'not-allowed',
  },
  '&.MuiButton-sizeLarge': {
    padding: '10px 20px',
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: '6px',
    lineHeight: 1.33333,
  },
  '& .MuiButton-label': {
    display: 'block',
  },
  '&.MuiButton-text': {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: '#337ab7',
    boxShadow: 'none',
  },
}));

const BootstrapInputBase = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  fontFamily: 'inherit',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#66afe9',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
    },
  },
}));

const BootstrapTabs = styled(Tabs)({
  borderBottom: '1px solid rgba(0, 0, 0, .12)',
  '& .MuiTabs-indicator': {
    backgroundColor: '#337ab7',
  },
});

const BootstrapTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: 'inherit',
  fontSize: '14px',
  '&:hover': {
    color: '#23527c',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#337ab7',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:focus': {
    color: '#337ab7',
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-arrow': {
    color: theme.palette.common.black,
  },
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    fontFamily: 'inherit',
  },
}));

export { BootstrapButton as Button };
export { BootstrapInputBase as InputBase };
export { BootstrapTab as Tab };
export { BootstrapTabs as Tabs };
export { BootstrapTooltip as Tooltip };
