import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { utils as sylkrtcutils } from 'sylkrtc';
import { Badge, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import clsx from 'clsx';
import FullscreenMixin from '../mixins/FullScreen';
import CallOverlay from './CallOverlay';
import CallQuality from './CallQuality';
import ConferenceDrawer from './ConferenceDrawer';
import DragAndDrop from './DragAndDrop';
import SwitchDevicesMenu from './SwitchDevicesMenu';
import EscalateConferenceModal from './EscalateConferenceModal';
import Statistics from './Statistics';
import fileTransferUtils from '../fileTransferUtils';
import utils from '../utils';
import debug from 'debug';

const DEBUG = debug('blinkrtc:Video');

const useStyles = makeStyles({
    badge: {
        width: '20px',
        height: '20px',
        fontWeight: 'bold',
        fontSize: '1rem',
        backgroundColor: '#337ab7',
        '&.MuiBadge-anchorOriginTopLeftCircular': {
            top: '18%',
            left: '18%'
        },
        '&.MuiBadge-anchorOriginTopRightCircular': {
            top: '18%',
            right: '18%'
        }
    },
    sharingButton: {
        width: '45px',
        height: '45px',
        backgroundColor: '#fff',
        fontSize: '20px',
        border: '1px solid #fff',
        color: '#333',
        margin: '4px',
        '&:hover': {
            backgroundColor: '#fff'
        }
    }
});

const VideoBox = ({
    setDevice,
    shareScreen,
    call,
    localMedia,
    hangupCall,
    escalateToConference,
    generatedVideoTrack,
    unreadMessages,
    notificationCenter,
    toggleChatInCall,
    toggleInlineChat,
    inlineChat,
    propagateKeyPress
}) => {
    const classes = useStyles();
    const [callOverlayVisible, setCallOverlayVisible] = useState(true);
    const [audioMuted, setAudioMuted] = useState(false);
    const [videoMuted, setVideoMuted] = useState(false);
    const [localVideoShow, setLocalVideoShow] = useState(false);
    const [remoteVideoShow, setRemoteVideoShow] = useState(false);
    const [remoteSharesScreen, setRemoteSharesScreen] = useState(false);
    const [showEscalateConferenceModal, setShowEscalateConferenceModal] = useState(false);
    const [switchAnchor, setSwitchAnchor] = useState(null);
    const [showSwitchMenu, setShowSwitchMenu] = useState(false);
    const [showAudioSwitchMenu, setShowAudioSwitchMenu] = useState(false);
    const [showStatistics, setShowStatistics] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [showInlineChat, setShowInlineChat] = useState(false);
    const [videoGraphData, setVideoGraphData] = useState(new Array(60).fill({}));
    const [audioGraphData, setAudioGraphData] = useState(new Array(60).fill({}));
    const [lastData, setLastData] = useState({});
    const localVideo = useRef(null);
    const remoteVideo = useRef(null);
    const overlayTimer = useRef(null);

    useEffect(() => {
        sylkrtcutils.attachMediaStream(call.getLocalStreams()[0], localVideo.current, { disableContextMenu: true, muted: true });
        const promise = localVideo.current.play();
        if (promise !== undefined) {
            promise.then(() => setLocalVideoShow(true)).catch((error) => DEBUG('Autoplay was prevented', error));
        } else {
            localVideo.current.addEventListener('playing', () => setLocalVideoShow(true));
        }

        remoteVideo.current.addEventListener('playing', handleRemoteVideoPlaying);
        call.account.on('incomingMessage', incomingMessage);
        sylkrtcutils.attachMediaStream(call.getRemoteStreams()[0], remoteVideo.current, { disableContextMenu: true });
        call.statistics.on('stats', statistics);
        document.addEventListener('keydown', onKeyDown);

        return () => {
            clearTimeout(overlayTimer.current);
            remoteVideo.current.removeEventListener('playing', handleRemoteVideoPlaying);
            document.removeEventListener('keydown', onKeyDown);
            call.account.removeListener('incomingMessage', incomingMessage);
            call.statistics.removeListener('stats', statistics);
        };
    }, [call]);

    const statistics = (stats) => {
        const videoData = stats.data.video;
        const audioData = stats.data.audio;
        const audioRemoteData = stats.data.remote.audio;
        const videoRemoteData = stats.data.remote.video;
        const videoRemoteExists = videoRemoteData.inbound[0];
        const audioRemoteExists = audioRemoteData.inbound[0];

        if (!videoRemoteExists && !audioRemoteExists) return;

        const audioRTT = audioRemoteExists ? audioRemoteData.inbound[0].roundTripTime : 0;
        const videoRTT = videoRemoteExists ? videoRemoteData.inbound[0].roundTripTime : audioRTT;
        const addData = {
            audio: {
                ...audioData.inbound[0],
                latency: audioRTT / 2,
                packetsLostOutbound: audioRemoteExists ? audioRemoteData.inbound[0].packetLossRate : 0,
            },
            video: {
                ...videoData.inbound[0],
                latency: videoRTT / 2,
                packetsLostOutbound: videoRemoteExists ? videoRemoteData.inbound[0].packetLossRate : 0,
            },
        };
        setVideoGraphData((prev) => prev.concat(addData.video).slice(1));
        setAudioGraphData((prev) => prev.concat(addData.audio).slice(1));
        setLastData(stats.data);
    };

    const onKeyDown = (event) => {
        if (!showEscalateConferenceModal && !propagateKeyPress) {
            switch (event.which) {
                case 77: // M
                    muteAudio(event);
                    break;
                case 86: // V
                    muteVideo(event);
                    break;
                case 83: // S
                    event.preventDefault();
                    shareScreen();
                    break;
                case 70: // F
                    event.preventDefault();
                    handleFullscreen();
                    break;
                default:
                    break;
            }
        }
    };

    const handleFullscreen = () => {
        // Implementation for handling fullscreen
    };

    const handleRemoteVideoPlaying = () => {
        setRemoteVideoShow(true);
        remoteVideo.current.onresize = handleRemoteResize;
        armOverlayTimer();
    };

    const handleRemoteResize = (event) => {
        const resolutions = ['1280x720', '960x540', '640x480', '640x360', '480x270', '320x180'];
        const videoResolution = `${event.target.videoWidth}x${event.target.videoHeight}`;
        setRemoteSharesScreen(resolutions.indexOf(videoResolution) === -1);
    };

    const muteAudio = (event) => {
        event.preventDefault();
        const localStream = call.getLocalStreams()[0];
        const track = localStream.getAudioTracks()[0];
        if (audioMuted) {
            track.enabled = true;
            setAudioMuted(false);
        } else {
            track.enabled = false;
            setAudioMuted(true);
        }
    };

    const muteVideo = (event) => {
        event.preventDefault();
        const localStream = call.getLocalStreams()[0];
        const track = localStream.getVideoTracks()[0];
        if (videoMuted) {
            track.enabled = true;
            setVideoMuted(false);
        } else {
            track.enabled = false;
            setVideoMuted(true);
        }
    };

    const hangup = (event) => {
        event.preventDefault();
        hangupCall();
    };

    const toggleStatistics = () => setShowStatistics((prev) => !prev);

    const armOverlayTimer = () => {
        clearTimeout(overlayTimer.current);
        overlayTimer.current = setTimeout(() => setCallOverlayVisible(false), 4000);
    };

    const incomingMessage = (message) => {
        if (inlineChat && call.remoteIdentity.uri === message.sender.uri) {
            if (!showInlineChat) {
                notificationCenter.postNewMessage(message, toggleInlineChat);
            }
        } else if (!showChat) {
            notificationCenter.postNewMessage(message, toggleChatInCall);
        }
    };

    return (
        <div className={clsx({ 'drawer-wide-visible': showInlineChat && !showChat && !utils.isMobile.any(), 'drawer-visible': showInlineChat && !showChat && utils.isMobile.any() })}>
            <DragAndDrop title="Drop files to share them" handleDrop={() => {}}>
                <div className="video-container" onMouseMove={() => setCallOverlayVisible(true)}>
                    <CallOverlay
                        show={callOverlayVisible}
                        remoteIdentity={call.remoteIdentity.displayName || call.remoteIdentity.uri}
                        call={call}
                        buttons={{}}
                        onTop={showChat}
                        callQuality={<CallQuality videoData={videoGraphData} audioData={audioGraphData} />}
                    />
        <video id="remoteVideo" className={clsx({ 'fadeIn': remoteVideoShow, 'fadeOut': !remoteVideoShow })} ref={remoteVideo} autoPlay />
                <video id="localVideo" className={clsx({ 'fadeIn': localVideoShow, 'fadeOut': !localVideoShow })} ref={localVideo} autoPlay muted />
            </div>
        </DragAndDrop>
        <SwitchDevicesMenu show={showSwitchMenu} close={() => setShowSwitchMenu(false)} call={call} setDevice={setDevice} />
        <SwitchDevicesMenu show={showAudioSwitchMenu} close={() => setShowAudioSwitchMenu(false)} call={call} setDevice={setDevice} audio />
        <EscalateConferenceModal show={showEscalateConferenceModal} call={call} close={() => setShowEscalateConferenceModal(false)} escalateToConference={escalateToConference} />
        <ConferenceDrawer show={showStatistics} close={toggleStatistics}>
            <Statistics videoData={videoGraphData} audioData={audioGraphData} lastData={lastData} />
        </ConferenceDrawer>
    </div>
);
};

VideoBox.propTypes = {
setDevice: PropTypes.func.isRequired,
shareScreen: PropTypes.func.isRequired,
call: PropTypes.object.isRequired,
localMedia: PropTypes.object,
hangupCall: PropTypes.func.isRequired,
escalateToConference: PropTypes.func.isRequired,
generatedVideoTrack: PropTypes.bool,
unreadMessages: PropTypes.object,
notificationCenter: PropTypes.func,
toggleChatInCall: PropTypes.func,
inlineChat: PropTypes.object,
propagateKeyPress: PropTypes.bool
};

export default VideoBox;
