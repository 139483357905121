'use strict';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    FormGroup,
    FormControlLabel
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from '../MaterialUIAsBootstrap';

const useStyles = makeStyles((theme) => ({
    bigger: {
        '&> h2': {
            fontSize: '20px'
        },
        '&> div > p': {
            fontSize: '14px'
        },
        '&> li.MuiListSubheader-root': {
            fontSize: '14px',
            textAlign: 'left'
        }
    },
    root: {
        marginTop: -5,
        '& .MuiSvgIcon-root': {
            fontSize: 24
        }
    },
    center: {
        flexGrow: 1,
        paddingLeft: 12,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 0
    },
    fixFont: {
        fontFamily: 'inherit',
        fontSize: '14px',
        textAlign: 'left'
    },
    fixFontCheck: {
        marginBottom: 0
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        '&> span > svg': {
            fontSize: 24
        }
    }
}));

const LogoutModal = (props) => {
    const classes = useStyles();
    const [removeData, setRemoveData] = useState(false);

    const handleRemoveDataChange = (event) => {
        setRemoveData(event.target.checked);
    };

    return (
        <Dialog
            open={props.show}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.close();
                }
            }}
            maxWidth="sm"
            fullWidth
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            disableEscapeKeyDown
        >
            <DialogTitle id="dialog-title" className={classes.bigger}>
                Sign out of Sylk
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.close}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="dialog-description" className={classes.fixFont}>
                    You will no longer be reachable for calls and messages on this device/browser.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <FormGroup
                    row
                    classes={{
                        root: classes.center
                    }}
                >
                    <FormControlLabel
                        classes={{
                            root: classes.fixFontCheck,
                            label: classes.fixFont
                        }}
                        control={
                            <Checkbox
                                checked={removeData}
                                onChange={handleRemoveDataChange}
                                classes={{
                                    root: classes.root
                                }}
                            />
                        }
                        label="Also remove your existing data"
                    />
                </FormGroup>
                <Button onClick={props.close} variant="text" title="Cancel">
                    Cancel
                </Button>
                <Button onClick={() => {props.logout(removeData); setRemoveData(false)}} variant="contained" title="Sign Out">
                    Sign Out
                </Button>
            </DialogActions>
        </Dialog>
    );
}

LogoutModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
};

export default LogoutModal;
