import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';

const Logo = styled('img')({
  height: '40px',
  marginRight: '10px',
});

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
});

const NavLinks = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const navItems = [
  { label: 'Home', href: '/' },
  { label: 'What We Do', href: '/#WhatWeDo' },
  { label: 'Our Clients', href: '/#OurClients' },
  { label: 'Contact', href: '/#contact' },
];

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemText>
              <Typography variant="button" component="a" href={item.href}>
                {item.label}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemText>
            <Typography variant="button" component="a" href="/login">
              Sign In
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed" color="default">
      <Container maxWidth="lg">
        <StyledToolbar>
          <Box display="flex" alignItems="center">
            <Logo src="/assets/img/logo.png" alt="Zerxis Logo" />
          </Box>
          <NavLinks>
            {navItems.map((item) => (
              <Button key={item.label} color="inherit" href={item.href}>
                {item.label}
              </Button>
            ))}
            <Button color="inherit" href="/login">
              Sign In
            </Button>
          </NavLinks>
          <MenuButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </MenuButton>
        </StyledToolbar>
      </Container>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
