import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { CancelOutlined as CloseIcon, Create as CreateIcon } from '@mui/icons-material';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

import imageConversion from 'image-conversion';
import computedStyleToInlineStyle from 'computed-style-to-inline-style';
import OldMessage from './Chat/OldMessage';
import debug from 'debug';

const DEBUG = debug('blinkrtc:ConferenceChatEditor');

const ConferenceChatEditor = (props) => {
  const [message, setMessage] = useState('');
  const [pickerVisible, setPickerVisible] = useState(false);
  const [contentType, setContentType] = useState('text/plain');
  const [timer, setTimer] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  useEffect(() => {
    if (props.editMessage) {
      setMessage(props.editMessage.content);
      editorRef.current.focus();
    } else {
      setMessage('');
    }
  }, [props.editMessage]);

  const handleSendMessage = () => {
    if (message.trim() === '') return;

    if (timer) clearTimeout(timer);

    if (contentType === 'text/html' || contentType.startsWith('image/')) {
      DEBUG('Sending message with content type:', contentType);
      props.onSubmit(message, contentType);
    } else {
      DEBUG('Sending text message');
      props.onSubmit(message);
    }

    setPickerVisible(false);
    setContentType('text/plain');
    setMessage('');
    editorRef.current.innerHTML = '';
  };

  const handlePaste = (e) => {
    DEBUG('Paste detected');
    let found = false;
    const target = e.currentTarget;

    e.clipboardData.items.forEach((item) => {
      if (item.type.match(/image.*/)) {
        DEBUG('Image detected in clipboard');
        const file = item.getAsFile();
        imageConversion.compressAccurately(file, { size: 200, scale: 0.5 }).then((res) => {
          const reader = new FileReader();
          reader.onload = (evt) => {
            const binary = evt.target.result;
            const imageBase64 = `data:${file.type};base64,${btoa(binary)}`;
            const imageTag = `<div class="file-box" style="background-image: url('${imageBase64}')" />`;
            target.innerHTML = imageTag;
            setMessage(imageBase64);
            setContentType(file.type);
          };
          reader.readAsBinaryString(res);
        });
        found = true;
      }
    });

    if (!found) {
      let data = e.clipboardData.getData('text/html');
      if (!data) {
        DEBUG('Plain text detected');
        data = e.clipboardData.getData('text/plain');
        target.innerText = data;
        setMessage(data);
      } else {
        DEBUG('HTML content detected');
        setContentType('text/html');
        const cleanData = data.replace(/\n|\t/g, '');
        target.innerHTML = cleanData;
        setMessage(cleanData);
        computedStyleToInlineStyle(target, {
          recursive: true,
          properties: ['font-family', 'font-size', 'color', 'background-color'],
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const addEmoji = (emoji) => {
    editorRef.current.innerText += emoji.native;
    setMessage(editorRef.current.innerText);
    editorRef.current.focus();
  };

  const togglePicker = () => setPickerVisible(!pickerVisible);

  const handleFocus = () => {
    const el = editorRef.current;
    if (el && document.createRange && contentType !== 'image/') {
      setTimeout(() => {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }, 1);
    }
  };

  return (
    <div style={{ margin: '0 -15px' }}>
      {pickerVisible && (
        <div style={{ minHeight: '360px', flex: '0 0 auto' }}>
          <Picker
            set="apple"
            data={data}
            showPreview={false}
            sheetSize={32}
            showSkinTones={false}
            onSelect={addEmoji}
            style={{ width: '100%' }}
          />
        </div>
      )}

      <div className="top-editor-wrapper">
        {props.editMessage && (
          <div className="editor-row">
            <CreateIcon style={{ fontSize: '24px', color: '#31708f', marginRight: '8px' }} />
            <OldMessage message={props.editMessage} />
            <IconButton onClick={props.cancelEdit} style={{ marginLeft: 'auto', padding: '10px' }}>
              <CloseIcon style={{ fontSize: '24px' }} />
            </IconButton>
          </div>
        )}

        <div className="editor-row">
          {props.upload && (
            <>
              <input
                id="outlined-button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={props.upload}
              />
              <label htmlFor="outlined-button-file" className="upload-button-label">
                <div className="upload-button">
                  <i className="fa fa-plus fa-2x" />
                </div>
              </label>
            </>
          )}

          {props.enableVoiceMessage && (
            <div className="upload-button" onClick={props.toggleRecordVoiceMessage}>
              <i className="fa fa-microphone fa-2x" />
            </div>
          )}

          <div className={`emoji-button ${props.upload ? 'padding-fixed' : ''}`} onClick={togglePicker}>
            <i className="fa fa-smile-o fa-2x" />
          </div>

          <div className="editor-wrapper">
            <div className="editor-inner-wrapper">
              <div
                className="pre-editor"
                style={{ visibility: message ? 'hidden' : 'visible' }}
              >
                Type a message
              </div>
              <div
                className="editor"
                contentEditable
                ref={editorRef}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={props.focus}
              />
            </div>
          </div>

          {message && (
            <IconButton onClick={handleSendMessage} style={{ marginLeft: '-10px', padding: '10px' }}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

ConferenceChatEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
  scroll: PropTypes.func.isRequired,
  focus: PropTypes.func.isRequired,
  upload: PropTypes.func,
  enableVoiceMessage: PropTypes.bool,
  toggleRecordVoiceMessage: PropTypes.func,
  editMessage: PropTypes.object,
  cancelEdit: PropTypes.func,
};

export default ConferenceChatEditor;
