import React from 'react';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
}));

const ClientLogo = styled('img')({
  width: '185px',
  height: '100px',
  objectFit: 'contain',
});

const clientLogos = [
  { src: '/assets/img/partner/onfon.png', alt: 'Client 2' },
  { src: '/assets/img/partner/cjslogo.png', alt: 'Client 3' },
  { src: '/assets/img/partner/3.jpg', alt: 'Client 4' },
  { src: '/assets/img/partner/sportpesa-logo.png', alt: 'Client 5' },
  { src: '/assets/img/partner/4.jpg', alt: 'Client 6' },
  { src: '/assets/img/partner/5.jpg', alt: 'Client 7' },
  { src: '/assets/img/partner/6.jpg', alt: 'Client 8' },
  { src: '/assets/img/partner/7.jpg', alt: 'Client 9' },
  { src: '/assets/img/partner/8.jpg', alt: 'Client 10' },
  { src: '/assets/img/partner/13.jpg', alt: 'Client 11' },
  { src: '/assets/img/partner/2.jpg', alt: 'Client 13' },

];

const OurClients = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <StyledSection id="OurClients">
      <Container>
        <Box textAlign="center" mb={6}>
          <Typography variant="h2" component="h2" gutterBottom>
            Our Clients
          </Typography>
        </Box>
        <Slider {...settings}>
          {clientLogos.map((logo, index) => (
            <Box key={index} px={2}>
              <ClientLogo src={logo.src} alt={logo.alt} />
            </Box>
          ))}
        </Slider>
      </Container>
    </StyledSection>
  );
};

export default OurClients;
