import { Typography } from "@mui/material";
import React from "react";

const Caller = ({ text }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "25vh",
        //  backgroundColor: colors.grey[900],
        // border: '1px solid black',
        padding: "5px",
        margin: "5px",
        overflowY: "auto", 
        zoom:0.9,
      }}
    >
      <Typography variant="body2">Phone: 254724577000</Typography>
      <Typography variant="body2">Names: Miriam</Typography>
      <Typography variant="body2">NAME: Miriam</Typography>
      <Typography variant="body2">AMOUNT: 2014</Typography>
      <Typography variant="body2">APP: instapesa</Typography>
      <Typography variant="body2">AGENT: SC1687</Typography>
      <Typography variant="body2">Campaign: CHORUS</Typography>
      <Typography variant="body2">Call ID: outgoing-212-200615</Typography>
    </div>
  );
};

export default Caller;
