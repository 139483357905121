import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import { utils as sylkrtcutils } from 'sylkrtc';
import { makeStyles } from '@mui/styles';
import {
    ListSubheader,
    ListItemIcon,
    Menu,
    MenuItem,
    Fade,
    CircularProgress
} from '@mui/material';

import AudioMenuItem from './SwitchDevicesMenu/AudioMenuItem';
import VideoMenuItem from './SwitchDevicesMenu/VideoMenuItem';
import { Queue } from '../utils';

const DEBUG = debug('blinkrtc:SwitchDevicesMenu');

const useStyles = makeStyles((theme) => ({
    paper: {
        marginLeft: (props) => (props.direction === 'right' ? '5px' : ''),
        marginTop: (props) => (props.direction !== 'right' && props.direction !== 'up' ? '5px' : ''),
        width: '272px'
    },
    item: {
        fontSize: '14px',
        fontFamily: 'inherit',
        color: '#333',
        minHeight: 0,
        lineHeight: '30px',
        margin: '4px 0'
    },
    audioLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '20px',
        flex: 3
    },
    subheader: {
        textAlign: 'left',
        fontFamily: 'inherit',
        fontWeight: 700,
        fontSize: '14px'
    },
    icon: {
        minWidth: '20px'
    },
    selected: {
        color: '#fff',
        backgroundColor: '#337ab7 !important',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .04) !important',
            color: '#000'
        }
    }
}));

const SwitchDevicesMenu = ({
    show,
    close,
    call,
    setDevice,
    anchor,
    audio = false,
    showOutput = false,
    direction = 'bottom'
}) => {
    const classes = useStyles({ direction });
    const [devices, setDevices] = useState([]);
    const [videoInput, setVideoInput] = useState('');
    const [audioInput, setAudioInput] = useState('');
    const [audioOutput, setAudioOutput] = useState('');
    const [mediaStreams, setMediaStreams] = useState({});
    const init = useRef(false);

    const currentStream = call.getLocalStreams()[0];

    useEffect(() => {
        if (show) {
            start();
        }

        if (init.current && !show) {
            Object.keys(mediaStreams).forEach((key) => {
                if (mediaStreams[key] !== 'failed') {
                    DEBUG('Closing stream: %o', mediaStreams[key]);
                    sylkrtcutils.closeMediaStream(mediaStreams[key]);
                }
            });
            setDevices([]);
            setMediaStreams({});
            init.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (init.current && (videoInput !== '' || audioInput !== '')) {
            applyConstraints();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoInput, audioInput]);

    useEffect(() => {
        if (devices.length !== 0) {
            let currentLabel;
            if (currentStream.getVideoTracks().length !== 0) {
                currentLabel = currentStream.getVideoTracks()[0].label;
                let device = devices.find((device) => device.label === currentLabel);
                if (device) {
                    setVideoInput(device.deviceId);
                }
            }

            currentLabel = currentStream.getAudioTracks()[0].label;
            let audioDevice = devices.find((device) => device.label === currentLabel);
            if (audioDevice) {
                setAudioInput(audioDevice.deviceId);
            }

            setImmediate(() => (init.current = true));
        }
    }, [devices, currentStream]);

    const start = () => {
        DEBUG('Getting available devices');
        let constraints = {
            audio: true,
            video: {
                width: { ideal: 1280 },
                height: { ideal: 720 }
            }
        };

        if (navigator.userAgent.indexOf('Firefox') > 0) {
            constraints.video = {
                width: { ideal: 640 },
                height: { ideal: 480 }
            };
        }

        const isSafari =
            navigator.vendor?.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;

        const promises = [];

        return new Promise((resolve, reject) => {
            if (isSafari) {
                return navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        sylkrtcutils.closeMediaStream(stream);
                        resolve();
                    })
                    .catch((error) => {
                        DEBUG('Initial access failed: %o', error);
                        resolve();
                    });
            }
            resolve();
        })
            .then(() => navigator.mediaDevices.enumerateDevices())
            .then((devices) => {
                DEBUG('We got the devices: %o', devices);
                setDevices(devices);
                return devices;
            })
            .catch((error) => {
                DEBUG('Device enumeration failed: %o', error);
            })
            .then((devices) => {
                devices.forEach((device) => {
                    if (!audio && device.kind === 'videoinput') {
                        const videoConstraints = {
                            video: { deviceId: { exact: device.deviceId } }
                        };
                        promises.push(
                            Queue.enqueue(() =>
                                navigator.mediaDevices
                                    .getUserMedia(videoConstraints)
                                    .then((stream) => ({
                                        [videoConstraints.video.deviceId.exact]: stream
                                    }))
                                    .catch((error) => ({
                                        [videoConstraints.video.deviceId.exact]: error
                                    }))
                            )
                        );
                    } else if (audio && device.kind === 'audioinput') {
                        const audioConstraints = {
                            audio: { deviceId: { exact: device.deviceId } }
                        };
                        promises.push(
                            Queue.enqueue(() =>
                                navigator.mediaDevices
                                    .getUserMedia(audioConstraints)
                                    .then((stream) => ({
                                        [audioConstraints.audio.deviceId.exact]: stream
                                    }))
                                    .catch((error) => ({
                                        [audioConstraints.audio.deviceId.exact]: error
                                    }))
                            )
                        );
                    }
                });
                return Promise.all(promises);
            })
            .then((localStreams) => {
                DEBUG('We have all available streams from the devices: %o', localStreams);
                const streams = {};
                localStreams.forEach((stream) => {
                    Object.assign(streams, stream);
                    const key = Object.keys(stream)[0];
                    if (stream[key].name) {
                        DEBUG('Device had a problem: %o', stream[key]);
                        streams[key] = 'failed';
                    }
                });
                setMediaStreams(streams);
            });
    };

    const getVideoInputDevices = () => {
        return devices
            .filter((device) => device.kind === 'videoinput')
            .map((device) => (
                <MenuItem
                    onClick={() =>
                        mediaStreams[device.deviceId] &&
                        mediaStreams[device.deviceId] !== 'failed' &&
                        selectVideoInput(device)
                    }
                    value={device.deviceId}
                    key={device.deviceId}
                    disabled={mediaStreams[device.deviceId] === 'failed'}
                >
                    <VideoMenuItem
                        stream={mediaStreams[device.deviceId]}
                        label={device.label}
                        selected={device.deviceId === videoInput}
                    />
                </MenuItem>
            ));
    };

    const getAudioInputDevices = () => {
        return devices
            .filter((device) => device.kind === 'audioinput')
            .map((device) => (
                <MenuItem
                    onClick={() =>
                        mediaStreams[device.deviceId] &&
                        mediaStreams[device.deviceId] !== 'failed' &&
                        selectAudioInput(device)
                    }
                    key={device.deviceId}
                    className={classes.item}
                    classes={{ selected: classes.selected }}
                    selected={device.deviceId === audioInput}
                    disabled={mediaStreams[device.deviceId] === 'failed'}
                >
                    <AudioMenuItem
                        label={device.label}
                        stream={mediaStreams[device.deviceId]}
                        selected={device.deviceId === audioInput}
                    />
                </MenuItem>
            ));
    };

    const getAudioOutputDevices = () => {
        return devices
            .filter((device) => device.kind === 'audiooutput')
            .map((device) => (
                <MenuItem
                    onClick={() => selectAudioOutput(device.deviceId)}
                    value={device.deviceId}
                    key={device.deviceId}
                    className={classes.item}
                >
                    <div className={classes.audioLabel}>{device.label}</div>
                </MenuItem>
            ));
    };

    const selectVideoInput = (device) => {
        setVideoInput(device.deviceId);
        if (videoInput === device.deviceId) {
            close();
        }
        setDevice(device);
    };

    const selectAudioInput = (device) => {
        setAudioInput(device.deviceId);
        setDevice(device);
    };

    const selectAudioOutput = (device) => {
        close();
    };
    const isCurrentVideoDevice = () => {
    const currentVideo = currentStream.getVideoTracks()?.[0].label;
    const selectedVideo = mediaStreams[videoInput].getVideoTracks()?.[0].label;
    return selectedVideo === currentVideo;
};

const isCurrentAudioDevice = () => {
    const currentAudio = currentStream.getAudioTracks()?.[0].label;
    const selectedAudio = mediaStreams[audioInput].getAudioTracks()?.[0].label;
    return selectedAudio === currentAudio;
};

const applyConstraints = () => {
    if (!audio && videoInput !== '') {
        if (!isCurrentVideoDevice()) {
            DEBUG('Switching videoInput');
            call.replaceTrack(
                currentStream.getVideoTracks()[0],
                mediaStreams[videoInput].getVideoTracks()[0].clone(),
                false
            );
        }
        close();
    }
    if (audio && audioInput !== '' && !isCurrentAudioDevice()) {
        DEBUG('Switching audioInput');
        call.replaceTrack(
            currentStream.getAudioTracks()[0],
            mediaStreams[audioInput].getAudioTracks()[0].clone(),
            false
        );
    }
};

return (
    <Menu
        id="conference-menu"
        anchorEl={anchor}
        open={show}
        onClose={close}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
            vertical: direction === 'right' || direction === 'up' ? 'top' : 'bottom',
            horizontal: direction === 'right' ? 'right' : 'center'
        }}
        transformOrigin={{
            vertical: direction === 'up' ? 'bottom' : 'top',
            horizontal: direction === 'right' ? 'left' : 'center'
        }}
        getContentAnchorEl={null}
    >
        {devices.length >= 1 ? (
            <>
                {audio && (
                    <>
                        <ListSubheader className={classes.subheader}>
                            <ListItemIcon className={classes.icon}>
                                <i className="fa fa-microphone"></i>
                            </ListItemIcon>
                            Microphones
                        </ListSubheader>
                        {getAudioInputDevices()}
                    </>
                )}

                {showOutput && (
                    <>
                        <ListSubheader className={classes.subheader}>
                            <ListItemIcon className={classes.icon}>
                                <i className="fa fa-volume-up"></i>
                            </ListItemIcon>
                            Speakers
                        </ListSubheader>
                        {getAudioOutputDevices()}
                    </>
                )}

                {!audio && getVideoInputDevices()}
            </>
        ) : (
            <Fade in={devices.length === 0} style={{ transitionDelay: devices.length === 0 ? '100ms' : '0ms' }} unmountOnExit>
                <CircularProgress />
            </Fade>
        )}
    </Menu>
);
};

SwitchDevicesMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  call: PropTypes.object.isRequired,
  setDevice: PropTypes.func.isRequired,
  anchor: PropTypes.object,
  audio: PropTypes.bool,
  showOutput: PropTypes.bool,
  direction: PropTypes.string
};

export default SwitchDevicesMenu;
