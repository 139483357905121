'use strict';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const useStyles = makeStyles({
    bigger: {
        '&> h2': {
            fontSize: '20px'
        },
        '&> div > p': {
            fontSize: '14px'
        },
        '&> li.MuiListSubheader-root': {
            fontSize: '14px',
            textAlign: 'left'
        }
    },
    fixFont: {
        fontFamily: 'inherit',
        fontSize: '14px',
        textAlign: 'left'
    },
    number: {
        fontSize: 32,
        textAlign: 'center',
        display: 'block',
        letterSpacing: 12,
        padding: 8
    }
});

function getContent(step = 0, exp) {
    if (step === 1 && exp) {
        return (
            <>
                To replicate messages on multiple devices, you need the same private key on all of them.<br /><br />
                Press <strong>Export</strong> and enter this code when prompted on your other device:<br />
            </>
        );
    } else if (step === 1 && !exp) {
        return (
            <>
                To replicate messages on multiple devices, you need the same private key on all of them.<br /><br />
                Enter this code when prompted on your other device:<br />
            </>
        );
    }
    return (
        <>
            You have used messaging on more than one device. To decrypt your messages, you need the same private key on all your devices.<br /><br />
            To use the private key from <strong>the other device</strong>, choose the menu option <strong><em>'Export private key'</em></strong> on that device.<br /><br />
            <span className="text-warning">Do you want to <strong>keep this key</strong>?</span>
        </>
    );
}

const EncryptionModal = (props) => {
    const classes = useStyles();
    const [step, setStep] = useState(0);
    const [password, setPassword] = useState();

    useEffect(() => {
        if (props.show === true) {
            setPassword(Math.random().toString().substr(2, 6));
        }
    }, [props.show]);

    return (
        <Dialog
            open={props.show}
            onClose={props.close}
            maxWidth="sm"
            fullWidth
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title" className={classes.bigger}>
                {props.export === false && step !== 1
                    ? 'Different key detected'
                    : 'Export private key'
                }
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="dialog-description" className={classes.fixFont}>
                    {getContent(props.export ? 1 : step, props.export)}
                    {(props.export || step === 1) && <span className={classes.number}>{password}</span>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.export === false && step !== 1 && (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setStep(1);
                                props.useExistingKey(password);
                            }}
                        >
                            Keep this key
                        </Button>
                        <Button onClick={props.close} variant="text">No</Button>
                    </>
                )}
                {props.export === true && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.exportKey(password);
                        }}
                    >
                        Export
                    </Button>
                )}
                {(step === 1 || props.export === true) && (
                    <Button onClick={props.close} variant="text">Close</Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

EncryptionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    exportKey: PropTypes.func.isRequired,
    useExistingKey: PropTypes.func.isRequired,
    export: PropTypes.bool
};

export default EncryptionModal;
