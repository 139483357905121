'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid } from '@mui/material';

const DividerWithText = ({ children }) => (
    <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
            <Divider />
        </Grid>
        <Grid item>
            {children}
        </Grid>
        <Grid item xs>
            <Divider />
        </Grid>
    </Grid>
);

DividerWithText.propTypes = {
    children: PropTypes.node,
};

export default DividerWithText;
