'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Popper, MenuList, Paper, ClickAwayListener, Fade } from '@mui/material';

const useMenuStyles = makeStyles({
    // Styles applied to the Paper component.
    paper: {
        maxHeight: 'calc(100% - 96px)',
        WebkitOverflowScrolling: 'touch',  // Add iOS momentum scrolling.
    },
    // Styles applied to the List component via MenuList.
    list: {
        outline: 0,  // Disable the focus ring for mouse, touch, and keyboard users.
    }
});

const CustomContentMenu = ({ anchorEl, open, children, onClose, keepMounted = true }) => {
    const menuClasses = useMenuStyles();
    const id = open ? 'faked-reference-popper' : undefined;

    return (
        <Popper
            id={id}
            open={open}
            anchorEl={anchorEl || null}
            transition
            placement="bottom-start"
            keepMounted={keepMounted}
            style={{ zIndex: 1201 }}
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={onClose}>
                    <Fade {...TransitionProps}>
                        <Paper className={menuClasses.paper}>
                            <MenuList className={menuClasses.list} autoFocus>
                                {children}
                            </MenuList>
                        </Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    );
};

CustomContentMenu.propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    keepMounted: PropTypes.bool,
};

export default CustomContentMenu;
