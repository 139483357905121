import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const DEBUG = debug('blinkrtc:CallQuality');

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'block',
  bottom: '25px',
  position: 'absolute',
  zIndex: 3,
  fontSize: '20px',
  right: '20px'
}));

const OkIcon = styled(WarningIcon)(({ theme }) => ({
  color: '#5cb85c',
  '&:hover': {
    color: '#4cae4c'
  }
}));

const WarningIconNew = styled(ErrorOutlineIcon)(({ theme }) => ({
  color: '#f0ad43',
  '&:hover': {
    color: '#eea236'
  }
}));

const DangerIcon = styled(WarningIcon)(({ theme }) => ({
  color: '#d9534f',
  '&:hover': {
    color: '#d43f3a'
  }
}));

const CallQuality = ({ audioData, videoData, inbound, thumb }) => {
  const [quality, setCallQuality] = useState(1);

  const titles = inbound
    ? [
        '',
        '',
        'The reception quality is not optimal, you can tell the participant to switch to a better network, or moving closer to a WiFi access point',
        'The reception quality is poor, you can tell the participant to try to switch to a better network, or moving closer to a WiFi access point'
      ]
    : [
        '',
        '',
        'The call quality is not optimal, you can try switching to a better network, or moving closer to a WiFi access point',
        'Your network is causing poor call quality, try switching to a better network, or moving closer to a WiFi access point'
      ];

  const update = useCallback((latencyMeasurements, packetsLost, packets) => {
    latencyMeasurements = latencyMeasurements.filter(data => data !== 0 && data !== undefined);
    const averageLatency = latencyMeasurements.reduce((sum, value) => sum + value, 0) / latencyMeasurements.length;

    const latency = (averageLatency * 1000);
    const packetLossPercentage = (packetsLost / packets) * 100;

    if (latency > 400) {
      setCallQuality(3);
      return;
    }

    let quality = latency <= 300 ? 1 : 2;

    let packetLossQuality = packetLossPercentage < 4 ? 1 : packetLossPercentage <= 8 ? 2 : 3;

    if (packetLossQuality > quality) {
      setCallQuality(packetLossQuality);
      if (packetLossQuality >= 2) {
        DEBUG('Bad quality: latency: %s, loss: %s', latency, packetLossPercentage);
      }
      return;
    }
    if (quality >= 2) {
      DEBUG('Bad quality: latency: %s, loss: %s', latency, packetLossPercentage);
    }
    setCallQuality(quality);
  }, []);

  useEffect(() => {
    let latencyMeasurements = [];
    let packets = 0;
    let packetsLost = 0;

    if (videoData && videoData.length !== 0) {
      const lastVideoData = videoData.slice(-30);
      latencyMeasurements = lastVideoData.map((data) => data.latency);
      if (inbound) {
        packets = lastVideoData.reduce((a, b) => a + (b['packetRateInbound'] || 0), 0);
        packetsLost = lastVideoData.reduce((a, b) => a + (b['packetsLostInbound'] || 0), 0);
      } else {
        packets = lastVideoData.reduce((a, b) => a + (b['packetRateOutbound'] || 0), 0);
        packetsLost = lastVideoData.reduce((a, b) => a + (b['packetsLostOutbound'] || 0), 0);
      }
    }

    if (audioData && audioData.length !== 0) {
      const lastAudioData = audioData.slice(-30);
      latencyMeasurements = latencyMeasurements.concat(lastAudioData.map((data) => data.latency));
      if (inbound) {
        packets += lastAudioData.reduce((a, b) => a + (b['packetRateInbound'] || 0), 0);
        packetsLost += lastAudioData.reduce((a, b) => a + (b['packetsLostInbound'] || 0), 0);
      } else {
        packets += lastAudioData.reduce((a, b) => a + (b['packetRateOutbound'] || 0), 0);
        packetsLost += lastAudioData.reduce((a, b) => a + (b['packetsLostOutbound'] || 0), 0);
      }
    }

    if ((audioData && audioData.length !== 0) || (videoData && videoData.length !== 0)) {
      update(latencyMeasurements, packetsLost, packets);
    }
  }, [update, audioData, videoData, inbound]);

  return (
    <>
      {quality === 3 && (
        <Tooltip title={titles[quality]}>
          <StyledSpan>
            <DangerIcon />
          </StyledSpan>
        </Tooltip>
      )}
      {quality === 2 && (
        <Tooltip title={titles[quality]}>
          <StyledSpan>
            <WarningIconNew />
          </StyledSpan>
        </Tooltip>
      )}
    </>
  );
};

CallQuality.propTypes = {
  audioData: PropTypes.array,
  videoData: PropTypes.array,
  inbound: PropTypes.bool,
  thumb: PropTypes.bool
};

export default CallQuality;
