import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import PropTypes from "prop-types";
import { tokens } from "../../../theme";
import { campaignsData } from "../../../data/mockData"; 
import DownloadIcon from '@mui/icons-material/Download';
import Footer from "../../global/Footer";

function LinearProgressWithLabel({ value }) {
  const numericValue = parseFloat(value);
  const progressValue = isNaN(numericValue)
    ? 0
    : Math.min(100, Math.max(0, numericValue));

  return (
    <Box width="100%">
      <LinearProgress
        color="success"
        variant="determinate"
        value={progressValue}
      />
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        progressValue
      )}%`}</Typography>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function Campaigns() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = React.useState(campaignsData);

  const handleDownload = () => {
    const csvData = data.map((row) => {
      return Object.values(row).join(',');
    }).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'campaigns.csv';
    link.click();

    window.URL.revokeObjectURL(url);
  };
  


  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "idname",
      headerName: "ID Name",
      width: 220,
      editable: true,
    },
    {
      field: "totalCalls",
      headerName: "Total calls",
      width: 80,
      editable: true,
    },
    {
      field: "successfulCalls",
      headerName: "Successful Calls",
      type: "number",
      width: 80,
      editable: true,
    },
    {
      field: "talkTime",
      headerName: "Talk Time",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "holdTime",
      headerName: "Hold Time",
      type: "number",
      width: 70,
      editable: true,
    },
    {
      field: "placedCalls",
      headerName: "Placed Calls",
      type: "number",
      width: 60,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "progress",
      headerName: "Progress",
      sortable: false,
      width: 160,
      renderCell: ({ row: { progress } }) => {
        // Convert the progress string to a number
        const progressValue = parseInt(progress, 10);

        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
          >
            <LinearProgressWithLabel value={progressValue} />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          // margin: 1,
          width: "100%",
          height: "100%",
          // backgroundColor: colors.primary[900],
          borderRadius: 3,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
        >
          <Typography
            variant="h3"
            color={colors.skyblueAccent[500]}
            fontWeight="bold"
          >
            Campaigns
          </Typography>
        </Box>
        <Divider />

        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(4, 1fr)",
            md: "repeat(12, 1fr)",
          }}
          gridAutoRows="100px"
          gap="10px"
          mt={2}
          paddingRight={1}
        >
          <Box
            gridColumn="span 2"
            backgroundColor="Blue"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                1283
              </Typography>
              <Box>
                <Typography color="white">Total Calls</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="green"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                980
              </Typography>
              <Box>
                <Typography color="white">Answered</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="red"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                283
              </Typography>
              <Box backgroundColor="red">
                <Typography color="white">Abandoned</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="#90EE90"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "black" }}
              >
                2:43
              </Typography>
              <Box>
                <Typography>Average Time</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor="#90EE90"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            ml="10px"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "black" }}
              >
                1:03
              </Typography>
              <Box>
                <Typography>Average wait </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box marginTop={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
        >
          <Typography
            variant="h5"
            color={colors.skyblueAccent[500]}
            fontWeight="bold"
          >
            Campaigns
          </Typography>
          <Button
        style={{
          background: '#4CC713',
          color: 'white',
          padding: '7px 15px',
          display: 'flex',
          jystifyContent: "space-between",
          fontSize: '0.9em',
          textIndent: '15px',
        }}
        onClick={handleDownload}
      >
        <DownloadIcon/>
        Download
      </Button>        </Box>
          <Box
            sx={{
              overflowX: "auto",
              width: "100%",
              "@media (max-width: 600px)": {
                width: "320px",
                fontSize: "14px", 
              },
            }}
          >
            <DataGrid
              rows={campaignsData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              // checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      </Box>
      <Footer/>
    </Box>
  );
}

export default Campaigns;
