import React, { useContext } from "react";
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";

const StyledPaper = styled(Paper)`
  padding: 20px;
  max-width: 300px;
`;

const StyledButton = styled(Button)`
  margin: 10px;
`;

const AgentProfile = ({ user, onClose }) => {
  const {userInfo} = useContext(AuthContext)

  return (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        {userInfo.username}{" "}
      </Typography>
      <Typography variant="body1" paragraph>
        {/* Email: {user.email} */}
      </Typography>

      <Divider />
      <List>
        <ListItem>
          <ListItemText primary={`Role: Manager`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Organization: Demo`} />
        </ListItem>
      </List>

      <StyledButton variant="contained" color="primary" onClick={onClose}>
        Close
      </StyledButton>
      <StyledButton variant="contained" color="primary" onClick={onClose}>
        Edit
      </StyledButton>
    </StyledPaper>
  );
};

export default AgentProfile;
